import { Component, Input, OnInit } from '@angular/core';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'seira-public-radio-group',
	templateUrl: './public-radio-group.component.html',
	styleUrls: ['./public-radio-group.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class PublicRadioGroupComponent<T> implements OnInit {
	@Input() options: OptionRadio<T>[] = [];
	@Input() label = '';
	@Input() controlName!: string;
	@Input() idRadio = `radioGroup-${uuidv4()}`;
	@Input() labelPlacement: 'start' | 'top' = 'top';
	@Input() classRadio = 'flex-row d-flex gap-3 align-items-center';
	@Input() isDesabilitado = false;

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}
}
