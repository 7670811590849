import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Validators } from '@angular/forms';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import { EstacaoUltimasMedicoes } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/estacao-ultimas-medicoes';

import { TipoPCD } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { DateTimeUtils } from '@utils/datetime-util';
import { isNotNuloOuUndefined, numberToBrNumber, verificaSePDF } from '@utils';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { VariaveisService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/services/variaveis.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	LatLngValue,
} from '@utils/interpolacao/interpolacao';
import {
	getFormattedVariavel,
	getLabelCard,
	getUnidade,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/variaveis';
import { FeatureCollection, Polygon } from 'geojson';
import { Position } from 'pdfmake/interfaces';
import * as Leaflet from 'leaflet';
import { MapaInterpolacaoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/mapa-interpolacao/mapa-interpolacao.component';
import {
	OpcaoPagina,
	PublicPageOptionsComponent,
} from '@componentes/public-page-options/public-page-options.component';
import { Subject, takeUntil } from 'rxjs';
import { RelatoriosService } from '../../../services';
import { corrigeDuplicacaoNome } from '../../../utils';
import * as ExcelTable from 'mr-excel';
import { ValuesVariaveis } from '../../../submodulos/monitoramento/interfaces/estacao-monitorada';

@Component({
	selector: 'seira-mapa-interpolacao-estacoes-pcds',
	templateUrl: './mapa-interpolacao-estacoes-pcds.component.html',
	styleUrls: ['./mapa-interpolacao-estacoes-pcds.component.scss'],
})
export class MapaInterpolacaoEstacoesPCDSComponent
	implements InstanciaRelatorio, OnInit, OnDestroy, AfterViewInit
{
	map!: Leaflet.Map;
	inputs = inject(INPUTS_RELATORIOS);
	isVariavelPrecipitacao = false;
	variavel = '';
	unidade = '';
	mapaNome = '';
	periodoTitulo = '';
	valores: EstacaoUltimasMedicoes[] = [];
	medicoes: EstacaoUltimasMedicoes[] = [];
	interpolacao?: InterpolacaoCanvasBounds;
	regiao?: Position[][];
	_destroyed = new Subject();
	@ViewChild('legenda') legendaMapaMobile!: TemplateRef<any>;
	@ViewChild(MapaInterpolacaoComponent) mapa!: MapaInterpolacaoComponent;
	opcoesDaPagina: OpcaoPagina<any>[] = [];
	private _carregando = true;

	colunasTabelaEstacoesPCDs: Array<DataTables.ColumnSettings> = [
		{
			title: 'Município/Posto',
			data: null,
			className: 'text-tertiary  text-center',
			render: function (row) {
				return row.municipio + '/' + row.nomePosto;
			},
		},
		{
			title: 'Tipo de PCD',
			data: 'tipoPCD',
			className: 'text-tertiary  text-center',
			render: function (data: keyof typeof TipoPCD) {
				return TipoPCD[data] || null;
			},
		},
		{
			title: 'Valor mínimo',
			data: 'valorMin',
			render: function (valorMin: number, type: string) {
				const valor = Number(valorMin).toFixed(2);
				return type === 'display' ? numberToBrNumber(valor) : valor;
			},
			className: 'text-tertiary  text-center',
		},
		{
			title: 'Valor máximo',
			data: 'valorMax',
			render: function (valorMax: number, type: string) {
				const valor = Number(valorMax).toFixed(2);
				return type === 'display' ? numberToBrNumber(valor) : valor;
			},
			className: 'text-tertiary  text-center',
		},
		{
			title: 'Total observado',
			data: 'valor',
			render: function (valorMedia: any, type: string) {
				const valor = Number(valorMedia).toFixed(2);
				return type === 'display' ? numberToBrNumber(valor) : valor;
			},
			className: 'text-tertiary  text-center',
		},
	];
	descricaoRelatorio =
		'Define-se como variável meteorológica interpolada a quantidade total dessa variável registrada em um local ou região específica, interpolada ao longo de um período.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	constructor(
		private httpClient: HttpClient,
		private variavelService: VariaveisService,
		private readonly modalService: BsModalService,
		private readonly toastr: ToastrService,
		private cdr: ChangeDetectorRef,
		private relatoriosService: RelatoriosService
	) {}

	ngOnInit(): void {
		this.initialValues();
		this.setValidatorsByJanelaTempo(
			this.inputs.form.get(FormularioRelatorio.JANELA_TEMPO)?.value
		);
		this.setValidators();
	}

	ngAfterViewInit() {
		this.adicionarOpcoesDaPagina();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.JANELA_TEMPO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.VARIAVEL_MAPA)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO_JANELA_TEMPO)
			?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM_JANELA_TEMPO)
			?.clearValidators();
		this._destroyed.next(undefined);
	}

	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
	}

	public get carregando() {
		return this._carregando;
	}

	public set carregando(valor: boolean) {
		this._carregando = valor;
		this.inputs.setLoading(valor);
	}

	setValidatorsByJanelaTempo(janelaTempo: string) {
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.DATA_INICIO_JANELA_TEMPO
		);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.DATA_FIM_JANELA_TEMPO
		);

		if (janelaTempo === 'Personalizada') {
			this.inputs.form
				.get(FormularioRelatorio.DATA_INICIO_JANELA_TEMPO)
				?.setValidators(Validators.required);
			this.inputs.form
				.get(FormularioRelatorio.DATA_FIM_JANELA_TEMPO)
				?.setValidators(Validators.required);
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.JANELA_TEMPO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByJanelaTempo(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.JANELA_TEMPO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	gerarRelatorio(): void {
		const variavel = this.inputs.form.get('variavelMapa')?.value;
		const janelaTempo = this.inputs.form.get('janelaTempo')?.value;
		this.isVariavelPrecipitacao = variavel === ValuesVariaveis.PRECIPITACAO;
		this.unidade = getUnidade(variavel);
		this.variavel = this.getVariavelFromValor(variavel);
		this.mapaNome = getFormattedVariavel(variavel);
		this.periodoTitulo = this.getPeriodoTitulo();

		this.carregando = true;
		this.inputs.setLoading(true);

		if (janelaTempo === 'Personalizada') {
			const diaInicio = this.inputs.form.get('dataInicioJanelaTempo')?.value;
			const diaFim = this.inputs.form.get('dataFimJanelaTempo')?.value;

			this.variavelService
				.medicoesPersonalizada({ diaInicio, diaFim }, variavel)
				.subscribe({
					next: resp => {
						this.medicoes = resp;
						this.relatoriosService.verificaExistenciaDados(resp);
					},
					error: (err: HttpErrorResponse) => {
						this.toastr.error(err.message, 'Erro ao gerar mapa');
						this.carregando = false;
						this.inputs.setLoading(false);
					},
					complete: () => {
						this.carregando = false;
						this.inputs.setLoading(false);
						this.interpolarValores();
					},
				});
		} else {
			this.variavelService.ultimasMedicoes(janelaTempo, variavel).subscribe({
				next: resp => {
					this.medicoes = resp;
					this.relatoriosService.verificaExistenciaDados(resp);
				},
				error: (err: HttpErrorResponse) => {
					this.toastr.error(err.message, 'Erro ao gerar mapa');
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
					this.interpolarValores();
				},
			});
		}
	}

	interpolarValores() {
		this.carregando = true;
		if (this.medicoes.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}

	criarImagemInterpolacao() {
		const variavel = this.inputs.form.get('variavelMapa')?.getRawValue();

		const valores: LatLngValue[] = this.medicoes.map(value => {
			const valorTipoPCD = value.tipoPCD;
			return {
				lat: value.latitude,
				lng: value.longitude,
				value: value.valor,
				municipio: value.municipio,
				valorMax: value.valorMax,
				valorMin: value.valorMin,
				nomePosto: value.nomePosto,
				tipoPCD: this.labelTipoPcd(valorTipoPCD),
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				variavel,
				this.regiao as number[][][],
				0.01
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	getPeriodoTitulo() {
		const janelaTempo = this.inputs.form.get('janelaTempo')?.value;
		let periodo;

		switch (janelaTempo) {
			case '6':
				periodo = DateTimeUtils.getPeriodoPorHoras(6);
				break;
			case '24':
				periodo = DateTimeUtils.getPeriodoPorHoras(24);
				break;
			case '48':
				periodo = DateTimeUtils.getPeriodoPorHoras(48);
				break;
			case '72':
				periodo = DateTimeUtils.getPeriodoPorHoras(72);
				break;
			default:
				periodo = DateTimeUtils.getPeriodoPorHoras(6);
		}

		const dataInicioFormatada = DateTimeUtils.formatarISOParaFormato24hrs(
			periodo.diaInicio.toISOString()
		);
		const dataFimFormatada = DateTimeUtils.formatarISOParaFormato24hrs(
			periodo.diaFim.toISOString()
		);

		return `${dataInicioFormatada} a ${dataFimFormatada}`;
	}

	getVariavelFromValor(variavel: string): string {
		return getLabelCard(variavel);
	}

	getDadosTabelaParaExportacao(
		dados: EstacaoUltimasMedicoes[],
		isPdf: boolean
	) {
		const tableData: any[][] = [];
		const colunas = [
			{ text: 'Município/Posto', fillColor: '#DCDCDC' },
			{ text: 'Tipo de PCD', fillColor: '#DCDCDC' },
		];

		if (!this.isVariavelPrecipitacao) {
			colunas.push({
				text: `Valor mínimo (${this.unidade})`,
				fillColor: '#DCDCDC',
			});
			colunas.push({
				text: `Valor máximo (${this.unidade})`,
				fillColor: '#DCDCDC',
			});
			colunas.push({ text: `Média (${this.unidade})`, fillColor: '#DCDCDC' });
		} else {
			colunas.push({
				text: `Total observado (${this.unidade})`,
				fillColor: '#DCDCDC',
			});
		}

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: EstacaoUltimasMedicoes) => {
			const rowData = [
				corrigeDuplicacaoNome(`${item.municipio}/${item.nomePosto}`),
				this.labelTipoPcd(item.tipoPCD),
			];

			if (!this.isVariavelPrecipitacao) {
				rowData.push(
					isNotNuloOuUndefined(item.valorMin)
						? numberToBrNumber(item.valorMin, 2)
						: '-'
				);
				rowData.push(
					isNotNuloOuUndefined(item.valorMax)
						? numberToBrNumber(item.valorMax, 2)
						: '-'
				);
			}
			rowData.push(
				isNotNuloOuUndefined(item.valor) ? numberToBrNumber(item.valor, 2) : '-'
			);

			tableData.push(rowData);
		});

		return tableData;
	}

	retornarPluviometriaInterpoladaParaXLSX(dados: EstacaoUltimasMedicoes[]) {
		const tableData: any[] = [];

		dados.forEach(item => {
			const rowData: any = {
				municipioPosto: corrigeDuplicacaoNome(
					`${item.municipio}/${item.nomePosto}`
				),
				tipoPCD: this.labelTipoPcd(item.tipoPCD),
			};

			if (!this.isVariavelPrecipitacao) {
				rowData.valorMinimo = isNotNuloOuUndefined(item.valorMin)
					? numberToBrNumber(item.valorMin, 2)
					: '-';
				rowData.valorMaximo = isNotNuloOuUndefined(item.valorMax)
					? numberToBrNumber(item.valorMax, 2)
					: '-';
				rowData.media = isNotNuloOuUndefined(item.valor)
					? numberToBrNumber(item.valor, 2)
					: '-';
			} else {
				rowData.totalObservado = isNotNuloOuUndefined(item.valor)
					? numberToBrNumber(item.valor, 2)
					: '-';
			}

			tableData.push(rowData);
		});

		return tableData;
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Variável meteorológica interpolada - ${this.variavel} - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 500,
				height: 400,
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, true);
		const widths = this.isVariavelPrecipitacao
			? ['33%', '33%', '33%']
			: ['20%', '20%', '20%', '20%', '20%'];

		documentDefinition.content.push({
			table: {
				widths: widths,
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},

			marginLeft: 15,
			marginRight: 15,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Variável meteorológica interpolada - ${this.variavel} - ${this.periodoTitulo}`
		);
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);
		DocumentExporter.gerarCSV(
			tableData,
			`Variável meteorológica interpolada - ${this.variavel} - ${this.periodoTitulo}`
		);
	}

	async exportarXLSX() {
		const nomeArquivo = `Variável meteorológica interpolada - ${this.variavel} - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{ label: 'municipioPosto', text: 'Município/Posto', size: 24 },
			{ label: 'tipoPCD', text: 'Tipo de PCD', size: 24 },
		];

		const columns: any[] = [{ key: 'municipioPosto' }, { key: 'tipoPCD' }];

		if (!this.isVariavelPrecipitacao) {
			headers.push({
				label: 'valorMinimo',
				text: `Valor mínimo ${this.unidade}`,
				size: 24,
			});
			headers.push({
				label: 'valorMaximo',
				text: `Valor máximo ${this.unidade}`,
				size: 24,
			});
			headers.push({ label: 'media', text: `Média ${this.unidade}`, size: 24 });
			columns.push({ key: 'valorMinimo', style: { numFmt: '0.0' } });
			columns.push({ key: 'valorMaximo', style: { numFmt: '0.0' } });
			columns.push({ key: 'media', style: { numFmt: '0.0' } });
		} else {
			headers.push({
				label: 'totalObservado',
				text: 'Total observado mm',
				size: 24,
			});
			columns.push({ key: 'totalObservado', style: { numFmt: '0.0' } });
		}

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPluviometriaInterpoladaParaXLSX(this.medicoes),
					columns: columns,
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	labelTipoPcd(tipo: string | null) {
		if (tipo === 'METEOROLOGICA') {
			return 'Meteorológica';
		} else if (tipo === 'AGROMETEOROLOGICA') {
			return 'Agrometeorológica';
		}
		return null;
	}

	abrirModalOpcoesDaPagina(event: Event) {
		event.preventDefault();
		this.modalService.show(PublicPageOptionsComponent, {
			class: 'modal-dialog-centered',
			initialState: {
				opcoes: this.opcoesDaPagina,
			},
		});
	}

	adicionarOpcoesDaPagina() {
		this.opcoesDaPagina = [
			{
				label: 'Legenda',
				template: this.legendaMapaMobile,
			},
		];
	}
}
