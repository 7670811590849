import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import {
	DadosEvapotranspiracao,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { format, formatISO } from 'date-fns';
import { Validators } from '@angular/forms';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';

@Component({
	selector: 'seira-evapotranspiracao-tabela',
	templateUrl: './evapotranspiracao-tabela.component.html',
	styleUrls: ['./evapotranspiracao-tabela.component.scss'],
})
export class EvapotranspiracaoTabelaComponent
	implements OnDestroy, InstanciaRelatorio
{
	carregando = true;
	tituloTabela = '';
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaEvapotranspiracao: Relatorios<DadosEvapotranspiracao>[] = [];

	descricaoRelatorio =
		'Define-se como evapotranspiração a quantidade total de água transferida para a atmosfera, resultado da soma da evaporação do solo e da transpiração das plantas de uma determinada área.';

	@ViewChild('tabelaEvapotranspiracao', { static: false })
	tabela?: PublicTableComponent;

	colunas: ADTColumns[] = [
		{
			data: 'dataColeta',
			title: 'Data',
			type: 'string',
			className: 'text-start',
		},
		{
			data: 'evapotranspiracaoPotencial',
			title: 'Evapotranspiração potencial (mm)',
			type: 'number',
			className: 'text-end',
		},
	];

	botoesDeExportacao: GroupButton[] = [
		// {
		// 	label: '.pdf',
		// 	size: 'small',
		// 	icon: 'ph-file-pdf',
		// 	onClick: () =>
		// 		exportarPDF(this.colunas, this.tabela, '', this.tituloTabela),
		// },
		// {
		// 	label: '.csv',
		// 	size: 'small',
		// 	icon: 'ph-file-csv',
		// 	onClick: () => exportarCSV(this.tabela, '', this.tituloTabela),
		// },
		// {
		// 	label: '.txt',
		// 	size: 'small',
		// 	icon: 'ph-file-text',
		// 	onClick: () => exportarTXT(this.tabela, '', this.tituloTabela),
		// },
		// {
		// 	label: '.xlsx',
		// 	size: 'small',
		// 	icon: 'ph-file-xls',
		// 	onClick: () => exportarXLSX(this.tabela, '', this.tituloTabela),
		// },
	];

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)?.value;
	}

	gerarRelatorio() {
		const postoIds = this.inputs.postos.map(posto => posto.id);
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.tituloTabela = `Evapotranspiração - ${DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		)}`;

		this.carregando = true;
		this.inputs.setLoading(true);

		this.relatoriosService
			.buscarRelatorioEvapotranspiracao(
				postoIds,
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim))
			)
			.subscribe({
				next: evapotranspiracao => {
					this.dadosTabelaEvapotranspiracao =
						this.padronizarResultado(evapotranspiracao);
					this.relatoriosService.verificaExistenciaDados(evapotranspiracao);
				},
				error: err => {
					this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	padronizarResultado(relatorios: Relatorios<DadosEvapotranspiracao>[]) {
		return relatorios.map(relatorio => {
			return {
				...relatorio,
				data: relatorio.data.map(dado => ({
					...dado,
					dataColeta: format(new Date(dado.dataColeta), 'dd/MM/yyyy HH:mm'),
					evapotranspiracaoPotencial: isNotNuloOuUndefined(
						dado.evapotranspiracaoPotencial
					)
						? numberToBrNumber(dado.evapotranspiracaoPotencial, 1)
						: '-',
				})),
			};
		});
	}
}
