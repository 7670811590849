<div>
	<div class="d-flex align-items-center">
		<seira-page-title class="w-100 align-items-center">
			<div class="d-flex justify-content-between align-items-center">
				<div
					style="color: #728187; font-weight: 400; font-size: 24px !important">
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Estações Automáticas
				</div>
				<div class="d-flex justify-content-end">
					<seira-public-page-about
						class="size-about"
						[textosInformativos]="informacoesGerais"></seira-public-page-about>
				</div>
			</div>
		</seira-page-title>
	</div>

	<!-- <form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-3">
				<div class="col-lg-9 col-md-4 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="OpcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"
						[isDesabilitado]="loadingForm" />
				</div>

				<div *ngIf="mostrarSelectTipo" class="mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo"
						[disabled]="loadingForm" />
				</div>

				<div *ngIf="mostrarSelectVariaveisGrafico" class="mt-1">
					<seira-public-select
						placeholder="Selecione uma variável"
						label="Variável"
						[options]="variaveis"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma variável encontrado"
						formControlName="variavel"
						[disabled]="loadingForm" />
				</div>
				<div *ngIf="mostrarDateRangePicker" class="mt-1">
					<seira-public-date-range-picker
						style="width: 100%"
						label="Período"
						controlStartName="dataInicio"
						controlEndName="dataFim"
						startPlaceholder="Inicio"
						endPlaceholder="Fim"
						[isDesabilitado]="loadingForm" />
				</div>

				<div *ngIf="mostrarAgrupamento" class="mt-1">
					<seira-public-select
						placeholder="Selecione um agrupamento"
						label="Agrupamento"
						[options]="optionsAgrupamento"
						optionValue="value"
						optionLabel="name"
						formControlName="agrupamento"
						[disabled]="loadingForm" />
				</div>
				<div *ngIf="mostrarSelectDePostos" class="mt-1">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Posto"
						[searchable]="true"
						[options]="options"
						optionValue="value"
						optionLabel="label"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao"
						[disabled]="loadingForm" />
				</div>
				<div *ngIf="mostrarSelectDePostosValoresExtremos" class="mt-1">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Posto"
						[searchable]="true"
						[options]="options"
						optionValue="value"
						optionLabel="label"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao"
						[disabled]="loadingForm" />
				</div>
				<ng-container>
					<div *ngIf="mostrarSelectTipoPeriodoValoresExtremos" class="mt-1">
						<seira-public-select
							[options]="tiposDePeriodosValoresExtremos"
							style="width: 100%"
							optionValue="value"
							optionLabel="name"
							label="Tipo de período"
							placeholder="Selecione um tipo de período"
							formControlName="tipoPeriodoValoresExtremos"
							[disabled]="loadingForm" />
					</div>
					<div *ngIf="mostrarDatePickerAnoCompleto" class="mt-1">
						<seira-public-date-picker
							style="width: 100%"
							label="Período"
							placeholder="Selecione um período"
							controlName="dataInicio"
							[maxDate]="calcularDataFimValoresExtremos"
							datePickerType="year"
							[isDesabilitado]="loadingForm" />
					</div>
					<div *ngIf="mostrarDatePickerAnoParcial" class="mt-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Período inicial"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="partial_year"
							[isDesabilitado]="loadingForm" />

						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Período final"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="calcularDataInicioValoresExtremos"
							[maxDate]="calcularDataFimValoresExtremos"
							datePickerType="partial_month"
							[isDesabilitado]="loadingForm" />
					</div>
					<div *ngIf="mostrarDatePickerMesCompleto" class="mt-1">
						<seira-public-date-picker
							style="width: 100%"
							label="Período"
							placeholder="Selecione um período"
							controlName="dataInicio"
							[maxDate]="calcularDataFimValoresExtremos"
							datePickerType="month"
							[isDesabilitado]="loadingForm" />
					</div>
					<div *ngIf="mostrarDatePickerMesParcial" class="mt-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Período inicial"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="partial_year"
							[isDesabilitado]="loadingForm" />

						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Período final"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="calcularDataInicioValoresExtremos"
							[maxDate]="calcularDataFimValoresExtremos"
							datePickerType="partial_month"
							[isDesabilitado]="loadingForm" />
					</div>
				</ng-container>
				<div *ngIf="mostrarSelectTipoMapa">
					<seira-public-select
						[options]="tiposMapas"
						style="width: 100%"
						optionValue="value"
						optionLabel="name"
						label="Tipo mapa"
						placeholder="Selecione um tipo de mapa"
						formControlName="tipoMapa"
						[disabled]="loadingForm" />
				</div>

				<div *ngIf="mostrarSelectsMapa" class="mt-1">
					<seira-public-select
						class="col-12 col-md-3 col-lg-2"
						placeholder="Informe a janela de tempo"
						[options]="janelasDeTempo"
						label="Janela de tempo"
						optionValue="name"
						optionLabel="value"
						notFoundText="Nenhuma janela de tempo encontrada"
						formControlName="janelaDeTempo"
						style="margin-right: 10px"
						[disabled]="loadingForm" />
					<seira-public-date-range-picker
						*ngIf="buscaPersonalizada"
						class="col-12 col-md-3 col-lg-2"
						style="margin-right: 10px"
						label="Período"
						controlEndName="dataFim"
						controlStartName="dataInicio"
						[isDesabilitado]="loadingForm" />

					<seira-public-select-variavel-estacoes-automaticas
						class="col-12 col-md-3 col-lg-2"
						style="margin-right: 10px"
						[clearable]="false"
						formControlName="variavel"
						[disabled]="loadingForm" />
				</div>

				<ng-container *ngIf="mostrarSelectsMapaHistorico">
					<seira-public-select
						[options]="variaveisMapa"
						optionLabel="label"
						optionValue="value"
						style="width: 100%"
						label="Variável"
						placeholder="Selecione uma variável"
						[formControlName]="FormularioRelatorio.VARIAVEL_MAPA"
						[disabled]="loadingForm" />

					<seira-public-select
						[options]="tiposPeriodos"
						style="width: 100%"
						optionValue="value"
						optionLabel="name"
						label="Tipo de período"
						placeholder="Selecione um tipo de período"
						formControlName="tipoPeriodo"
						[disabled]="loadingForm" />

					<div *ngIf="mostrarDateRangePickerMes" class="mt-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Data de início"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="month"
							[isDesabilitado]="loadingForm" />

						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Data de fim"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="calcularDataInicio"
							[maxDate]="calcularDataFim"
							datePickerType="month"
							[isDesabilitado]="loadingForm" />
					</div>
					<div *ngIf="mostrarDatePickerAno" class="mt-1">
						<seira-public-date-picker
							style="width: 100%"
							label="Período"
							placeholder="Selecione um período"
							controlName="periodo"
							datePickerType="year"
							[isDesabilitado]="loadingForm" />
					</div>
				</ng-container>

				<div class="mt-3">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>
				</div>
			</div>
			<div class="col-lg-6">
				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>
		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar relatório</seira-public-button
			>
		</ng-template>
	</form> -->
	<form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-4 col-xl-3">
				<div class="col-lg-11 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="opcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo"
						[disabled]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectVariaveisMapa">
					<seira-public-select-variavel-estacoes-automaticas
						[clearable]="false"
						formControlName="variavelMapa"
						[disabled]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectJanelaTempo">
					<seira-public-select
						placeholder="Informe a janela de tempo"
						[options]="janelasTempo"
						label="Janela de tempo"
						optionValue="name"
						optionLabel="value"
						notFoundText="Nenhuma janela de tempo encontrada"
						formControlName="janelaTempo"
						[disabled]="loadingForm" />
					<seira-public-date-range-picker
						*ngIf="form.value.janelaTempo === 'Personalizada'"
						label="Período"
						controlEndName="dataFimJanelaTempo"
						controlStartName="dataInicioJanelaTempo"
						[maxDate]="limiteDataFimSuperior"
						[isDesabilitado]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectVariaveisGrafico">
					<seira-public-select
						placeholder="Selecione uma variável"
						label="Variável"
						[options]="variaveisGrafico"
						optionValue="value"
						optionLabel="name"
						formControlName="variavelGrafico"
						[disabled]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectAgrupamento">
					<seira-public-select
						placeholder="Selecione um agrupamento"
						label="Agrupamento"
						[options]="optionsAgrupamento"
						optionValue="value"
						optionLabel="name"
						formControlName="agrupamento"
						[disabled]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectPostos">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Município/Posto"
						[searchable]="true"
						[options]="municipioPostos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao"
						[disabled]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMicrorregioes">
					<seira-public-select
						placeholder="Selecione uma microrregião"
						label="Microrregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao"
						[searchable]="true"
						[options]="microrregioes"
						[disabled]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectRegioes">
					<seira-public-select
						placeholder="Selecione uma região pluviométrica"
						label="Região pluviométrica"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma região pluviométrica encontrada"
						formControlName="regiao"
						[searchable]="true"
						[options]="regioes"
						[disabled]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectBacias">
					<seira-public-select
						placeholder="Selecione uma bacia"
						label="Bacia"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma bacia encontrada"
						formControlName="bacia"
						[searchable]="true"
						[options]="bacias"
						[disabled]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMesorregioes">
					<seira-public-select
						placeholder="Selecione uma mesorregião"
						label="Mesorregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma mesorregião encontrada"
						formControlName="mesorregiao"
						[searchable]="true"
						[options]="mesorregioes"
						[disabled]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMunicipios">
					<seira-public-select
						placeholder="Selecione um município"
						label="Município"
						optionValue="id"
						optionLabel="nome"
						notFoundText="Nenhum município encontrado"
						formControlName="municipio"
						[searchable]="true"
						[options]="municipios"
						[disabled]="loadingForm" />
				</div>

				<div class="col-lg-12 col-md-4 col-sm-6" *ngIf="mostrarPeriodoBusca">
					<seira-public-radio-group
						[controlName]="FormularioRelatorio.PERIODO_BUSCA"
						[options]="opcoesPeriodo"
						[isDesabilitado]="loadingForm" />
				</div>

				<ng-container *ngIf="relatorioAtual?.periodos && labelAdptativaPeriodo">
					<ng-container
						*ngIf="labelAdptativaPeriodo.mensal_por_nome; else unicoPicker">
						<div class="col-lg-11 d-flex flex-column">
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês inicial"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesInicio"
								[searchable]="true"
								[options]="meses"
								[disabled]="loadingForm" />
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês final"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesFim"
								[searchable]="true"
								[options]="meses"
								[disabled]="loadingForm" />
						</div>
					</ng-container>

					<ng-template #unicoPicker>
						<ng-container *ngIf="labelAdptativaPeriodo.unico; else rangePicker">
							<div class="col-lg-11 d-flex flex-column mt-1">
								<seira-public-date-picker
									class="w-100 mt-1"
									controlName="periodo"
									[label]="labelAdptativaPeriodo.unico.label"
									[placeholder]="labelAdptativaPeriodo.unico.placeholder || ''"
									[datePickerType]="
										labelAdptativaPeriodo.unico.dataType || 'month'
									"
									[tipoFormulario]="getFormItemValue('tipo')"
									[isDesabilitado]="loadingForm" />
							</div>
						</ng-container>
					</ng-template>

					<ng-template #rangePicker>
						<div class="col-lg-11 d-flex flex-column mt-1">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataInicio"
								[label]="labelAdptativaPeriodo.inicio?.label"
								[placeholder]="labelAdptativaPeriodo.inicio?.placeholder || ''"
								[datePickerType]="
									labelAdptativaPeriodo.inicio?.dataType || 'year'
								"
								[tipoFormulario]="getFormItemValue('tipo')"
								[isDesabilitado]="loadingForm" />
						</div>
						<div class="col-lg-11 d-flex flex-row align-items-end">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataFim"
								[label]="labelAdptativaPeriodo.fim?.label"
								[placeholder]="labelAdptativaPeriodo.fim?.placeholder || ''"
								[datePickerType]="labelAdptativaPeriodo.fim?.dataType || 'year'"
								[minDate]="dataInicio"
								[tipoFormulario]="getFormItemValue('tipo')"
								[maxDate]="limiteDataFimSuperior"
								[isDesabilitado]="loadingForm" />
						</div>
					</ng-template>
				</ng-container>

				<div
					class="col-lg-11 mt-1 d-flex justify-content-between align-items-end"
					style="margin-top: 1rem !important">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>
				</div>
			</div>
			<div class="col-lg-6">
				<div
					class="d-flex flex-colum justify-content-end mb-2"
					*ngIf="
						relatoriosService.getExibirBotoesExportacao() && form.value.tipo
					">
					<seira-public-button-group
						[buttons]="botoesDeExportacao"
						[disabledButtons]="loadingForm" />
				</div>

				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>

		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar</seira-public-button
			>
		</ng-template>
	</form>
	<div class="row">
		<div class="col-lg-6">
			<seira-info-gerais
				id="informacoes-gerais"
				[textosInformativos]="informacoesGerais"></seira-info-gerais>
		</div>
	</div>
</div>
