import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { DesvioDTO } from '@modulos/home/submodulos/dados-meteorologicos/interfaces/desvioDTO';
import { numberToBrNumber, obter_erro_request, verificaSePDF } from '@utils';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	ValorDesvioInterpolacao,
} from '@utils/interpolacao/interpolacao';
import { Polygon } from 'geojson';
import { ToastrService } from 'ngx-toastr';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '../../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../../services/relatorios.service';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';
import html2canvas from 'html2canvas';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';
import {
	legendaInfoDesvioMilimetro,
	legendaInfoDesvioPorcentagem,
} from '@componentes/mapa-paraiba-svg/legenda';
import moment from 'moment';
import Position = GeoJSON.Position;
import FeatureCollection = GeoJSON.FeatureCollection;

@Component({
	selector: 'seira-mapa-desvio',
	templateUrl: './mapa-desvio.component.html',
	styleUrls: ['./mapa-desvio.component.scss'],
})
export class MapaDesvioComponent implements OnInit, OnDestroy {
	inputs = inject(INPUTS_RELATORIOS);
	regiao?: Position[][];
	carregando = true;
	medicoes: DesvioDTO[] = [];
	valoresDesvio: DesvioDTO[] = [];
	interpolacaoDesvio!: InterpolacaoCanvasBounds;
	colorJsonInterpolacao = '#5a5e66';
	weight = 2;
	tipoDesvio: keyof DesvioDTO = 'desvioMilimetro';
	unidade = '(mm)';
	legenda: Record<number, string>;

	descricaoRelatorio = '';
	descricaoRelatorioMilimetro =
		'Define-se como desvio (mm), a diferença entre a quantidade de chuva registrada e a sua climatologia para um mesmo período, em um determinado local ou região.';
	descricaoRelatorioPorcentagem =
		'Define-se como desvio (%) a variação da precipitação em relação à climatologia, expressa em porcentagem, que indica se o acumulado de chuvas foi superior ou inferior à climatologia.';

	periodoTitulo = '';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPdf();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	constructor(
		private httpClient: HttpClient,
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit(): void {
		const tipoDesvioSemTratamento = this.inputs.form.get(
			FormularioRelatorio.TIPO
		)?.value;
		this.tipoDesvio = this.capitalizeFirtsLetterSecundWord(
			tipoDesvioSemTratamento
		) as keyof DesvioDTO;
		this.unidade = this.getDesvioUnidade(this.tipoDesvio)!;
		this.descricaoRelatorio =
			this.tipoDesvio === 'desvioMilimetro'
				? this.descricaoRelatorioMilimetro
				: this.descricaoRelatorioPorcentagem;

		this.initialValues();
		this.atualizaLegenda();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	setValidators(): void {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	getFormItemValue<T>(formValue: string): T {
		return this.inputs.form.get(formValue)!.value;
	}

	atualizaLegenda(): void {
		this.legenda =
			this.tipoDesvio === 'desvioMilimetro'
				? legendaInfoDesvioMilimetro
				: legendaInfoDesvioPorcentagem;
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}

		const dataInicio = this.inputs.form.get('dataInicio')?.value;
		const dataFim = this.inputs.form.get('dataFim')?.value;

		if (!dataInicio || !dataFim) {
			return;
		}
		this.inputs.setLoading(true);
		this.carregando = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			'periodo'
		);

		this.relatoriosService
			.relatorioMapaInterpolacaoDesvio(dataInicio, dataFim)
			.subscribe({
				next: (resp: DesvioDTO[]) => {
					this.relatoriosService.verificaExistenciaDados(resp);
					this.medicoes = resp;
				},
				error: erro => {
					this.carregando = false;
					this.inputs.setLoading(false);
					const msg_erro = obter_erro_request(erro);
					this.toastr.error(msg_erro, 'Erro ao obter informações de Desvio');
				},
				complete: () => {
					this.interpolarValores();
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	capitalizeFirtsLetterSecundWord(input: string): string {
		const words = input.toLowerCase().split('_');
		let camelCaseString = words[0];
		for (let i = 1; i < words.length; i++) {
			camelCaseString += words[i].charAt(0).toUpperCase() + words[i].slice(1);
		}
		return camelCaseString;
	}

	async interpolarValores() {
		try {
			const [interpolacaoDesvio] = await Promise.all([
				this.criarImagemInterpolacao(this.tipoDesvio),
			]);

			this.interpolacaoDesvio = interpolacaoDesvio as InterpolacaoCanvasBounds;
		} catch (error) {
			console.error('Erro ao interpolar valores:', error);
		}
	}

	criarImagemInterpolacao(
		tipoDesvio: keyof DesvioDTO
	): Promise<InterpolacaoCanvasBounds> {
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				this.valoresParaInterpolacao(tipoDesvio),
				tipoDesvio as VariavelMeteorologica,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject(`Erro ao criar imagem de interpolação de ${tipoDesvio}.`);
			}
			resolve(interpolacao);
		});
	}

	valoresParaInterpolacao(
		tipoDesvio: keyof DesvioDTO
	): ValorDesvioInterpolacao[] {
		return this.medicoes.map(value => ({
			lat: value.latitude,
			lng: value.longitude,
			value: value[tipoDesvio] as number,
			nomeMunicipio: value.municipio,
			desvio: tipoDesvio,
		}));
	}

	getDesvioUnidade(tipoDesvio: string) {
		const unidadeMap = new Map([
			['desvioMilimetro', '(mm)'],
			['desvioPorcentagem', '(%)'],
		]);
		return unidadeMap.get(tipoDesvio);
	}

	getDadosTabelaParaExportacao(dados: DesvioDTO[], isPdf: boolean) {
		const tableData: any[][] = [];

		const colunas = [{ text: 'Município', fillColor: '#DCDCDC' }];

		this.tipoDesvio === 'desvioMilimetro'
			? colunas.push({ text: 'Desvio (mm)', fillColor: '#DCDCDC' })
			: colunas.push({ text: 'Desvio (%)', fillColor: '#DCDCDC' });

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: DesvioDTO) => {
			const rowData = [item.municipio];

			this.tipoDesvio === 'desvioMilimetro'
				? rowData.push(numberToBrNumber(item.desvioMilimetro, 1) as string)
				: rowData.push(numberToBrNumber(item.desvioPorcentagem, 1) as string);
			tableData.push(rowData);
		});

		return tableData;
	}

	getDataFormatada(data: Date): string {
		return moment(data).format('DD/MM/YYYY');
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	retornarDesviosParaXLSX() {
		const tableData: any[] = [];

		this.medicoes.map(desvio => {
			if (this.tipoDesvio === 'desvioMilimetro') {
				tableData.push({
					desvioMilimetro: numberToBrNumber(desvio.desvioMilimetro, 1),
					municipio: desvio.municipio,
				});
			} else {
				tableData.push({
					desvioPorcentagem: numberToBrNumber(desvio.desvioPorcentagem, 1),
					municipio: desvio.municipio,
				});
			}
		});

		return tableData;
	}

	async exportarPdf() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Desvios ${
					this.tipoDesvio === 'desvioMilimetro' ? '(mm)' : '(%)'
				} - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: 500,
				height: 400,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, true);

		documentDefinition.content.push({
			table: {
				widths: ['50%', '50%'],
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},

			marginLeft: 15,
			marginRight: 15,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);

		DocumentExporter.gerarCSV(
			tableData,
			`Desvios ${this.tipoDesvio === 'desvioMilimetro' ? '(mm)' : '(%)'} - ${
				this.periodoTitulo
			}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Desvios ${this.tipoDesvio === 'desvioMilimetro' ? '(mm)' : '(%)'} - ${
				this.periodoTitulo
			}`
		);
	}

	async exportarXLSX() {
		const nomeArquivo = `Desvios ${
			this.tipoDesvio === 'desvioMilimetro' ? '(mm)' : '(%)'
		} - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'municipio',
				text: 'Município',
				size: 48,
			},
		];

		this.tipoDesvio === 'desvioMilimetro'
			? headers.push({
					label: 'desvioMilimetro',
					text: 'Desvio (mm)',
					size: 24,
			  })
			: headers.push({
					label: 'desvioPorcentagem',
					text: 'Desvio (%)',
					size: 24,
			  });

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarDesviosParaXLSX(),
					columns: [
						{ key: 'municipio' },
						{
							key:
								this.tipoDesvio === 'desvioMilimetro'
									? 'desvioMilimetro'
									: 'desvioPorcentagem',
							style: { numFmt: '0.0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo}`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
