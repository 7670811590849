import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from '@core/services/user/user.service';
import { Router } from '@angular/router';
import { KEY_LOCAL_STORAGE_PERMISSIONS } from '@modulos/administracao/submodulos/grupo/enums/permissoesEnum';

@Component({
	selector: 'seira-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
	@HostBinding('class') clazz = 'expandir-flex';

	constructor(
		private router: Router,
		private userService: UserService
	) {}

	ngOnInit() {
		if (this.userService.isLogged()) {
			const permissions =
				localStorage
					.getItem(KEY_LOCAL_STORAGE_PERMISSIONS.PERMISSIONS)
					?.split(';') || [];

			if (
				permissions.includes('permissao.historico.reservatorio.gerenciar') &&
				permissions.includes('permissao.gerenciar-carta-campo')
			) {
				this.router
					.navigate(['meteorologia', 'medicoes-pluviometricas'])
					.then();
			} else if (
				permissions.includes('permissao.historico.reservatorio.gerenciar')
			) {
				this.router
					.navigate(['recursos-hidricos', 'reservatorio', 'historico'])
					.then();
			} else if (permissions.includes('permissao.gerenciar-carta-campo')) {
				this.router
					.navigate(['meteorologia', 'medicoes-pluviometricas'])
					.then();
			} else {
				this.router
					.navigate(['meteorologia', 'medicoes-pluviometricas'])
					.then();
			}
		}
	}
}
