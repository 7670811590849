<label class="text-center text-tertiary fw-semibold w-100">
	Índice de anomalia de precipitação modificado (IAPM) - {{ periodoTitulo }}
</label>

<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />

<div
	*ngIf="isLoading; else grafico"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-template #grafico>
	<div style="max-width: 1300px" *ngIf="dados; else semDados">
		<seira-grafico-iapm
			[dados]="dados"
			(chartInstance)="onChartCreated($event)"
			[chartTitle]="chartTitle"
			[fileName]="fileName" />
		<p class="referencia-estudo">
			{{ referenciaEstudo }}
		</p>
	</div>
</ng-template>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
