import { SeriePrecipitacao } from './series/serie-precipitacao';
import { SerieUmidade } from './series/serie-umidade';
import { SerieVariavelMeteorologica } from './series/serie-variavel-meteorologica';
import { SerieTemperatura } from './series/serie-temperatura';
import { SeriePressao } from './series/serie-pressao';
import { SerieRadiacaoSolar } from './series/radiacao-solar';
import mensagens from './series/mensagens';
import { SerieAgua } from './series/serie-agua';
import { SerieVento } from './series/serie-vento';

const mapeamentoSeries = new Map<string, SerieVariavelMeteorologica>([
	['PRECIPITACAO', new SeriePrecipitacao()],
	[
		'PRESSAO_ATMOSFERICA',
		new SeriePressao(mensagens['variavel.pressaoAtmosferica']),
	],
	[
		'TEMPERATURA_DO_AR',
		new SerieTemperatura(mensagens['variavel.temperaturaAr']),
	],
	[
		'TEMPERATURA_DO_SOLO',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo']),
	],
	['UMIDADE', new SerieUmidade(mensagens['variavel.umidade'])],
	['RADIACAO_SOLAR', new SerieRadiacaoSolar()],
	[
		'TEMPERATURA_SOLO_10CM',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo10cm']),
	],
	[
		'TEMPERATURA_SOLO_20CM',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo20cm']),
	],
	[
		'TEMPERATURA_SOLO_30CM',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo30cm']),
	],
	[
		'TEMPERATURA_SOLO_40CM',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo40cm']),
	],
	[
		'TEMPERATURA_SOLO_50CM',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo50cm']),
	],
	[
		'TEMPERATURA_SOLO_60CM',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo60cm']),
	],
	[
		'CONTEUDO_AGUA_SOLO_10CM',
		new SerieAgua(mensagens['variavel.conteudoAguaSolo10cm']),
	],
	[
		'CONTEUDO_AGUA_SOLO_20CM',
		new SerieAgua(mensagens['variavel.conteudoAguaSolo20cm']),
	],
	[
		'CONTEUDO_AGUA_SOLO_30CM',
		new SerieAgua(mensagens['variavel.conteudoAguaSolo30cm']),
	],
	[
		'CONTEUDO_AGUA_SOLO_40CM',
		new SerieAgua(mensagens['variavel.conteudoAguaSolo40cm']),
	],
	[
		'CONTEUDO_AGUA_SOLO_50CM',
		new SerieAgua(mensagens['variavel.conteudoAguaSolo50cm']),
	],
	[
		'CONTEUDO_AGUA_SOLO_60CM',
		new SerieAgua(mensagens['variavel.conteudoAguaSolo60cm']),
	],
	['UMIDADE_INTERNA', new SerieUmidade(mensagens['variavel.umidadeInterna'])],
	['UMIDADE_SOLO', new SerieUmidade(mensagens['variavel.umidadeRelativa'])],
	[
		'TEMPERATURA_INTERNA',
		new SerieTemperatura(mensagens['variavel.temperaturaInterna']),
	],
	['VENTO', new SerieVento()],
	['VENTO_2M', new SerieVento()],
	['VENTO_1_MAXIMA', new SerieVento()],
	['VENTO_2_MAXIMA', new SerieVento()],
	['VENTO_DIRECAO_1_DESVIO_PADRAO', new SerieVento()],
	['VENTO_DIRECAO_2_DESVIO_PADRAO', new SerieVento()],
]);

/**
 * Obtem a série a ser adicionada em um gráfico de variáveis meteorológicas
 * @param {Variavel} variavel a variavel desejada
 * @returns {SerieVariavelMeteorologica} a seríe (dados e configuração do eixo) a ser utilizada no gráfico
 */
export function obterFabricaSerie(
	variavel: string
): SerieVariavelMeteorologica | undefined {
	return mapeamentoSeries.get(variavel);
}
