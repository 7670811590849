<div class="d-flex flex-column">
	<label class="text-tertiary text-center fw-semibold mt-3 w-100">
		{{ tituloTabela }}
	</label>

	<div style="max-width: 1300px">
		<div
			class="d-flex my-3 flex-row justify-content-between align-items-end gap-2">
			<seira-card-info [texto]="descricaoRelatorio" />
		</div>

		<div
			*ngIf="carregando; else tabela"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>

		<ng-template #tabela>
			<div>
				<seira-public-pagination
					[items]="dadosTabelaEvapotranspiracao"
					[itemsPerPage]="5">
					<ng-template let-item="item">
						<div class="mb-2">
							<label
								for="tabela-evapotranspiracao"
								class="text-tertiary mb-2 fw-semibold">
								{{
									item.data.at(0).nomeEstacao === item.municipio
										? item.data.at(0).nomeEstacao
										: item.data.at(0).nomeEstacao + ' / ' + item.municipio
								}}
							</label>
							<seira-public-table
								#tabelaEvapotranspiracao
								id="tabela-evapotranspiracao"
								[hasFooter]="false"
								[hasHeader]="false"
								[info]="false"
								[paging]="false"
								[searching]="false"
								[columns]="colunas"
								[data]="item.data"></seira-public-table>
						</div>
					</ng-template>
				</seira-public-pagination>
			</div>
		</ng-template>
	</div>
</div>
