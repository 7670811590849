/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, OnInit } from '@angular/core';
import { AgrupamentoResponse } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import {
	capitalizeFirstLetter,
	enumAsSelectOptions,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';
import { corrigeDuplicacaoNome } from '../../utils';
import { Agrupamento } from '../../interfaces';

export type PopupAgrupamentoResponseType = Omit<
	AgrupamentoResponse,
	'precipitacaoAcumulada' | 'dadosPosto' | 'nomeOriginal'
> &
	Partial<
		Pick<
			AgrupamentoResponse,
			'precipitacaoAcumulada' | 'dadosPosto' | 'nomeOriginal'
		>
	> & {
		tipo: string;
	};

@Component({
	selector: 'seira-popup-agrupamento',
	templateUrl: './popup-agrupamento.component.html',
	styleUrls: ['./popup-agrupamento.component.scss'],
})
export class PopupAgrupamentoComponent implements OnInit {
	@Input() agrupamento: PopupAgrupamentoResponseType;
	@Input() headerColor = 'bg-primary';
	@Input() titleColor = 'white';
	nome: string;

	ngOnInit(): void {
		this.nome = corrigeDuplicacaoNome(this.agrupamento?.nome || '');
	}

	get generoAgrupamento(): string {
		return ['MUNICIPIO_POSTO', 'MUNICIPIO'].includes(this.agrupamento.tipo)
			? 'o'
			: 'a';
	}

	get labelTipoAgrupamento(): string {
		if (this.agrupamento.tipo !== 'BACIA_SUBBACIA') {
			return enumAsSelectOptions<string>(Agrupamento)
				.find(e => e.value === this.agrupamento.tipo)!
				.name!.toLowerCase();
		} else {
			return 'bacia/sub-bacia';
		}
	}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
