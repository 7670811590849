import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Meses } from '@home/submodulos/dados-meteorologicos/enum';
import {
	Agrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { SPI } from '@home/submodulos/dados-meteorologicos/interfaces/SPI';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { formatISO, startOfMonth, subYears } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { PERIODO_MINIMO_SPI_EM_ANOS } from '../pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { filterSelectOptions, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { Select } from '@layout/interfaces/select';
import { Subject, takeUntil } from 'rxjs';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';

@Component({
	selector: 'seira-tabela-spi',
	templateUrl: './tabela-spi.component.html',
	styleUrls: ['./tabela-spi.component.scss'],
})
export class TabelaSPIComponent implements OnInit, OnDestroy {
	@ViewChild('tabelaSpi', { static: false })
	tabela: PublicTableComponent;
	inputs = inject(INPUTS_RELATORIOS);
	periodoTitle = '';
	municipioPosto = '';
	carregando = false;
	descricaoRelatorio =
		'O Relatório de Índice de Precipitação Padronizado (SPI) dispõe informações a respeito do monitoramento de seca em um determinado período para as regiões pluviométricas';

	referenciaEstudo =
		'Os métodos utilizados para a análise da precipitação e cálculo do Índice de Precipitação Padronizado (SPI) foram baseados nas diretrizes apresentadas no guia climático do National Center for Atmospheric Research (NCAR), intitulado: "Standardized Precipitation Index (SPI)". Este guia fornece os fundamentos teóricos e práticos para a aplicação do SPI, amplamente utilizado para o monitoramento de secas e análise da variabilidade climática. O estudo está disponível no Climate Data Guide.';

	colunasSPI: ADTColumns[] = [];
	resultados: any[] = [];
	periodoAgrupamento = 'anual';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.resultados,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];
	agrupamento: Select<string>[];
	private _destroyed = new Subject();
	tituloAgrupamento = '';
	tituloTabela = '';
	agrupamentoAtual = '';

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
		this.setValidators();
	}

	ngOnInit() {
		this.prencherValoresIniciais();

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.clearValidators();
		this._destroyed.next(undefined);
	}

	prencherValoresIniciais() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);

		const inicioDefault = startOfMonth(
			subYears(new Date(), PERIODO_MINIMO_SPI_EM_ANOS)
		);
		const fimDefault = new Date();

		this.inputs.form.patchValue({
			dataInicio: inicioDefault,
			dataFim: fimDefault,
		});
	}

	setColunasTabela() {
		this.colunasSPI = [
			{
				data: 'nome',
				title: this.getTituloAgrupamento(),
				className: 'text-left',
			},
			{
				data: null,
				title: 'Mês/Ano',
				className: 'text-center',
				render: {
					_: (spi: SPI) => `${Meses[spi.mes - 1]}/${spi.ano}`,
					sort: (spi: SPI) => `${spi.ano}${spi.mes}`,
				},
			},
			{
				data: 'spi',
				title: 'Valor SPI ',
				className: 'text-center',
			},
			{
				data: 'categoria',
				title: 'SPI',
				type: 'string',
				className: 'text-center',
			},
		];
	}

	private getTituloAgrupamento(): string {
		return this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)?.value ===
			'MUNICIPIO_POSTO'
			? 'Município/Posto'
			: 'Região Pluviométrica';
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					?.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				break;
		}
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const regiaoId = this.getDataFromForm(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		)?.value;
		const postoId = this.getDataFromForm(FormularioRelatorio.ESTACAO)?.value;
		this.agrupamentoAtual = this.getDataFromForm(
			FormularioRelatorio.AGRUPAMENTO
		)?.value;

		if (this.agrupamentoAtual === 'MUNICIPIO_POSTO') {
			const municipioPostoEncontrado: any = this.inputs.municipioPostos.find(
				item => item.value === postoId
			);
			this.municipioPosto = municipioPostoEncontrado.name || '';
		}

		if (this.inputs.form.invalid) {
			return;
		}

		this.periodoTitle = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			this.periodoAgrupamento
		);
		this.carregando = true;
		this.inputs.setLoading(true);
		this.tituloTabela = `Índice de precipitação padronizada (SPI) - ${this.periodoTitle}`;

		this.relatoriosService
			.buscarRelatorioSPI(
				formatISO(new Date(diaInicio)),
				formatISO(new Date(diaFim)),
				this.agrupamentoAtual === 'MUNICIPIO_POSTO' ? postoId : regiaoId,
				this.agrupamentoAtual
			)
			.subscribe({
				next: SPIResult => {
					this.relatoriosService.verificaExistenciaDados(SPIResult);
					this.setColunasTabela();
					this.resultados = this.padronizarResultados(SPIResult);
				},
				error: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
					this.toastrService.error(
						'Erro ao gerar tabela de SPI, tente novamente'
					);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private padronizarResultados(resultados: SPI[]) {
		return resultados.map(resultado => ({
			mes: resultado.mes,
			ano: resultado.ano,
			categoria: resultado.categoria || '-',
			nome:
				this.agrupamentoAtual === 'MUNICIPIO_POSTO'
					? this.municipioPosto
					: resultado.nomeRegiao,
			spi: numberToBrNumber(resultado.spi, 1) || '-',
		}));
	}
}
