import {
	Component,
	inject,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { DropdownOption } from '@componentes/dropdown/interfaces/DropdownOption';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { RelatorioPrecipitacaoAcumulada } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	isNotNuloOuUndefined,
	numberToBrNumber,
	obter_erro_request,
} from '@utils';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalQuantisComponent } from '@home/submodulos/dados-meteorologicos/componentes/modal-quantis/modal-quantis.component';
import { handleGenerateQuantisDataHighchart } from '@home/submodulos/dados-meteorologicos/utils/graficoUtils';
import {
	DadoGraficoQuantis,
	DadosGraficoQuantisMensal,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { ToastrService } from 'ngx-toastr';
import { QuantisMicrorregiaoService } from '../../services/quantis-microrregiao.service';
import { Meses } from '../../enum';
import { DateTimeUtils } from '@utils/datetime-util';
import { corrigeDuplicacaoNome } from '../../utils';

@Component({
	selector: 'seira-tabela-relatorios-precipitacao-acumulada',
	templateUrl: './tabela-relatorios-precipitacao-acumulada.component.html',
	styleUrls: ['./tabela-relatorios-precipitacao-acumulada.component.scss'],
})
export class TabelaRelatoriosPrecipitacaoAcumuladaComponent
	implements OnChanges
{
	@Input() relatorios?: RelatorioPrecipitacaoAcumulada;
	@ViewChild('templateColunaAcoes', { static: false })
	templateColunaAcoes?: TemplateRef<any>;
	resultadoQuantis?: DadosGraficoQuantisMensal;
	useMaximumLimit = 500;
	private readonly inputs = inject(INPUTS_RELATORIOS);

	colunas: ADTColumns[] = [
		{
			data: null,
			render: chuvasAcumuladas => {
				return chuvasAcumuladas.detalhesPosto.municipio;
			},
			title: 'Município',
			className: 'text-center',
		},
		{
			data: null,
			render: chuvasAcumuladas => {
				return chuvasAcumuladas.detalhesPosto.posto;
			},
			title: 'Posto',
			className: 'text-center',
		},
		{
			data: null,
			render: chuvasAcumuladas => {
				return chuvasAcumuladas.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'Pluviômetro convencional'
					: 'PCD';
			},
			title: 'Tipo de estação',
			className: 'text-center',
		},
		{
			data: null,
			render: chuvasAcumuladas => {
				if (isNotNuloOuUndefined(chuvasAcumuladas.precipitacaoAcumulada)) {
					return numberToBrNumber(chuvasAcumuladas.precipitacaoAcumulada, 1);
				}
				return '-';
			},
			title: 'Precipitação Acumulada',
			className: 'text-center',
		},
		{
			title: 'Ações',
			data: null,
			render: chuvasAcumuladas => {
				return chuvasAcumuladas.detalhesPosto;
			},
			orderable: false,
			className: 'text-lg-center',
			createdCell: (cell, _, rowData) => {
				const celulaLimpa = this.renderer.selectRootElement(cell, false);
				if (isNotNuloOuUndefined(this.templateColunaAcoes)) {
					const view = this.templateColunaAcoes.createEmbeddedView({
						pk: rowData.detalhesPosto.idEstacao,
					});

					view.detach();
					view.rootNodes.forEach(node => {
						this.renderer.appendChild(celulaLimpa, node);
					});
					view.detectChanges();
				}
			},
		},
	];

	constructor(
		private renderer: Renderer2,
		private quantisMicrorregiaoService: QuantisMicrorregiaoService,
		private modalService: BsModalService,
		private toastr: ToastrService
	) {}

	generateOptionsDropdown(pk: number): DropdownOption[] {
		return [
			new DropdownOption('Quantis mensal', '', null, '', async () => {
				this.gerarQuantisMensal(pk);
			}),
			new DropdownOption('Quantis anual', '', null, '', async () => {
				this.gerarQuantisAnual(pk);
			}),
		];
	}

	abrirModal(resultado: DadosGraficoQuantisMensal, isAnual = false): void {
		this.modalService.show(ModalQuantisComponent, {
			backdrop: 'static',
			class: 'modal-xl modal-dialog-centered bg-transparent ',
			initialState: {
				afterSubmitOnModalFunc: () => {},
				resultado: resultado,
				isAnual,
				useMaximumLimit: this.useMaximumLimit,
				closeModalFunc: () => this.modalService.hide(),
			},
		});
	}

	private get dataNoInicioDoAno(): Date {
		const dataInicio = this.inputs.form.get(FormularioRelatorio.PERIODO)?.value;
		return DateTimeUtils.getDataNoInicioAno(dataInicio.getFullYear());
	}

	private get dataNoFimDoAno(): Date {
		const dataInicio = this.inputs.form.get(FormularioRelatorio.PERIODO)?.value;
		return DateTimeUtils.getDataNoFimAno(dataInicio.getFullYear());
	}

	gerarQuantisMensal(postoId: number): void {
		this.quantisMicrorregiaoService
			.getQuantisMensalGraficoByEstacaoId(
				postoId,
				this.dataNoInicioDoAno.toISOString(),
				this.dataNoFimDoAno.toISOString()
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);
							return {
								titulo: `${Meses[q.data! - 1]}`,
								categorias: newCategorias,
							};
						}
					);
					const municipioPosto = corrigeDuplicacaoNome(
						`${resp.posto} (${resp.municipio})`
					);
					const tituloGeral = `Posto: ${municipioPosto} - ${resp.ano}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultadoQuantis = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
					};

					this.abrirModal(this.resultadoQuantis, false);
				},
				error: err => {
					const msg_erro = obter_erro_request(err);

					this.toastr.error(
						msg_erro,
						'Erro ao obter informações do posto selecionado'
					);
				},
			});
	}

	gerarQuantisAnual(postoId: number): void {
		this.quantisMicrorregiaoService
			.getQuantisAnualGraficoByEstacaoId(
				postoId,
				this.dataNoInicioDoAno.toISOString(),
				this.dataNoFimDoAno.toISOString()
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);
							return {
								titulo: this.dataNoInicioDoAno.getFullYear().toString(),
								categorias: newCategorias,
							};
						}
					);

					const municipioPosto = corrigeDuplicacaoNome(
						`${resp.posto} (${resp.municipio})`
					);
					const tituloGeral = `Posto: ${municipioPosto} - ${resp.ano}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultadoQuantis = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
					};
					const todosValores = resp.quantis.flatMap(
						({ valores }) => Object.values(valores) as number[]
					);
					this.useMaximumLimit = Math.max(...todosValores) + 100;
					this.abrirModal(this.resultadoQuantis, true);
				},
				error: err => {
					const msg_erro = obter_erro_request(err);

					this.toastr.error(
						msg_erro,
						'Erro ao obter informações do posto selecionado'
					);
				},
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['relatorios']) {
			this.relatorios = changes['relatorios'].currentValue;
		}
	}
}
