import { DateTimeUtils } from '@utils/datetime-util';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	getXLSXDefinitions,
	getXLSXSheetDefinitions,
	getXLSXSheetTitleDefinitions,
} from '@utils/xlsx-seira';

export async function exportarPDF(
	resultados: any,
	tabela: any,
	periodoAgrupamento: string,
	tituloTabela: string
) {
	const documentDefinition: any = await pdfseira.documentDefinitions(
		'landscape'
	);

	adicionarTitulo(documentDefinition, tituloTabela);

	if (temDadosParaTabela(resultados, tabela)) {
		const tableData = prepararDadosParaTabela(tabela, periodoAgrupamento);
		adicionarTabelaAoDocumento(tabela, documentDefinition, tableData);
	} else {
		adicionarMensagemSemDados(documentDefinition);
	}

	gerarDocumentoPDF(documentDefinition);
}

export function exportarCSV(
	tabela: any,
	periodoAgrupamento: string,
	tituloTabela: string
) {
	const tableData = prepararDadosParaTabela(tabela, periodoAgrupamento);
	DocumentExporter.gerarCSV(tableData, tituloTabela);
}

export async function exportarTXT(
	tabela: any,
	periodoAgrupamento: string,
	tituloTabela: string
) {
	let txtData = '';
	const tableData = prepararDadosParaTabela(tabela, periodoAgrupamento);

	if (tableData.length > 0) {
		const colunas = tableData[0];

		tableData.slice(1).forEach(dadoRelatorio => {
			colunas.forEach((coluna: string, index: number) => {
				const valor = dadoRelatorio[index];
				txtData += `${coluna}: ${valor ?? '-'}\n`;
			});

			txtData += '\n';
		});
	}

	DocumentExporter.gerarTXT(txtData, tituloTabela);
}

export async function exportarXLSX(
	tabela: any,
	periodoAgrupamento: string,
	tituloTabela: string
) {
	const nomeArquivo = tituloTabela;
	const headers = getXlsxHeaders(tabela?.columns);
	const dataExcel = {
		...getXLSXDefinitions(),
		sheet: [
			{
				...getXLSXSheetDefinitions(),
				headers: headers,
				data: retornarPluviometriaObservadaParaXLSX(tabela, periodoAgrupamento),
				columns: [],
				title: {
					...getXLSXSheetTitleDefinitions(),
					text: nomeArquivo,
				},
			},
		],
		fileName: nomeArquivo,
	};

	ExcelTable.generateExcel(dataExcel);
}

function temDadosParaTabela(resultados: any, tabela: any): boolean {
	return !!resultados && !!tabela;
}

function adicionarTitulo(documentDefinition: any, tituloTabela: string): void {
	documentDefinition.content.push({
		text: tituloTabela,
		fontSize: 12,
		alignment: 'center',
		margin: [0, 10],
	});
}

function adicionarTabelaAoDocumento(
	tabela: any,
	documentDefinition: any,
	tableData: string[][]
): void {
	tabela?.columns.forEach((column: any) => {
		column.width = '*';
	});

	if (tabela && tabela?.columns.length > 9) {
		documentDefinition.pageSize = 'A3';
	}

	if (tabela && tabela?.columns.length > 20) {
		documentDefinition.pageSize = 'A2';
	}

	const larguraDinamica = `${(100 / tabela?.columns.length).toFixed(2)}%`;

	documentDefinition.content.push({
		fontSize: tabela?.columns.length < 14 ? '10' : '9',
		table: {
			headerRows: 1,
			widths: Array(tableData[0].length).fill(larguraDinamica),
			body: tableData,
		},
		layout: {
			fillColor: (rowIndex: number, node: any, columnIndex: number) => {
				return rowIndex === 0 ? '#DCDCDC' : null;
			},
		},
	});
}

function adicionarMensagemSemDados(documentDefinition: any): void {
	documentDefinition.content.push({
		text: 'Nenhum dado encontrado na tabela',
		alignment: 'center',
		fontSize: 10,
		margin: [0, 10],
	});
}

function gerarDocumentoPDF(documentDefinition: any): void {
	const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
	pdfDocGenerator.open();
}

function prepararDadosParaTabela(
	tabela: any,
	periodoAgrupamento: string
): string[][] {
	const visibleColumns = getVisibleColumns(tabela);
	const tableData = [visibleColumns];

	tabela?.data.forEach((row: any) => {
		const rowData = prepararDadosDaLinha(
			row,
			visibleColumns,
			tabela,
			periodoAgrupamento
		);
		tableData.push(rowData);
	});

	return tableData;
}

function getVisibleColumns(tabela: any): string[] {
	return tabela?.columns.map((col: any) => col.title as string);
}

function prepararDadosDaLinha(
	row: any,
	visibleColumns: string[],
	tabela: any,
	periodoAgrupamento: string
): string[] {
	return visibleColumns.map((columnTitle: string) => {
		switch (columnTitle) {
			case 'Município/Posto':
				return row.nome;

			case 'Mês':
				return row.mes;

			case 'Mês/Ano':
				return formatarDataMesAno(row);

			case 'Data':
				if (row.ano) {
					return formatarDataMesAno(row);
				}
				return getCellValue(row, columnTitle, tabela, periodoAgrupamento);

			default:
				return getCellValue(row, columnTitle, tabela, periodoAgrupamento);
		}
	});
}

function getCellValue(
	row: any,
	columnTitle: string,
	tabela: any,
	periodoAgrupamento: string
): string {
	const column = tabela.columns.find((col: any) => col.title === columnTitle);
	if (column && column.data) {
		return (
			extractNestedValue(row, column.data as string, periodoAgrupamento) ?? '-'
		);
	}
	return '-';
}

function extractNestedValue(
	obj: any,
	path: string,
	periodoAgrupamento: string
): any {
	return path.split('.').reduce((value, key) => {
		if (key === 'data') {
			return formatarData(value?.[key], periodoAgrupamento);
		}

		return value?.[key];
	}, obj);
}

function formatarDataMesAno(row: any): string {
	if (row.mes != null) {
		const mesFormatado = String(row.mes).padStart(2, '0');
		return `${mesFormatado}/${row.ano}`;
	}

	if (row.ano != null) {
		return `${row.ano}`;
	}

	return '-';
}

function formatarData(value: any, periodoAgrupamento: string): string {
	const dataFormatada =
		periodoAgrupamento === 'anual'
			? DateTimeUtils.formatarData(String(value).substring(0, 4), 'yyyy')
			: periodoAgrupamento === 'mensal'
			? DateTimeUtils.formatarData(String(value).substring(0, 8), 'MM/yyyy')
			: DateTimeUtils.formatarData(
					String(value).substring(0, 11),
					'dd/MM/yyyy'
			  );

	return dataFormatada;
}

function getXlsxHeaders(
	colunas: ADTColumns[]
): Array<{ label: string; text: string; size: number }> {
	const headers: Array<{ label: string; text: string; size: number }> = [];

	colunas.map(coluna =>
		headers.push({
			label: `${coluna.data}`,
			text: `${coluna.title}`,
			size: 24,
		})
	);

	return headers;
}

function retornarPluviometriaObservadaParaXLSX(
	tabela: any,
	periodoAgrupamento: string
) {
	const tableData = tabela.data.map((dado: any) => {
		const novoObjeto: any = {};

		tabela.columns.forEach((coluna: any) => {
			const atributo: any = coluna.data;

			switch (coluna.title) {
				case 'Mês':
					return (novoObjeto[atributo] = dado['mes']);

				case 'Mês/Ano':
					return (novoObjeto[atributo] = formatarDataMesAno(dado));

				case 'Data':
					if (dado['ano']) {
						return (novoObjeto[atributo] = formatarDataMesAno(dado));
					}
					if (dado['periodo'] || dado['dataColeta']) {
						return (novoObjeto[atributo] =
							dado['periodo'] || dado['dataColeta']);
					}
					return (novoObjeto[atributo] = formatarData(
						dado[atributo],
						periodoAgrupamento
					));

				default:
					if (dado.hasOwnProperty(atributo)) {
						return (novoObjeto[atributo] = dado[atributo]);
					} else {
						novoObjeto[atributo] = '-';
					}
			}
		});

		return novoObjeto;
	});

	return tableData;
}
