import {
	QuantisCategoriasMapColors,
	QuantisDataHighcharts,
} from '../interfaces';
import { QuantisValores } from '../interfaces/quantis-responses';

export interface QuantisDataJSON {
	nome: string;
	ranges: number[];
}

/**
 * TODO - Acho que seria uma boa depois de uma tarefa de Quantis ANUAL checar essas
 * cores. Da forma que elas estão, a ordem pode quebrar toda a exibição do gráfico.
 * Acho que fazer a montagem por atributo e não por indicie seria mais confiável.
 */
export const CORES = {
	VERMELHO: '#ec0808', //'#eb372a',
	AMARELO: '#ffea00', //'#fddd35',
	VERDE: '#22ee03', //'#55D6E8',
	AZUL: '#407dec', //'#458EB0',
	AZUL_ESCURO: '#035cf8', //'#213A75',
	PRETO: '#000',
};

// Categorias do gráfico de quantis e ordem das cores que deve ser utilizada
export const CATEGORIAS = [
	'Observado',
	'Muito Seco',
	'Seco',
	'Normal',
	'Chuvoso',
	'Muito Chuvoso',
];
export const CORES_QUANTIS = [
	CORES.PRETO,
	CORES.AMARELO,
	CORES.VERDE,
	CORES.AZUL,
	CORES.AZUL_ESCURO,
];

const bordaPadrao = {
	borderWidth: 1,
	borderColor: '#000000',
};

/**
 * Gera a propreidade de configuração do highcharts `yAxis.plotBands`
 * que será utilizada para criar as categorias de chuva
 *
 * @param data dados de quantis lidos a partir do json
 */
export function gerarRangesQuantis(
	data: QuantisDataJSON
): QuantisDataHighcharts[] {
	const resultado: QuantisDataHighcharts[] = [];

	const primeiroLimitePrecipitacao = Math.round(data.ranges[0] * 100) / 100;

	resultado.push({
		from: 0,
		to: primeiroLimitePrecipitacao,
		color: QuantisCategoriasMapColors.get('Muito Seco')!,
		...bordaPadrao,
		categoria: 'Muito Seco',
	});

	for (let i = 0; i < data.ranges.length - 1; i++) {
		if (data.ranges[i] >= 0 || data.ranges[i + 1] > 0) {
			resultado.push({
				from: Math.round(data.ranges[i] * 100) / 100,
				to: Math.round(data.ranges[i + 1] * 100) / 100,
				color: CORES_QUANTIS[i + 1],
				...bordaPadrao,
				categoria: CATEGORIAS[i + 1] as
					| 'Muito Seco'
					| 'Seco'
					| 'Normal'
					| 'Chuvoso'
					| 'Muito Chuvoso',
			});
		}
	}
	return resultado;
}

export function handleGenerateQuantisDataHighchart(
	quantis: QuantisValores
): QuantisDataHighcharts[] {
	return [
		{
			...bordaPadrao,
			from: 0,
			to: Math.round(quantis.observado * 100) / 100,
			categoria: 'Observado',
			color: QuantisCategoriasMapColors.get('Observado')!,
		},
		{
			...bordaPadrao,
			from: 0,
			to: Math.round(quantis.muitoSeco * 100) / 100,
			categoria: 'Muito Seco',
			color: QuantisCategoriasMapColors.get('Muito Seco')!,
		},
		{
			...bordaPadrao,
			from: 0,
			to: Math.round(quantis.seco * 100) / 100,
			categoria: 'Seco',
			color: QuantisCategoriasMapColors.get('Seco')!,
		},
		{
			...bordaPadrao,
			from: 0,
			to: Math.round(quantis.normal * 100) / 100,
			categoria: 'Normal',
			color: QuantisCategoriasMapColors.get('Normal')!,
		},
		{
			...bordaPadrao,
			from: 0,
			to: Math.round(quantis.chuvoso * 100) / 100,
			categoria: 'Chuvoso',
			color: QuantisCategoriasMapColors.get('Chuvoso')!,
		},
		{
			...bordaPadrao,
			from: 0,
			to: Math.round(quantis.muitoChuvoso * 100) / 100,
			categoria: 'Muito Chuvoso',
			color: QuantisCategoriasMapColors.get('Muito Chuvoso')!,
		},
	];
}
