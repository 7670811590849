<div [class]="'d-flex flex-column ' + (isDesabilitado ? 'disabled' : '')">
	<label
		for="date-{{ controlName }}"
		*ngIf="label"
		class="form-label text-tertiary-light"
		>{{ label }}<span class="text-danger" *ngIf="required">*</span></label
	>
	<mat-form-field id="date-{{ controlName }}">
		<input
			matInput
			[id]="id"
			[matDatepicker]="picker"
			[formControl]="control"
			[formControlName]="controlName"
			[seiraDatePickerFormat]="parseDate"
			[placeholder]="placeholder"
			[matDatepickerFilter]="filterDateByTipo"
			[max]="maxDate"
			[min]="minDate"
			[attr.disabled]="isDesabilitado ? true : null" />
		<mat-datepicker-toggle
			[disabled]="isDesabilitado"
			matIconSuffix
			[for]="picker"></mat-datepicker-toggle>
		<mat-datepicker
			#picker
			[startView]="startView"
			(yearSelected)="setYear($event, picker)"
			(monthSelected)="setMonthAndYear($event, picker)"
			[panelClass]="
				datePickerType !== 'day' ? 'header-disable' : ''
			"></mat-datepicker>
	</mat-form-field>
</div>
