import { DataModel } from 'mr-excel';

export function getXLSXDefinitions() {
	const colorPalette = {
		c1: '2C3639',
		c2: 'FFFFFF',
		c3: '000000',
		c4: 'EEEEEE',
	};
	const rowStyle = {
		backgroundColor: colorPalette.c2,
		color: colorPalette.c3,
	};
	const headerStyle = {
		backgroundColor: colorPalette.c4,
		color: colorPalette.c1,
		bold: true,
	};
	const headerStyleCenter = {
		backgroundColor: colorPalette.c4,
		color: colorPalette.c1,
		bold: true,
		alignment: {
			horizontal: 'center',
			vertical: 'center',
		} as DataModel.AlignmentOption,
	};
	const rowStyleCenter = {
		backgroundColor: colorPalette.c2,
		color: colorPalette.c3,
		alignment: {
			horizontal: 'center',
			vertical: 'center',
		} as DataModel.AlignmentOption,
	};

	const title = {
		backgroundColor: colorPalette.c2,
		whiteSpace: 'pre',
		color: colorPalette.c3,
		bold: true,
		alignment: {
			horizontal: 'center',
			vertical: 'center',
			wrapText: 1,
		} as DataModel.AlignmentOption,
	};

	return {
		styles: {
			headerStyle,
			headerStyleCenter,
			rowStyle,
			rowStyleCenter,
			title,
		},
	};
}

export function getXLSXSheetDefinitions() {
	return {
		shiftTop: 3,
		styleCellCondition(
			data: any,
			object: any,
			rowIndex: number,
			colIndex: number,
			fromHeader: boolean,
			styleKeys: string[]
		) {
			if (data.label) {
				return 'headerStyleCenter';
			} else {
				if (colIndex === 1) {
					return 'rowStyleCenter';
				} else {
					return 'rowStyle';
				}
			}
		},
		images: [
			{
				url: 'assets/images/cabecalho/cabeçalho_excel.png',
				from: 'A1',
				to: 'C3',
			},
		],
	};
}

export function getXLSXSheetTitleDefinitions() {
	return {
		consommeRow: 3,
		consommeCol: 2,
		styleId: 'title',
	};
}
