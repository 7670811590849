import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
	Agrupamento,
	FormularioRelatorio,
	IapmDTO,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '../../../interfaces';
import {
	GraficosPrecipitacaoService,
	RelatoriosService,
} from '../../../services';
import { Validators } from '@angular/forms';
import { Select } from '@layout/interfaces/select';
import { Subject, takeUntil } from 'rxjs';
import { filterSelectOptions, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { formatISO } from 'date-fns';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	PERIODO_MAXIMO_IAPM_EM_ANOS,
	PERIODO_MAXIMO_IAPM_EM_MESES,
} from '../pluviometria-grafico-iapm/pluviometria-grafico-iapm.component';
import moment from 'moment';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';

@Component({
	selector: 'seira-pluviometria-tabela-iapm',
	templateUrl: './pluviometria-tabela-iapm.component.html',
	styleUrls: ['./pluviometria-tabela-iapm.component.scss'],
})
export class PluviometriaTabelaIapmComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild('tabelaIapm', { static: false })
	tabela: PublicTableComponent;
	inputs = inject(INPUTS_RELATORIOS);
	colunas: ADTColumns[] = [];
	isLoading = false;
	tableData: IapmDTO[];
	titleTable = '';
	titlePeriodo = '';
	tituloTabela = '';
	periodoAgrupamento = '';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.tableData,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];
	agrupamento: Select<string>[];
	tituloColunaAgrupamento = 'Nome';

	descricaoRelatorio =
		'Define-se como IAPM, Índice de Anomalia de Precipitação Modificado, a variação da pluviometria em comparação com valores médios históricos a fim de caracterizar as diferentes intensidades de eventos secos e chuvosos no tempo e no espaço.';

	referenciaEstudo =
		'Os métodos utilizados para a análise da precipitação e cálculo do Índice de Anomalia de Chuva (IAC) foram baseados no estudo intitulado: "Índice de Anomalia de Chuva (IAC) como Indicador para Análise da Variabilidade Climática na Bacia Hidrográfica do Rio Pajeú-PE", de autoria de J. M. O. Assis, W. M. Souza, M. C. M. Sobral, G. L. Melo e R. A. Irmão. Este trabalho foi apresentado no XX Simpósio Brasileiro de Recursos Hídricos em 2013 e fornece uma aplicação prática do IAC para a análise da variabilidade climática. O estudo está disponível no repositório da Associação Brasileira de Recursos Hídricos (ABRHidro).';

	private _destroyed = new Subject();

	constructor(
		private readonly service: GraficosPrecipitacaoService,
		private toastr: ToastrService,
		private relatorioService: RelatoriosService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.BACIA,
			Agrupamento.MESORREGIAO,
			Agrupamento.MICRORREGIAO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setDatasByPeriodoBusca(
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.BACIA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MESORREGIAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.clearValidators();
		this._destroyed.next(undefined);
	}

	atualizarTituloColuna(agrupamento: string): void {
		this.tituloColunaAgrupamento =
			Agrupamento[agrupamento as keyof typeof Agrupamento];
	}

	atualizarColunasTabela() {
		this.colunas = [
			{
				data: 'nome',
				title: this.tituloColunaAgrupamento,
				type: 'string',
				className: 'text-center',
			},
			{
				data: 'iapm',
				title: 'IAPM (mm)',
				className: 'text-center',
			},
			{
				data: 'periodo',
				title: 'Data',
				className: 'text-center',
				orderable: false,
			},
			{
				data: 'classificacao',
				title: 'Classificação',
				className: 'text-center',
				orderable: false,
			},
		];
	}

	gerarRelatorio() {
		if (this.inputs.form?.invalid) {
			return;
		}

		const agrupamento = this.inputs.form?.get('agrupamento')?.value;
		const periodoBusca = this.inputs.form?.get('periodoBusca')?.value;
		const dataInicio = this.inputs.form?.get('dataInicio')?.value;
		const dataFim = this.inputs.form?.get('dataFim')?.value;

		let id = 0;

		switch (agrupamento) {
			case 'MICRORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MICRORREGIAO)?.value;
				break;
			case 'MESORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MESORREGIAO)?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.getDataFromForm(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.value;
				break;
			case 'BACIA':
				id = this.getDataFromForm(FormularioRelatorio.BACIA)?.value;
				break;
		}

		if (id == 0 || !agrupamento || !periodoBusca || !dataInicio || !dataFim) {
			return;
		}

		this.titlePeriodo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		);
		this.tituloTabela = `Índice de anomalia de precipitação modificado (IAPM) - ${this.titlePeriodo}`;
		this.periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		this.handleFetchIAPM(
			id,
			agrupamento,
			this.handleFormatPeriodoBusca(periodoBusca),
			formatISO(new Date(dataInicio)),
			formatISO(new Date(dataFim))
		);
		this.atualizarTituloColuna(agrupamento);
		this.atualizarColunasTabela();
	}

	setDatasByPeriodoBusca(periodoBuscaValue: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();

			switch (periodoBuscaValue) {
				case 'anual':
					dataFim.setValue(moment(hoje).subtract(1, 'year').endOf('year'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_ANOS, 'year')
							.startOf('year')
					);
					break;
				case 'mensal':
					dataFim.setValue(moment(hoje).subtract(1, 'month').startOf('month'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_MESES, 'month')
							.endOf('month')
					);
					break;
			}
		}
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MESORREGIAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);

		switch (agrupamentoValue) {
			case 'BACIA':
				this.inputs.form
					?.get(FormularioRelatorio.BACIA)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MESORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MESORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					?.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: (periodo: PeriodosLabel | null) => {
					this.setDatasByPeriodoBusca(periodo);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	private handleFetchIAPM(
		id: number,
		agrupamento: string,
		periodo: string,
		dataInicial: string,
		dataFinal: string
	) {
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.service
			.gerarGraficoIAPM(id, agrupamento, periodo, dataInicial, dataFinal)
			.subscribe({
				next: values => {
					this.relatorioService.verificaExistenciaDados(values);
					this.tableData = values.map(value => ({
						...value,
						iapm: numberToBrNumber(value.iapm, 1) || '-',
						periodo:
							periodo === 'MENSAL'
								? value.periodo.padStart(7, '0')
								: value.periodo,
					}));
					this.titleTable = values[0].nome.toUpperCase();
				},
				error: () => {
					this.toastr.error('Erro ao buscar as informações');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form?.get(data);
	}

	private handleFormatPeriodoBusca(periodo: string) {
		if (periodo === 'anual') return 'ANUAL';
		if (periodo === 'mensal') return 'MENSAL';
		return 'PERIODO';
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}
}
