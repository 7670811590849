import mensagens from './mensagens';
import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
	SerieVariavelMeteorologica,
} from './serie-variavel-meteorologica';
import { Color, getOptions } from 'highcharts';

export interface DadosVento extends DadosGraficoVariaveis {
	velocidadeVento: number[];
	direcaoVento: number[];
}

export class SerieVento extends SerieVariavelMeteorologica {
	private corVelocidadeVento = getOptions().colors?.at(0);
	private corDirecaoVento = getOptions().colors?.at(1);

	obterDadosSerie(
		dadosGrafico: DadosVento,
		indexEixo: number
	): DadosSerieVariavelMeteorologica {
		return {
			serie: [
				this.obterSerieVelocidadeVento(dadosGrafico, indexEixo),
				this.obterSerieDirecaoVento(dadosGrafico, indexEixo),
			],
			eixo: {
				title: {
					text: mensagens['variavel.vento.titulo-meteograma'],
				},
				offset: 0,
				lineWidth: 2,
				opposite: false,
				labels: { align: 'left', x: 3 },
			},
		};
	}

	private obterSerieVelocidadeVento(
		dadosGrafico: DadosVento,
		indexEixo: number
	): Object {
		if (this.corVelocidadeVento) {
			return {
				type: 'area',
				yAxis: indexEixo,
				keys: ['x', 'y', 'rotation'],
				data: this.prepararSerieVento(dadosGrafico),
				color: this.corVelocidadeVento,
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, this.corVelocidadeVento],
						[1, new Color(this.corVelocidadeVento).setOpacity(0.25).get()],
					],
				},
				name: mensagens['variavel.velocidade'],
				tooltip: {
					valueDecimals: 2,
					valueSuffix: `${mensagens['variavel.velocidade.unidade']}`,
				},
			};
		}
		return {};
	}

	private obterSerieDirecaoVento(
		dadosGrafico: DadosVento,
		indexEixo: number
	): Object {
		const dadosFormatados = this.prepararSerieVento(dadosGrafico);

		return {
			type: 'windbarb',
			yAxis: indexEixo,
			data: this.prepararSerieVento(dadosGrafico),
			name: mensagens['variavel.vento'], // TODO: Corrigir para usar a direção
			color: this.corDirecaoVento,
			showInLegend: false,
			turboThreshold: dadosFormatados.length,
			tooltip: {
				valueDecimals: 2,
				valueSuffix: ` ${mensagens['variavel.velocidade.unidade']}`, // TODO: Corrigir para usar a direção
			},
		};
	}

	private prepararSerieVento({
		datas,
		velocidadeVento,
		direcaoVento,
	}: DadosVento) {
		const serie = [];
		const tamanho = datas.length;

		for (let i = 0; i < tamanho; i++) {
			serie.push([datas[i], velocidadeVento[i], direcaoVento[i]]);
		}

		return serie;
	}
}
