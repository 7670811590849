import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import { Validators } from '@angular/forms';
import { HighStockComponent } from '@componentes/high-stock/high-stock.component';
import { optionsChartObservadoDesvioClimatologia } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada-desvio-climatologia/chartOptions';
import { format } from 'date-fns-tz';
import * as Highcharts from 'highcharts/highstock';
import { Subject, takeUntil } from 'rxjs';

import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	criarImagemBase64FromChart,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { corrigeDuplicacaoNome, gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import {
	Agrupamento,
	RelatorioDesvio,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';

@Component({
	selector: 'seira-grafico-pluviometria-observado-desvio-climatologia',
	templateUrl:
		'./grafico-pluviometria-observado-desvio-climatologia.component.html',
	styleUrls: [
		'./grafico-pluviometria-observado-desvio-climatologia.component.scss',
	],
})
export class GraficoPluviometriaObservadoDesvioClimatologiaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild(HighStockComponent, { static: false })
	highStockComponent: HighStockComponent;
	inputs = inject(INPUTS_RELATORIOS);
	chart: Highcharts.Chart;
	loading = false;
	gerou = false;
	_destroyed = new Subject();
	tipoDesvio = '';
	resultado = false;
	chartOptions: Highcharts.Options = optionsChartObservadoDesvioClimatologia(
		this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA'
	);

	descricaoRelatorio = '';
	descricaoRelatorioMilimetro =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. ' +
		'O desvio em milímetros refere-se à diferença entre a quantidade de chuva registrada e a sua climatologia para o mesmo período, expressa em milímetros em uma determinada localidade. ' +
		'A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';
	descricaoRelatorioPorcentagem =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. ' +
		'O desvio em porcentagem refere-se à variação da precipitação em relação à sua climatologia, expressa como uma porcentagem para indicar se o volume de chuva foi superior ou inferior ao esperado. ' +
		'A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	periodoTitulo = '';
	preciptacoesAgrupadas: any[];
	anos: string[];
	chartAux: any;
	tituloAgrupamento: string;

	constructor(
		private toastr: ToastrService,
		private readonly relatorioService: RelatoriosService
	) {}

	ngOnInit() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });
		this.tipoDesvio =
			this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA'
				? 'milimetro'
				: 'porcentagem';

		this.descricaoRelatorio =
			this.tipoDesvio === 'milimetro'
				? this.descricaoRelatorioMilimetro
				: this.descricaoRelatorioPorcentagem;

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	gerarRelatorio() {
		this.loading = true;
		this.inputs.setLoading(true);
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		const diaInicio =
			periodoAgrupamento !== 'anual'
				? dataInicio
				: new Date(`${new Date(dataInicio).getFullYear()}-01-01T00:00:00`);
		const diaFim =
			periodoAgrupamento !== 'anual'
				? dataFim
				: new Date(`${new Date(dataFim).getFullYear()}-12-31T00:00:00`);

		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		let id = null;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoAgrupamento
		);

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				id = this.inputs.form.get('estacao')?.value;
				break;
			case 'MICRORREGIAO':
				id = this.inputs.form.get('microrregiao')?.value;
				break;
			case 'MESORREGIAO':
				id = this.inputs.form.get('mesorregiao')?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.inputs.form.get('regiao')?.value;
				break;
			case 'BACIA':
				id = this.inputs.form.get('bacia')?.value;
				break;
			case 'MUNICIPIO':
				id = this.inputs.form.get('municipio')?.value;
				break;
		}

		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento,
				idEntidade: id,
				dataFim: new Date(diaFim),
				dataInicio: new Date(diaInicio),
				periodo: periodoAgrupamento.toUpperCase(),
				incluirDetalhes: false,
			})
			.subscribe({
				next: preciptacoesAgrupadas => {
					this.relatorioService.verificaExistenciaDados(preciptacoesAgrupadas);
					this.preciptacoesAgrupadas = preciptacoesAgrupadas;
					this.configurarChart(preciptacoesAgrupadas, periodoAgrupamento);
					this.chartOptions = {
						...this.chartOptions,
						exporting: {
							enabled: true,
							buttons: {
								contextButton: {
									menuItems: [
										'viewFullscreen',
										'separator',
										'downloadPNG',
										'downloadJPEG',
									],
								},
							},
							filename: gerarFilenameGrafico(
								`grafico_pluviometria_observada_desvio_${
									this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
								}_${this.inputs.form
									.get(FormularioRelatorio.AGRUPAMENTO)
									?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
							),
						},
					};
					this.resultado = !!preciptacoesAgrupadas[0];
				},
				error: () => {
					this.inputs.setLoading(false);
					this.resultado = false;
					this.loading = false;
					this.gerou = false;
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getTipoPrecipitacao(
		tipoAgrupamento: any,
		precipitacao: any,
		isXLSX: boolean,
		tipoDesvio?: string,
		dados?: any[],
		desvio?: {
			data: string;
			chuva: number;
			desvioMilimetro?: number;
			desvioPorcentagem?: number;
			climatologia: number;
		}
	) {
		if (!isXLSX) {
			if (tipoAgrupamento === 'MUNICIPIO_POSTO') {
				return corrigeDuplicacaoNome(precipitacao.municipio);
			} else if (tipoAgrupamento === 'MUNICIPIO') {
				return precipitacao.municipio;
			} else if (tipoAgrupamento === 'MICRORREGIAO') {
				return precipitacao.microrregiao;
			} else if (tipoAgrupamento === 'MESORREGIAO') {
				return precipitacao.mesorregiao;
			} else if (tipoAgrupamento === 'BACIA') {
				return precipitacao.bacia;
			} else if (tipoAgrupamento === 'REGIAO_PLUVIOMETRICA') {
				return precipitacao.regiaoPluviometrica;
			} else {
				return precipitacao.subBacia;
			}
		} else {
			const formatarDadosBase = (desvio: {
				data: string;
				chuva: string | number | null | undefined;
				climatologia: string | number | null | undefined;
			}) => ({
				data: DateTimeUtils.formatarData(
					DateTimeUtils.formatToZonedDateTime(desvio.data),
					'MM/yyyy'
				),
				chuva: numberToBrNumber(desvio.chuva, 1),
				climatologia: numberToBrNumber(desvio.climatologia, 1),
			});

			const obterDadosAgrupamento = (
				groupType: string | number,
				precipitacao: {
					municipio: string;
					microrregiao: any;
					mesorregiao: any;
					bacia: any;
					regiaoPluviometrica: any;
					subBacia: any;
				}
			) => {
				const mapping = {
					MUNICIPIO_POSTO: {
						municipio: precipitacao.municipio
							? corrigeDuplicacaoNome(precipitacao.municipio)
							: '',
					},
					MUNICIPIO: { municipio: precipitacao.municipio },
					MICRORREGIAO: { microrregiao: precipitacao.microrregiao },
					MESORREGIAO: { mesorregiao: precipitacao.mesorregiao },
					BACIA: { bacia: precipitacao.bacia },
					REGIAO_PLUVIOMETRICA: {
						regiaoPluviometrica: precipitacao.regiaoPluviometrica,
					},
					DEFAULT: { subbacia: precipitacao.subBacia },
				};
				return mapping[groupType as keyof typeof mapping] || mapping.DEFAULT;
			};

			const addDados = (
				groupType: any,
				precipitacao: any,
				desvio: {
					data: string;
					chuva: number;
					desvioMilimetro?: number;
					desvioPorcentagem?: number;
					climatologia: number;
				},
				tipoDesvio: string | undefined
			) => {
				const baseData = formatarDadosBase(desvio);
				const agrupamento = obterDadosAgrupamento(groupType, precipitacao);
				const desvioKey =
					tipoDesvio === 'milimetro' ? 'desvioMilimetro' : 'desvioPorcentagem';

				dados?.push({
					...baseData,
					...agrupamento,
					[desvioKey]: numberToBrNumber(desvio[desvioKey], 1),
				});
			};

			addDados(tipoAgrupamento, precipitacao, desvio as any, tipoDesvio);
		}
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];
		const tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA);
		const tipoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.AGRUPAMENTO
		)?.value;
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		const colunas = [
			{
				text: Agrupamento[agrupamento as keyof typeof Agrupamento],
				fillColor: '#DCDCDC',
			},
			{
				text: tipoPeriodo?.value === 'mensal' ? 'Mês/ano' : 'Ano',
				fillColor: '#DCDCDC',
			},
			{ text: 'Observado (mm)', fillColor: '#DCDCDC' },
			{
				text: this.tipoDesvio === 'milimetro' ? 'Desvio (mm)' : 'Desvio (%)',
				fillColor: '#DCDCDC',
			},
			{ text: 'Climatologia (mm)', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		let rowData: any[] = [];

		if (tipoPeriodo?.value === 'mensal') {
			this.preciptacoesAgrupadas.forEach(precipitacao => {
				if (this.tipoDesvio === 'milimetro') {
					precipitacao.desvios.forEach(
						(desvio: {
							data: string;
							chuva: number;
							desvioMilimetro: number;
							climatologia: number;
						}) => {
							rowData = [
								this.getTipoPrecipitacao(tipoAgrupamento, precipitacao, false),
								DateTimeUtils.formatarData(
									DateTimeUtils.formatToZonedDateTime(desvio.data),
									'MM/yyyy'
								),
								numberToBrNumber(desvio.chuva, 1),
								numberToBrNumber(desvio.desvioMilimetro, 1),
								numberToBrNumber(desvio.climatologia, 1),
							];

							tableData.push(rowData);
						}
					);
				} else {
					precipitacao.desvios.forEach(
						(desvio: {
							data: string;
							chuva: number;
							desvioPorcentagem: number;
							climatologia: number;
						}) => {
							rowData = [
								this.getTipoPrecipitacao(tipoAgrupamento, precipitacao, false),
								DateTimeUtils.formatarData(
									DateTimeUtils.formatToZonedDateTime(desvio.data),
									'MM/yyyy'
								),
								numberToBrNumber(desvio.chuva, 1),
								numberToBrNumber(desvio.desvioPorcentagem, 1),
								numberToBrNumber(desvio.climatologia, 1),
							];

							tableData.push(rowData);
						}
					);
				}
			});
		} else {
			let rowData: any[] = [];

			this.anos.map(ano => {
				const dadosDoAno = this.preciptacoesAgrupadas[0].desvios.filter(
					(item: any) => item.data.startsWith(ano)
				);

				const chuvaAnual = dadosDoAno.reduce(
					(total: any, item: any) => total + item.chuva,
					0
				);

				const climatologiaAnual = dadosDoAno.reduce(
					(total: any, item: any) => total + item.climatologia,
					0
				);

				const desvioMilimetroAnual = dadosDoAno.reduce(
					(total: any, item: any) => total + item.desvioMilimetro,
					0
				);

				const desvioPorcentagemAnual =
					climatologiaAnual !== 0
						? (desvioMilimetroAnual / climatologiaAnual) * 100
						: 0;

				this.preciptacoesAgrupadas.forEach(precipitacao => {
					if (this.tipoDesvio === 'milimetro') {
						rowData = [
							this.getTipoPrecipitacao(tipoAgrupamento, precipitacao, false),
							ano,
							numberToBrNumber(chuvaAnual, 1),
							numberToBrNumber(desvioMilimetroAnual, 1),
							numberToBrNumber(Number(climatologiaAnual), 1),
						];

						tableData.push(rowData);
					} else {
						rowData = [
							this.getTipoPrecipitacao(tipoAgrupamento, precipitacao, false),
							ano,
							numberToBrNumber(chuvaAnual, 1),
							numberToBrNumber(desvioPorcentagemAnual, 1),
							numberToBrNumber(Number(climatologiaAnual), 1),
						];

						tableData.push(rowData);
					}
				});
			});
		}

		return tableData;
	}

	retornarIAPMParaXLSX() {
		const dados: any[] = [];
		const tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA);
		const tipoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.AGRUPAMENTO
		)?.value;

		if (tipoPeriodo?.value === 'mensal') {
			this.preciptacoesAgrupadas.forEach(precipitacao => {
				if (this.tipoDesvio === 'milimetro') {
					precipitacao?.desvios.forEach(
						(desvio: {
							data: string;
							chuva: number;
							desvioMilimetro: number;
							desvioPorcentagem: number;
							climatologia: number;
						}) => {
							this.getTipoPrecipitacao(
								tipoAgrupamento,
								precipitacao,
								true,
								this.tipoDesvio,
								dados,
								desvio
							);
						}
					);
				} else {
					precipitacao.desvios.forEach(
						(desvio: {
							data: string;
							chuva: number;
							desvioPorcentagem: number;
							climatologia: number;
						}) => {
							this.getTipoPrecipitacao(
								tipoAgrupamento,
								precipitacao,
								true,
								this.tipoDesvio,
								dados,
								desvio
							);
						}
					);
				}
			});
		} else {
			this.anos.map(ano => {
				const dadosDoAno = this.preciptacoesAgrupadas[0].desvios.filter(
					(item: any) => item.data.startsWith(ano)
				);

				const chuvaAnual = dadosDoAno.reduce(
					(total: any, item: any) => total + item.chuva,
					0
				);

				const climatologiaAnual = dadosDoAno.reduce(
					(total: any, item: any) => total + item.climatologia,
					0
				);

				const desvioMilimetroAnual = dadosDoAno.reduce(
					(total: any, item: any) => total + item.desvioMilimetro,
					0
				);

				const desvioPorcentagemAnual =
					climatologiaAnual !== 0
						? (desvioMilimetroAnual / climatologiaAnual) * 100
						: 0;

				this.preciptacoesAgrupadas.forEach(precipitacao => {
					tipoAgrupamento === 'MUNICIPIO_POSTO' ||
					tipoAgrupamento === 'MUNICIPIO'
						? dados.push({ municipio: precipitacao.municipio })
						: tipoAgrupamento === 'MICRORREGIAO'
						? dados.push({ microrregiao: precipitacao.microrregiao })
						: tipoAgrupamento === 'MESORREGIAO'
						? dados.push({ mesorregiao: precipitacao.mesorregiao })
						: tipoAgrupamento === 'BACIA'
						? dados.push({ bacia: precipitacao.bacia })
						: tipoAgrupamento === 'REGIAO_PLUVIOMETRICA'
						? dados.push({
								regiaoPluviometrica: precipitacao.regiaoPluviometrica,
						  })
						: dados.push({ subbacia: precipitacao.subBacia });

					if (this.tipoDesvio === 'milimetro') {
						dados.push({
							data: ano,
							chuva: numberToBrNumber(chuvaAnual, 1),
							desvioMilimetro: numberToBrNumber(desvioMilimetroAnual, 1),
							climatologia: numberToBrNumber(Number(climatologiaAnual), 1),
						});
					} else {
						dados.push({
							data: ano,
							chuva: numberToBrNumber(chuvaAnual, 1),
							desvioPorcentagem: numberToBrNumber(desvioPorcentagemAnual, 1),
							climatologia: numberToBrNumber(Number(climatologiaAnual), 1),
						});
					}
				});
			});
		}

		return dados;
	}

	extrairDatasPrecipitacao(
		preciptacaoAgrupada: RelatorioDesvio,
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacaoAgrupada.desvios
					.filter((el: any) => el.data)
					.map((el: any) => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a: any, b: any) => +a - +b)
					.map((data: any) => {
						let formato = 'MM/yyyy';
						if (periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato, {
							timeZone: 'America/Sao_Paulo',
						});
					})
			),
		];
	}

	configurarChart(
		preciptacoesAgrupadas: any[],
		periodoAgrupamento: PeriodosLabel
	) {
		const sufixoTipo = this.tipoDesvio === 'milimetro' ? 'mm' : '%';
		const categorias = ['Observado', `Desvio ${sufixoTipo}`, 'Climatologia'];

		this.chartOptions.title = {
			text: `Pluviometria observada/climatologia(mm)/desvio(${sufixoTipo}) - ${this.periodoTitulo}`,
			align: 'center',
			style: {
				fontSize: '18px',
			},
		};

		const dataSeries: string[] =
			preciptacoesAgrupadas.length === 0
				? []
				: this.extrairDatasPrecipitacao(
						preciptacoesAgrupadas[0],
						periodoAgrupamento
				  );

		this.chartOptions.series = categorias.map(serie => {
			let resultado: any[] = [];
			const y = serie !== `Desvio ${sufixoTipo}` ? 0 : 1;
			const sufixo = serie === 'Desvio %' ? '%' : 'mm';
			const tipo = serie == `Desvio ${sufixoTipo}` ? 'spline' : 'column';

			if (dataSeries.length === 0) {
				resultado = [];
			} else if (periodoAgrupamento === 'mensal') {
				let i = 0;
				if (serie === 'Observado') {
					resultado = preciptacoesAgrupadas[0].desvios.map((dados: any) => {
						const retorno = [dataSeries[i], dados?.chuva];
						i += 1;
						return retorno;
					});
				} else if (serie === 'Climatologia') {
					resultado = preciptacoesAgrupadas[0].desvios.map((dados: any) => {
						const retorno = [dataSeries[i], dados?.climatologia];
						i += 1;
						return retorno;
					});
				} else {
					resultado = preciptacoesAgrupadas[0].desvios.map((dados: any) => {
						const retorno = [
							dataSeries[i],
							this.tipoDesvio === 'milimetro'
								? dados?.desvioMilimetro
								: dados?.desvioPorcentagem,
						];
						i += 1;
						return retorno;
					});
				}
			} else {
				this.anos = dataSeries;
				const dadosAgrupados = dataSeries.map(ano => {
					const dadosDoAno = preciptacoesAgrupadas[0].desvios.filter(
						(item: any) => item.data.startsWith(ano)
					);

					const chuvaAnual = dadosDoAno.reduce(
						(total: any, item: any) => total + item.chuva,
						0
					);

					const climatologiaAnual = dadosDoAno.reduce(
						(total: any, item: any) => total + item.climatologia,
						0
					);

					const desvioMilimetroAnual = dadosDoAno.reduce(
						(total: any, item: any) => total + item.desvioMilimetro,
						0
					);

					const desvioPorcentagemAnual =
						climatologiaAnual !== 0
							? (desvioMilimetroAnual / climatologiaAnual) * 100
							: 0;

					return {
						ano,
						chuvaAnual,
						climatologiaAnual,
						desvioMilimetroAnual,
						desvioPorcentagemAnual,
					};
				});

				if (serie === 'Observado') {
					resultado = dadosAgrupados.map(dados => [
						dados.ano,
						dados.chuvaAnual,
					]);
				} else if (serie === 'Climatologia') {
					resultado = dadosAgrupados.map(dados => [
						dados.ano,
						dados.climatologiaAnual,
					]);
				} else {
					resultado = dadosAgrupados.map(dados => [
						dados.ano,
						this.tipoDesvio === 'milimetro'
							? dados.desvioMilimetroAnual
							: dados.desvioPorcentagemAnual,
					]);
				}
			}

			return {
				name: serie,
				type: tipo,
				tooltip: {
					valueDecimals: 1,
					valueSuffix: sufixo,
				},
				yAxis: y,
				marker: {
					enabled: y,
					radius: 5,
				},
				zIndex: y * 10,
				data: resultado,
			} as Highcharts.SeriesColumnOptions;
		});

		this.chartOptions.xAxis = {
			categories: dataSeries,
		};
	}

	async exportarPDF() {
		if (this.chart.xAxis && this.chart.yAxis) {
			this.chartAux = this.chart;
		}

		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.chart || !this.resultado) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chartAux),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Pluviometria observada/climatologia (mm) / desvio (${
					this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
				}) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
			{
				table: {
					widths: ['20%', '20%', '20%', '20%', '20%'],
					body: this.getDadosTabelaParaExportacao(true),
					layout: {
						noWrap: false,
						fontSize: 5,
					},
				},

				marginTop: 10,
			},
		];

		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(false);

		DocumentExporter.gerarCSV(
			tableData,
			`Pluviometria observada/climatologia (mm)/desvio (%) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Pluviometria observada/climatologia (mm)/desvio (%) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;
		const tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA);
		const nomeArquivo = `Pluviometria observada/climatologia (mm) / desvio (${
			this.tipoDesvio === 'porcentagem' ? '%' : 'mm'
		}) - ${this.periodoTitulo}`;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			tipoAgrupamento === 'MUNICIPIO_POSTO'
				? {
						label: 'municipio',
						text: 'Município/posto',
						size: 48,
				  }
				: tipoAgrupamento === 'MUNICIPIO'
				? {
						label: 'municipio',
						text: 'Município',
						size: 48,
				  }
				: tipoAgrupamento === 'MICRORREGIAO'
				? {
						label: 'microrregiao',
						text: 'Microrregião',
						size: 48,
				  }
				: tipoAgrupamento === 'MESORREGIAO'
				? {
						label: 'mesorregiao',
						text: 'Mesorregião',
						size: 48,
				  }
				: tipoAgrupamento === 'BACIA'
				? {
						label: 'bacia',
						text: 'Bacia',
						size: 48,
				  }
				: tipoAgrupamento === 'REGIAO_PLUVIOMETRICA'
				? {
						label: 'regiaoPluviometrica',
						text: 'Região pluviométrica',
						size: 48,
				  }
				: {
						label: 'subbacia',
						text: 'Sub-bacia',
						size: 48,
				  },
		];

		headers.push(
			{
				label: 'data',
				text: tipoPeriodo?.value === 'mensal' ? 'Mês' : 'Ano',
				size: 24,
			},
			{ label: 'chuva', text: 'Observado (mm)', size: 24 },
			{
				label:
					this.tipoDesvio === 'milimetro'
						? 'desvioMilimetro'
						: 'desvioPorcentagem',
				text: this.tipoDesvio === 'milimetro' ? 'Desvio (mm)' : 'Desvio (%)',
				size: 24,
			},
			{ label: 'climatologia', text: 'Climatologia (mm)', size: 24 }
		);
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarIAPMParaXLSX(),
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
