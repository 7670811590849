<div class="d-flex flex-column">
	<label class="text-center text-tertiary fw-semibold w-100">
		Pluviometria observada anual (mm) - {{ periodo }}
	</label>

	<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />
	<div
		*ngIf="carregandoRelatorio; else tabela"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>
	<ng-template #tabela>
		<div
			style="max-width: 1300px"
			*ngIf="dadosTabelaPrecipitacaoAcumulada; else semDados">
			<seira-tabela-relatorios-precipitacao-acumulada
				[relatorios]="
					dadosTabelaPrecipitacaoAcumulada
				"></seira-tabela-relatorios-precipitacao-acumulada>
		</div>
	</ng-template>
</div>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
