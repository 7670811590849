<div>
	<label class="text-center text-tertiary fw-semibold w-100">
		Variável meteorológica interpolada - {{ variavel }} - {{ periodoTitulo }}
	</label>
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

	<div class="col-12 p-0">
		<div class="col-12 mb-3 mb-lg-0 p-0">
			<div id="mapa-legenda" class="p-0 mx-1 w-100" style="position: relative">
				<seira-mapa-interpolacao
					[mapaId]="mapaNome"
					[mapaNome]="mapaNome"
					[imageOpacity]="0.8"
					[interpolacao]="interpolacao!"
					[carregando]="carregando"
					[valores]="valores"
					[medicoes]="medicoes"
					[unidade]="unidade"
					(mapaEmitter)="setupMap($event)">
				</seira-mapa-interpolacao>
			</div>
		</div>
	</div>
</div>
<ng-template #legenda>
	<seira-monitoramento-legenda
		maxWidth="10"
		class="d-flex align-items-center justify-content-center"
		[valores]="interpolacao!.legenda"
		[carregando]="carregando"></seira-monitoramento-legenda>
	<seira-monitoramento-legenda-pcd
		class="d-flex align-items-center justify-content-center mt-2"
		maxWidth="10"></seira-monitoramento-legenda-pcd>
</ng-template>
