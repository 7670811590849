import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { Observable } from 'rxjs';
import {
	QuantisPrecipitacao,
	QuantisRegiaoPluviometrica,
	QuantisResponse,
	QuantisValores,
} from '../interfaces';

/**
 * @description Serviço para buscar os dados PUBLICOS de quantis
 */
@Injectable({
	providedIn: 'root',
})
export class QuantisMicrorregiaoService {
	constructor(private http: HttpClientProtectedService) {}

	getQuantisMensalTabelaByEstacaoId(
		postoId: number,
		mesInicio: string,
		mesFim: string
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		const dados = { postoId, dataInicio: mesInicio, dataFim: mesFim };

		return this.http.post(Rotas.QUANTIS + `/mensal/tabela/estacao`, dados);
	}

	getQuantisAnualTabelaByEstacaoId(
		postoId: number,
		anoInicio: string,
		anoFim: string
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		const dados = { postoId, dataInicio: anoInicio, dataFim: anoFim };

		return this.http.post(Rotas.QUANTIS + `/anual/tabela/estacao`, dados);
	}

	getQuantisMensalGraficoByEstacaoId(
		postoId: number,
		mesInicio: string,
		mesFim: string
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		const dados = { postoId, dataInicio: mesInicio, dataFim: mesFim };

		return this.http.post(Rotas.QUANTIS + `/mensal/grafico/estacao`, dados);
	}

	getQuantisAnualGraficoByEstacaoId(
		postoId: number,
		anoInicio: string,
		anoFim: string
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		const dados = { postoId, dataInicio: anoInicio, dataFim: anoFim };

		return this.http.post(Rotas.QUANTIS + `/anual/grafico/estacao`, dados);
	}

	getQuantisMensalGraficoByRegiaoId(
		regiaoId: number,
		mesInicio: string,
		mesFim: string
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		const dados = { regiaoId, dataInicio: mesInicio, dataFim: mesFim };

		return this.http.post(Rotas.QUANTIS + `/mensal/grafico/regiao`, dados);
	}

	getQuantisAnualGraficoByRegiaoId(
		regiaoId: number,
		anoInicio: string,
		anoFim: string
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		const dados = { regiaoId, dataInicio: anoInicio, dataFim: anoFim };

		return this.http.post(Rotas.QUANTIS + `/anual/grafico/regiao`, dados);
	}

	getQuantisByRegiaoAndPeriodoChuvoso(
		regiaoId: string,
		periodoChuvoso: string
	): Observable<QuantisRegiaoPluviometrica> {
		return this.http.get(
			Rotas.QUANTIS +
				`/pluviometrico/mensal/grafico/regiao?regiaoId=${regiaoId}&periodo=${periodoChuvoso}`
		);
	}

	getUltimosQuantisByRegiaoAndPeriodoChuvoso(
		regiaoId: string,
		periodoChuvoso: string,
		quantidade: number
	): Observable<QuantisResponse<QuantisValores[]>> {
		return this.http.get(Rotas.QUANTIS + '/ultimos', {
			regiaoId,
			quantidade,
			periodoChuvoso,
		});
	}
}
