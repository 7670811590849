export enum AgrupamentoGeojson {
	MUNICIPIO = 'assets/geoJson/pb-municipios-geo.json',
	MESORREGIAO = 'assets/geoJson/pb-mesorregioes-geo.json',
	BACIA = 'assets/geoJson/pb-bacias-geo.json',
	SUBBACIA = 'assets/geoJson/pb-subBacias-geo.json',
	BACIA_SUBBACIA = 'assets/geoJson/pb-subBacias-bacia-geo.json',
	REGIAO_PLUVIOMETRICA = 'assets/geoJson/pb-regioes-pluviometricas-geo.json',
	MICRORREGIAO = 'assets/geoJson/pb-microrregioes-geo.json',
}
export const agrupamentoGeojsonMap: { [key: string]: AgrupamentoGeojson } = {
	MUNICIPIO: AgrupamentoGeojson.MUNICIPIO,
	MICRORREGIAO: AgrupamentoGeojson.MICRORREGIAO,
	MESORREGIAO: AgrupamentoGeojson.MESORREGIAO,
	BACIA: AgrupamentoGeojson.BACIA,
	SUBBACIA: AgrupamentoGeojson.SUBBACIA,
	BACIA_SUBBACIA: AgrupamentoGeojson.BACIA_SUBBACIA,
	REGIAO_PLUVIOMETRICA: AgrupamentoGeojson.REGIAO_PLUVIOMETRICA,
};
