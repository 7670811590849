<div class="d-flex flex-column">
	<label class="text-center text-tertiary fw-semibold w-100">
		Pluviometria observada mensal (mm) - {{ periodoTitulo }}
	</label>
	<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />

	<div
		*ngIf="carregando; else tabela"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #tabela>
		<div
			style="max-width: 1300px"
			*ngIf="precipitacaoAnual.length; else nenhumResultado">
			<seira-tabela-relatorio-precipitacao-anual
				[precipitacaoAnual]="precipitacaoAnual"
				[estatisticas]="
					estatisticas
				"></seira-tabela-relatorio-precipitacao-anual>
		</div>
	</ng-template>

	<ng-template #nenhumResultado>
		<seira-sem-dados></seira-sem-dados>
	</ng-template>
</div>
