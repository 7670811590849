import mensagens from './mensagens';
import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
	SerieVariavelMeteorologica,
} from './serie-variavel-meteorologica';
import { Color } from 'highcharts';
export interface DadosRadiacaoSolar extends DadosGraficoVariaveis {
	radiacao: number[];
}

export class SerieRadiacaoSolar extends SerieVariavelMeteorologica {
	private corRadiacao = '#FCC751';

	constructor() {
		super();
	}

	obterDadosSerie(
		dados: DadosRadiacaoSolar,
		indexEixo: number
	): DadosSerieVariavelMeteorologica {
		return {
			serie: [
				{
					yAxis: indexEixo,
					name: mensagens['variavel.radiacaoSolar'],
					type: 'area',
					data: this.prepararSerie(dados.datas, dados.radiacao),
					color: this.corRadiacao,
					tooltip: {
						valueDecimals: 2,
						valueSuffix: ` ${mensagens['variavel.radiacaoSolar.unidade']}`,
					},
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
							[0, this.corRadiacao],
							[1, new Color(this.corRadiacao).setOpacity(0).get('rgba')],
						],
					},
				},
			],
			eixo: {
				title: {
					text: mensagens['variavel.radiacaoSolar.titulo-meteograma'],
				},
				lineWidth: 2,
				opposite: false,
				labels: { align: 'left', x: 3 },
			},
		};
	}
}
