import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS_QUANTIS,
	InstanciaRelatorio,
	QuantisPrecipitacao,
	QuantisResponse,
	QuantisValores,
	Relatorios,
} from '../../../interfaces';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	QuantisMicrorregiaoService,
	RelatoriosService,
} from '../../../services';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { CondicaoMap } from '../../../utils';
import { isNotNuloOuUndefined, numberToBrNumber, verificaSePDF } from '@utils';
import { Validators } from '@angular/forms';
import { DateTimeUtils } from '@utils/datetime-util';
import { DataModel } from 'mr-excel';
import * as ExcelTable from 'mr-excel';
import { Meses } from '../../../enum';
import { DocumentExporter } from '@utils/document-exporter';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'seira-pluviometro-tabela-quantis',
	templateUrl: './pluviometro-tabela-quantis.component.html',
	styleUrls: ['./pluviometro-tabela-quantis.component.scss'],
})
export class PluviometroTabelaQuantisComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como quantis mensais, os valores que dividem uma amostra de dados mensais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	descricaoRelatorioAnual =
		'Define-se como quantis anuais, os valores que dividem uma amostra de dados anuais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	legenda =
		'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

	inputs = inject(INPUTS_RELATORIOS_QUANTIS);
	estacoes: Estacao[] = [];
	tableData: Relatorios<QuantisPrecipitacao> | null;
	municipioNome = '';
	isLoading = true;
	isAnual = false;
	quantisType = '';
	periodoTitulo = '';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	constructor(
		private readonly quantisService: QuantisMicrorregiaoService,
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.descricaoRelatorio =
			periodoBusca === 'mensal'
				? this.descricaoRelatorioMensal
				: this.descricaoRelatorioAnual;
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	getTipo(): string {
		return this.getFormItemValue(FormularioRelatorio.PERIODO_BUSCA) === 'mensal'
			? 'QUANTIS_MENSAL'
			: 'QUANTIS_ANUAL';
	}

	gerarRelatorio() {
		const estacao = this.getFormItemValue(FormularioRelatorio.ESTACAO);
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		);

		if (!estacao || !periodoBusca || !this.estacoes?.length) {
			return;
		}

		this.setQuantis(estacao, dataInicio, dataFim);
	}

	setQuantis(estacao: number, dataInicio: string, dataFim: string) {
		if (this.getTipo() === 'QUANTIS_MENSAL') {
			this.quantisType = 'Quantis mensal';
			this.descricaoRelatorio = this.descricaoRelatorioMensal;
			this.getQuantisMensal(estacao, dataInicio, dataFim);
		}
		if (this.getTipo() === 'QUANTIS_ANUAL') {
			this.quantisType = 'Quantis anual';
			this.descricaoRelatorio = this.descricaoRelatorioAnual;
			this.getQuantisAnual(estacao, dataInicio, dataFim);
		}
	}

	getQuantisMensal(estacao: number, mesInicio: string, mesFim: string) {
		this.isAnual = false;
		this.isLoading = true;
		this.inputs.setLoading(true);
		this.quantisService
			.getQuantisMensalTabelaByEstacaoId(estacao, mesInicio, mesFim)
			.subscribe({
				next: val => {
					this.municipioNome = val.municipio!;
					this.tableData = this.getTableDataFormatada(val);
					this.relatoriosService.verificaExistenciaDados(val.quantis);
				},
				error: err => {
					this.tableData = null;
					console.error(err);
					this.toastr.error('Erro ao obter informações do posto selecionado');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getQuantisAnual(estacao: number, anoInicio: string, anoFim: string) {
		this.isAnual = true;
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.quantisService
			.getQuantisAnualTabelaByEstacaoId(estacao, anoInicio, anoFim)
			.subscribe({
				next: val => {
					this.municipioNome = val.municipio!;
					this.tableData = this.getTableDataFormatada(val);
					this.relatoriosService.verificaExistenciaDados(val.quantis);
				},
				error: err => {
					this.tableData = null;
					console.error(err);
					this.toastr.error('Erro ao obter informações do posto selecionado');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getTableDataFormatada(
		originalData: QuantisResponse<QuantisPrecipitacao[]>
	): Relatorios<QuantisPrecipitacao> {
		return {
			data: originalData.quantis,
			municipio: originalData.municipio!,
			posto: originalData.posto!,
			ano: originalData.ano.toString(),
		};
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);
		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`,
			alignment: 'center',
			margin: [0, 0, 0, 15],
		});

		const datas = this.tableData?.data.map(d => d.data) || [];
		const totalColunas = datas.length + 2; // 2 colunas fixas: 'Município/Posto' e 'Tipo'
		const colunasFixas = ['12%', '10%'];
		const usarColunasFixas = totalColunas > 10;

		const larguraDinamica = `${(
			(100 - (usarColunasFixas ? 22 : 0)) /
			(totalColunas - (usarColunasFixas ? colunasFixas.length : 0))
		).toFixed(2)}%`;

		const widths = usarColunasFixas
			? [...colunasFixas, ...Array(datas.length).fill(larguraDinamica)]
			: Array(totalColunas).fill(larguraDinamica);

		documentDefinition.content.push({
			table: {
				widths: widths,
				body: this.getDadosTabelaParaExportacao(true),
			},
			layout: {
				noWrap: false,
				fontSize: 5,
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(false);
		DocumentExporter.gerarCSV(
			tableData,
			`${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(false);
		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];
			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});
				txtData += '\n';
			});
		}
		DocumentExporter.gerarTXT(
			txtData,
			`${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `${this.quantisType} - ${this.tableData?.municipio} - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};

		const headers = this.getHeadersXLSX();
		const datas = this.tableData?.data.map(d => d.data) || [];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data?.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarQuantisParaXLSX(),
					columns: [
						{ key: 'municipio_posto' },
						{ key: 'tipo' },
						...datas.map(data => ({
							key: data,
							style: { numFmt: '0.0' },
						})),
					],
					title: {
						consommeRow: 3,
						consommeCol: datas.length + 2,
						text: nomeArquivo,
						styleId: 'title',
						alignment: 'center',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	retornarQuantisParaXLSX() {
		const tableData: any[] = [];
		const datas = this.tableData?.data.map(d => d.data) || [];
		const tipos = Array.from(CondicaoMap.keys());

		tipos.forEach(tipo => {
			const linha = {
				municipio_posto: this.municipioNome,
				tipo: CondicaoMap.get(tipo),
				...datas.reduce(
					(acc: any, data: any) => {
						const valor = this.tableData?.data.find(d => d.data === data)
							?.valores[tipo as keyof QuantisValores];

						acc[data] = isNotNuloOuUndefined(valor)
							? this.formatNumber(valor, tipo === 'observado')
							: '-';
						return acc;
					},
					{} as Record<string, string>
				),
			};
			tableData.push(linha);
		});
		return tableData;
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];
		const datas = this.tableData?.data.map(d => d.data) || [];
		const tipos = Array.from(CondicaoMap.keys());

		const colunas = [
			{ text: 'Município/Posto', fillColor: '#DCDCDC' },
			{ text: 'Tipo', fillColor: '#DCDCDC' },
			...datas.map(data => ({
				text: `${this.isAnual ? data : Meses[data! - 1]} (mm)`,
				fillColor: '#DCDCDC',
			})),
		];

		verificaSePDF(tableData, colunas, isPdf);

		tipos.forEach(tipo => {
			const linha = [
				this.municipioNome,
				CondicaoMap.get(tipo),
				...datas.map(data => {
					const valor = this.tableData?.data.find(d => d.data === data)
						?.valores[tipo as keyof QuantisValores];

					return isNotNuloOuUndefined(valor)
						? this.formatNumber(valor, tipo === 'observado')
						: '-';
				}),
			];
			tableData.push(linha);
		});

		return tableData;
	}

	private getHeadersXLSX(): Array<{
		label: string;
		text: string;
		size: number;
	}> {
		const datas = this.tableData?.data.map(d => d.data) || [];

		const headers: Array<{ label: string; text: string; size: number }> = [
			{ label: 'municipio_posto', text: 'Município/Posto', size: 24 },
			{ label: 'tipo', text: 'Tipo', size: 24 },
			...datas.map(data => ({
				label: `${data}`,
				text: `${this.isAnual ? data : Meses[data! - 1]} (mm)`,
				size: 12,
			})),
		];

		return headers;
	}
	private formatNumber(value: number, ehObservado = false): string | number {
		let retorno = '';
		if (ehObservado) {
			const valorFormatado = numberToBrNumber(value, 1);
			return valorFormatado ?? '-';
		}
		retorno = isNotNuloOuUndefined(value)
			? '≤ ' + numberToBrNumber(value, 1)
			: '-';
		return retorno === '≤ 0,0' ? '0,0' : retorno;
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)?.value;
	}
}
