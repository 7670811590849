export enum CamadasGeojson {
	BACIAS = 'assets/geoJson/pb-bacias-geo.json',
	SUBBACIAS = 'assets/geoJson/pb-subBacias-geo.json',
	REGIOES = 'assets/geoJson/pb-regioes-dos-rios-geo.json',
	BACIAS_SUBBACIAS = 'assets/geoJson/pb-subBacias-bacia-geo.json',
	BACIAS_SUBBACIAS_REGIOES = 'assets/geoJson/pb-bacias-subbacias-regioes-rios-geo.json',
	BACIAS_REGIOES = 'assets/geoJson/pb-regioes-dos-rios-bacias-geo.json',
	SUBBACIAS_REGIOES = 'assets/geoJson/pb-regioes-e-subbacia-geo.json',
}

export const camadasGeojsonMap: { [key: string]: CamadasGeojson } = {
	BACIA: CamadasGeojson.BACIAS,
	SUBBACIA: CamadasGeojson.SUBBACIAS,
	REGIAO_CURSO: CamadasGeojson.REGIOES,
	BACIA_SUBBACIA: CamadasGeojson.BACIAS_SUBBACIAS,
	BACIA_SUBBACIA_REGIAO: CamadasGeojson.BACIAS_SUBBACIAS_REGIOES,
	BACIA_REGIAO: CamadasGeojson.BACIAS_REGIOES,
	SUBBACIA_REGIAO: CamadasGeojson.SUBBACIAS_REGIOES,
};
