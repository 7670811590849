import { HttpClient } from '@angular/common/http';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FormBuilder, FormControl } from '@angular/forms';

import * as Leaflet from 'leaflet';

import { AgrupamentoResponse } from '../../interfaces/agrupamento';
@Component({
	selector: 'seira-menu-mapa-pluviometria-observavada',

	templateUrl: './menu-mapa-pluviometria-observavada.component.html',

	styleUrls: ['./menu-mapa-pluviometria-observavada.component.scss'],
})
export class MenuMapaPluviometriaObservavadaComponent implements OnInit {
	map!: Leaflet.Map;
	geoJsonBacias!: Leaflet.GeoJSON;
	geoJsonSubBacias!: Leaflet.GeoJSON;
	geoJsonRegiaoPluviometrica!: Leaflet.GeoJSON;
	geoJsonBaciasSubBaciasRegioes!: Leaflet.GeoJSON;
	geoJsonBaciasSubbacias!: Leaflet.GeoJSON;
	geoJsonSubBaciasRegioes!: Leaflet.GeoJSON;
	geoJsonBaciasRegioes!: Leaflet.GeoJSON;
	geoJsonLayer?: Leaflet.GeoJSON;
	agrupamentoGeoJson: string;
	agrupamentoResult: AgrupamentoResponse[] = [];
	@Output() gerarRelatorio = new EventEmitter();

	layersCamada = new EventEmitter<Leaflet.GeoJSON[]>();

	markersLayer: Leaflet.LayerGroup | any;
	dadosCarregados = false;

	formCamadas = this.fb.group({
		bacias: new FormControl(false),

		subBacias: new FormControl(false),

		regioes: new FormControl(false),
	});

	constructor(
		private fb: FormBuilder,

		private httpClient: HttpClient
	) {
		this.markersLayer = Leaflet.layerGroup();
	}

	ngOnInit(): void {
		this.observersChanges();
		this.setDefaultValues();
	}
	observersChanges() {
		let previousValues = this.formCamadas.value;

		this.formCamadas.valueChanges.subscribe({
			next: currentValues => {
				const { bacias, subBacias, regioes } = currentValues;

				if (
					previousValues.bacias !== bacias ||
					previousValues.subBacias !== subBacias ||
					previousValues.regioes !== regioes
				) {
					previousValues = currentValues;

					this.handleAddToMap(this.geoJsonBacias, false);
					this.handleAddToMap(this.geoJsonSubBacias, false);
					this.handleAddToMap(this.geoJsonRegiaoPluviometrica, false);
					this.handleAddToMap(this.geoJsonBaciasSubbacias, false);
					this.handleAddToMap(this.geoJsonSubBaciasRegioes, false);
					this.handleAddToMap(this.geoJsonBaciasSubBaciasRegioes, false);
					this.handleAddToMap(this.geoJsonBaciasRegioes, false);

					if (bacias && subBacias && regioes) {
						this.agrupamentoGeoJson = 'BACIA_SUBBACIA_REGIAO';
					} else if (bacias && subBacias) {
						this.agrupamentoGeoJson = 'BACIA_SUBBACIA';
					} else if (bacias && regioes) {
						this.agrupamentoGeoJson = 'BACIA_REGIAO';
					} else if (subBacias && regioes) {
						this.agrupamentoGeoJson = 'SUBBACIA_REGIAO';
					} else if (bacias) {
						this.agrupamentoGeoJson = 'BACIA';
					} else if (subBacias) {
						this.agrupamentoGeoJson = 'SUBBACIA';
					} else if (regioes) {
						this.agrupamentoGeoJson = 'REGIAO_CURSO';
					} else {
						this.agrupamentoGeoJson = 'BACIA_SUBBACIA';
					}

					this.gerarRelatorio.emit(this.agrupamentoGeoJson);
					this.dadosCarregados = true;
				}
			},
		});
	}

	handleAddToMap(
		item: Leaflet.GeoJSON | Leaflet.LayerGroup,

		add?: boolean | null
	) {
		if (item)
			if (add) {
				item.addTo(this.map);
			} else {
				item.remove();
			}
	}

	setDefaultValues() {
		setTimeout(() => {
			this.formCamadas.patchValue({
				bacias: true,
				subBacias: true,
				regioes: false,
			});
		}, 5);
	}
}
