<div class="d-flex flex-column mt-2 align-items-center">
	<label class="text-tertiary fw-semibold mt-3">
		{{ tituloQuantis }}
	</label>
	<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

	<div
		*ngIf="loading; else mapa"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #mapa>
		<div
			id="mapa-legenda"
			class="d-flex flex-column align-items-center"
			style="max-width: 1300px">
			<div id="map-container" style="position: relative" class="w-100">
				<seira-mapa-paraiba-svg
					[removerEspacosEmBranco]="true"
					tipoMapa="precipitacao"
					id="mapa_quantis_anual"
					[loading]="loading"
					[atributosMunicipio]="chuvaPorMunicipio"
					[microrregiao]="microrregiaoNome"
					(legendaItems)="handleLegendaItems($event)"
					[popoverTemplate]="popupPrecipitacao"></seira-mapa-paraiba-svg>
			</div>
			<seira-legenda-relatorio
				[titulo]="getTituloLegenda()!"
				[isDesktop]="isDesktop"
				[legenda]="legendaItems"
				[tipo]="getTituloLegenda()"
				[exibirBotaoExportar]="false"
				[periodo]="tituloQuantis"></seira-legenda-relatorio>
			<p
				style="
					color: #666666;
					text-align: justify;
					font-size: 13px;
					line-height: 1rem;
				">
				{{ legenda }}
			</p>
		</div>
	</ng-template>
</div>

<ng-template
	#popupPrecipitacao
	let-color="color"
	let-geocodigo="geocodigo"
	let-nome="nome"
	let-classificacao="classificacao">
	<seira-popup-boletim-diario-mapa
		[precipitacaoAcumulada]="precipitacaoAcumuladaPorGeocodigo(geocodigo)"
		[informacaoExtra]="classificacao"
		[headerColor]="color"
		[nome]="nome">
	</seira-popup-boletim-diario-mapa>
</ng-template>
