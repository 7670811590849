import { HttpClient } from '@angular/common/http';
import {
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	createComponent,
	EnvironmentInjector,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	ViewContainerRef,
} from '@angular/core';
import { CORES, legendaNDCInfo } from '@componentes/mapa-paraiba-svg/legenda';
import Leaflet, { LayerGroup } from 'leaflet';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { PopupNdcComponent } from '../../popup-ndc/popup-ndc.component';
import { ColorUtils } from '@utils/color-utils';
import {
	AgrupamentoGeojson,
	agrupamentoGeojsonMap,
} from '../../../enum/Agrupamento';
import {
	AgrupamentoResponse,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '../../../interfaces';
import { FormBuilder } from '@angular/forms';
import {} from '@componentes/mapa-paraiba-leaflet/utils';
import { CamadasGeojson, camadasGeojsonMap } from '../../../enum/Camadas';
import { MenuMapaPluviometriaObservavadaComponent } from '../../menu-mapa-pluviometria-observavada/menu-mapa-pluviometria-observavada.component';
import { Feature, Geometry } from 'geojson';

@Component({
	selector: 'seira-mapa-ndc-chuva',
	templateUrl: './mapa-ndc.component.html',
	styleUrls: ['./mapa-ndc.component.scss'],
})
export class MapaNdcComponent implements OnDestroy, OnChanges {
	markersLayer: LayerGroup | any;
	@Input() dados?: RelatorioNDCResponse[];
	map!: Leaflet.Map;
	geoJsonLayer?: Leaflet.GeoJSON;
	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;
	layersCamada = new EventEmitter<Leaflet.GeoJSON[]>();
	legenda = legendaNDCInfo;
	menuLateral = new Leaflet.Control({ position: 'bottomright' });

	private inputs = inject(INPUTS_RELATORIOS);
	@Input() isLoading = false;
	MenuMapa?: ComponentRef<MenuMapaPluviometriaObservavadaComponent>;
	agrupamentoResult: AgrupamentoResponse[] = [];
	constructor(
		private fb: FormBuilder,
		private httpClient: HttpClient,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private envInjector: EnvironmentInjector,
		private viewContainerRef: ViewContainerRef
	) {}
	ngOnDestroy(): void {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}
	}

	ngOnChanges(): void {
		this.cdr.detectChanges();
		if (!this.isLoading) {
			this.limparMapa();
			if (this.isBacia) {
				this.getMenu();
			} else {
				if (this.MenuMapa) {
					this.MenuMapa.destroy();
					this.MenuMapa = undefined;
				}
				setTimeout(() => {
					this.loadMapLayers(this.map!, false);
				}, 500);
			}
		}
	}

	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
	}
	get agrupamento(): string {
		return this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
	}
	get isBacia(): boolean {
		return (
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value ===
			'BACIA_SUBBACIA'
		);
	}
	get agrupamentoGeoJSON(): AgrupamentoGeojson {
		return (
			agrupamentoGeojsonMap[this.agrupamento] ?? AgrupamentoGeojson.MUNICIPIO
		);
	}
	getCamadaGeoJson(camada: string): CamadasGeojson {
		return camadasGeojsonMap[camada] ?? CamadasGeojson.BACIAS;
	}
	private limparMapa(): void {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}
	}
	getPropertyNomeGeoson(feature: {
		properties: {
			name: string;
			NM_MESO: string;
			Nome: string;
			nome: string;
			Regiao: string;
		};
	}) {
		return (
			feature.properties.name ||
			feature.properties.NM_MESO ||
			feature.properties.Nome ||
			feature.properties.nome ||
			feature.properties.Regiao
		);
	}
	loadMapLayers(map: Leaflet.Map, deveLimparMapa = true, camada?: string) {
		if (deveLimparMapa) {
			this.limparMapa();
		}
		if (this.isBacia) {
			const geojson = this.getCamadaGeoJson(camada!);
			this.httpClient.get<GeoJSON.GeoJsonObject>(geojson).subscribe({
				next: value => {
					this.geoJsonLayer = Leaflet.geoJSON(value, {
						style: feature => this.handleGetStyleByFeature(feature, camada),
						onEachFeature: (feature, layer) => {
							layer.on({
								click: e => {
									const nome =
										feature.properties[
											this.chavePropriedadeFeatureGeoJSON(camada, feature)
										] ?? this.getPropertyNomeGeoson(feature);
									const ndc = this.handleFindNDCByPropertyName(nome);
									const markerPopup = this.handleAttachPopup(
										ndc
											? this.handleCreatePopupByNDC(
													ndc,

													nome
											  )
											: this.handleCreatePopup(nome)
									);
									layer.bindPopup(markerPopup).openPopup(e.latlng);
								},
							});
						},
					}).addTo(map);
				},
			});
		} else {
			this.httpClient
				.get<GeoJSON.GeoJsonObject>(this.agrupamentoGeoJSON)
				.subscribe({
					next: value => {
						this.geoJsonLayer = Leaflet.geoJSON(value, {
							style: feature => this.handleGetStyleByFeature(feature),
							onEachFeature: (feature, layer) => {
								layer.on({
									click: e => {
										const nome =
											feature.properties[
												this.chavePropriedadeFeatureGeoJSON(camada, feature)
											] ?? this.getPropertyNomeGeoson(feature);
										const ndc = this.handleFindNDCByPropertyName(nome);
										const markerPopup = this.handleAttachPopup(
											ndc
												? this.handleCreatePopupByNDC(
														ndc,

														nome
												  )
												: this.handleCreatePopup(nome)
										);
										layer.bindPopup(markerPopup).openPopup(e.latlng);
									},
								});
							},
						}).addTo(map);
					},
				});
		}
	}

	private handleCreatePopup(titulo: string): ComponentRef<PopupNdcComponent> {
		const componentRef = createComponent(PopupNdcComponent, {
			environmentInjector: this.envInjector,
		});
		const color = CORES.CINZA;
		componentRef.instance.nome = titulo;
		componentRef.instance.headerColor = color;
		componentRef.instance.agrupamento = this.agrupamento;
		componentRef.instance.titleColor = ColorUtils.ehCorClara(color)
			? 'gray'
			: CORES.BRANCO_NEVE;
		return componentRef;
	}

	private handleCreatePopupByNDC(
		ndc: RelatorioNDCResponse,
		titulo: string
	): ComponentRef<PopupNdcComponent> {
		const componentRef = createComponent(PopupNdcComponent, {
			environmentInjector: this.envInjector,
		});
		componentRef.instance.ndc = ndc;
		componentRef.instance.nome = titulo;
		componentRef.instance.agrupamento = this.agrupamento;
		const color = this.getColorByDiasChuva(ndc.diasComChuva);
		componentRef.instance.headerColor = color;

		componentRef.instance.titleColor = ColorUtils.ehCorClara(color)
			? 'gray'
			: CORES.BRANCO_NEVE;
		return componentRef;
	}

	private handleAttachPopup(compRef: ComponentRef<PopupNdcComponent>) {
		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		const div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}

	private handleGetStyleByFeature(feature?: any, camada?: string) {
		const nome =
			feature.properties[
				this.chavePropriedadeFeatureGeoJSON(camada, feature)
			] ?? this.getPropertyNomeGeoson(feature);
		const ndc = this.handleFindNDCByPropertyName(nome);
		return {
			fillColor: ndc ? this.getColorByDiasChuva(ndc.diasComChuva) : CORES.CINZA,
			weight: 2,
			opacity: 1,
			color: '#607d8b',
			fillOpacity: 0.7,
		};
	}
	private chavePropriedadeFeatureGeoJSON(
		camada?: string,
		feature?: Feature<Geometry, any>
	): string {
		switch (this.agrupamento) {
			case 'MUNICIPIO_POSTO':
			case 'MUNICIPIO':
			case 'REGIAO_PLUVIOMETRICA':
				return 'name';
			case 'MICRORREGIAO':
				return 'nome';
			case 'MESORREGIAO':
				return 'NM_MESO';
			case 'BACIA_SUBBACIA':
				if (camada === 'REGIAO_CURSO') {
					return 'Regiao';
				} else if (
					camada === 'BACIA_SUBBACIA_REGIAO' ||
					camada === 'BACIA_SUBBACIA'
				) {
					const bacia = this.agrupamentoResult.find(
						(d: AgrupamentoResponse) => d.nome === feature!.properties['name']
					);
					const subbacia = this.agrupamentoResult.find(
						(d: AgrupamentoResponse) => d.nome === feature!.properties['Nome']
					);
					const regiao = this.agrupamentoResult.find(
						(d: AgrupamentoResponse) => d.nome === feature!.properties['Regiao']
					);
					if (bacia! > subbacia! && bacia! > regiao!) {
						return 'name';
					} else if (subbacia! > bacia! && subbacia! > regiao!) {
						return 'Nome';
					} else if (regiao! > bacia! && regiao! > subbacia!) {
						return 'Regiao';
					}
				}
				return 'Nome';

			default:
				return 'Nome';
		}
	}

	private getchavePropriedadeFeature(): keyof RelatorioNDCResponse {
		if (this.agrupamento === 'MUNICIPIO_POSTO') {
			return 'municipio';
		}
		return 'nome';
	}

	private handleFindNDCByPropertyName(name: string) {
		return this.dados?.find(
			dado => dado[this.getchavePropriedadeFeature()] === name
		);
	}

	private getColorByDiasChuva(valor: number): string {
		const arr = Object.keys(legendaNDCInfo)
			.map(Number)
			.sort((a, b) => b - a);

		for (const key of arr) {
			if (valor >= key) {
				return legendaNDCInfo[key];
			}
		}
		return CORES.CINZA;
	}
	getMenu() {
		if (this.dados?.length === 0) {
			return;
		}
		if (this.MenuMapa && !this.isBacia) {
			this.MenuMapa.destroy();
			this.MenuMapa = undefined;
		} else {
			this.menuLateral.onAdd = (map: Leaflet.Map) => {
				this.MenuMapa = this.viewContainerRef.createComponent(
					MenuMapaPluviometriaObservavadaComponent
				);
				this.MenuMapa.instance.map = map;
				this.MenuMapa.changeDetectorRef.detectChanges();
				this.MenuMapa.instance.gerarRelatorio.subscribe({
					next: (value: string) => {
						this.loadMapLayers(map, true, value);
					},
				});
				return this.MenuMapa.location.nativeElement;
			};
			this.menuLateral.addTo(this.map);
		}
	}
}
