export interface RespostaGraficoVariaveis {
	conteudoAguaSolo10cm: number[];
	conteudoAguaSolo20cm: number[];
	conteudoAguaSolo30cm: number[];
	conteudoAguaSolo40cm: number[];
	conteudoAguaSolo50cm: number[];
	conteudoAguaSolo60cm: number[];
	correnteRecarga: number[];
	data: number[];
	evapotranspiracaoPotencial: number[];
	precipitacao: number[];
	pressao: number[];
	pressaoAtmosferica: number[];
	radiacao: number[];
	sensorPortaAberta: number[];
	temperaturaAr: number[];
	temperaturaInterna: number[];
	temperaturaSolo: number[];
	temperaturaSolo10cm: number[];
	temperaturaSolo20cm: number[];
	temperaturaSolo30cm: number[];
	temperaturaSolo40cm: number[];
	temperaturaSolo50cm: number[];
	temperaturaSolo60cm: number[];
	tensaoBateria: number[];
	umidadeInstantanea: number[];
	umidadeInterna: number[];
	umidadeSolo: number[];
	vento2m: number[];
	ventoDirecao: number[];
	ventoDirecao1DesvioPadrao: number[];
	ventoDirecao1Maxima: number[];
	ventoDirecao2: number[];
	ventoDirecao2DesvioPadrao: number[];
	ventoDirecao2Maxima: number[];
	ventoVelocidade: number[];
	ventoVelocidade1Maxima: number[];
	ventoVelocidade2Maxima: number[];
}
export class ConfiguracaoGrafico {
	constructor(
		readonly render: string,
		readonly titulo: string,
		readonly subTitulo: string,
		readonly descritivoDados: string,
		readonly configuracoesExtras: any
	) {}
}

export enum Variavel {
	PRECIPITACAO = 'Precipitação',
	PRESSAO_ATMOSFERICA = 'Pressão atmosférica',
	TEMPERATURA_DO_AR = 'Temperatura do ar',
	TEMPERATURA_DO_SOLO = 'Temperatura do solo',
	UMIDADE = 'Umidade',
	RADIACAO_SOLAR = 'Radiação solar',
	TEMPERATURA_SOLO_10CM = 'Temperatura do solo a 10 cm',
	TEMPERATURA_SOLO_20CM = 'Temperatura do solo a 20 cm',
	TEMPERATURA_SOLO_30CM = 'Temperatura do solo a 30 cm',
	TEMPERATURA_SOLO_40CM = 'Temperatura do solo a 40 cm',
	TEMPERATURA_SOLO_50CM = 'Temperatura do solo a 50 cm',
	TEMPERATURA_SOLO_60CM = 'Temperatura do solo a 60 cm',
	CONTEUDO_AGUA_SOLO_10CM = 'Conteúdo de água no solo 10cm',
	CONTEUDO_AGUA_SOLO_20CM = 'Conteúdo de água no solo 20cm',
	CONTEUDO_AGUA_SOLO_30CM = 'Conteúdo de água no solo 30cm',
	CONTEUDO_AGUA_SOLO_40CM = 'Conteúdo de água no solo 40cm',
	CONTEUDO_AGUA_SOLO_50CM = 'Conteúdo de água no solo 50cm',
	CONTEUDO_AGUA_SOLO_60CM = 'Conteúdo de água no solo 60cm',
	UMIDADE_INTERNA = 'Umidade interna',
	UMIDADE_SOLO = 'Umidade do Solo',
	TEMPERATURA_INTERNA = 'Temperatura interna',
	VENTO = 'Vento',
	VENTO_2M = 'Vento 2m',
	// VENTO_1_MAXIMA = 'Vento 1 máxima', //TODO: Descomentar quando a variável for sincronizada no backend
	// VENTO_2_MAXIMA = 'Vento 2 máxima', //TODO: Descomentar quando a variável for sincronizada no backend
	VENTO_DIRECAO_1_DESVIO_PADRAO = 'Vento direção 1 desvio Padrão ',
	VENTO_DIRECAO_2_DESVIO_PADRAO = 'Vento direção 2 desvio Padrão',
}

export enum VariavelUnidade {
	PRECIPITACAO = '(mm)',
	PRESSAO_ATMOSFERICA = '(hPa)',
	TEMPERATURA_DO_AR = '(°C)',
	TEMPERATURA_DO_SOLO = '(°C)',
	UMIDADE = '(%)',
	RADIACAO_SOLAR = '(W/m²)',
	TEMPERATURA_SOLO_10CM = '(°C)',
	TEMPERATURA_SOLO_20CM = '(°C)',
	TEMPERATURA_SOLO_30CM = '(°C)',
	TEMPERATURA_SOLO_40CM = '(°C)',
	TEMPERATURA_SOLO_50CM = '(°C)',
	TEMPERATURA_SOLO_60CM = '(°C)',
	CONTEUDO_AGUA_SOLO_10CM = '(%)',
	CONTEUDO_AGUA_SOLO_20CM = '(%)',
	CONTEUDO_AGUA_SOLO_30CM = '(%)',
	CONTEUDO_AGUA_SOLO_40CM = '(%)',
	CONTEUDO_AGUA_SOLO_50CM = '(%)',
	CONTEUDO_AGUA_SOLO_60CM = '(%)',
	UMIDADE_INTERNA = '(%)',
	UMIDADE_SOLO = '(%)',
	TEMPERATURA_INTERNA = '(°C)',
	VENTO_DIRECAO = '(°)',
	VENTO = '(m/s)',
	VENTO_2M = '(m/s)',
	VENTO_1_MAXIMA = '(m/s)',
	VENTO_2_MAXIMA = '(m/s)',
	VENTO_DIRECAO_1_DESVIO_PADRAO = '(m/s)',
	VENTO_DIRECAO_2_DESVIO_PADRAO = '(m/s)',
}
