import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { QuantisPrecipitacao, Relatorios } from '../../interfaces';
import {
	ADTColumns,
	ADTSettings,
} from 'angular-datatables/src/models/settings';
import { isNotNuloOuUndefined } from '@utils';
import { CondicaoMap } from '../../utils';
import { Meses } from '../../enum';

@Component({
	selector: 'seira-tabela-relatorios-quantis',
	templateUrl: './tabela-relatorios-quantis.component.html',
	styleUrls: ['./tabela-relatorios-quantis.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TabelaRelatoriosQuantisComponent implements OnInit {
	@Input() relatorios: Relatorios<QuantisPrecipitacao> | null;
	@Input() isAnual = false;
	dtOptions: ADTSettings = {};
	dados: any;

	colunas: ADTColumns[] = [];

	ngOnInit(): void {
		this.dados = this.gerarDados();
		this.colunas = this.gerarColunas(this.isAnual);
	}

	private gerarDados() {
		const dados: any[] = [];
		const tipos = Array.from(CondicaoMap.keys());

		const dadosPorTipo = tipos.reduce(
			(acc, tipo) => {
				acc[tipo] = { tipo };
				return acc;
			},
			{} as Record<string, any>
		);

		this.relatorios?.data.forEach(d => {
			if (d?.data) {
				dadosPorTipo['muitoSeco'][d.data] = d.valores.muitoSeco;
				dadosPorTipo['seco'][d.data] = d.valores.seco;
				dadosPorTipo['normal'][d.data] = d.valores.normal;
				dadosPorTipo['chuvoso'][d.data] = d.valores.chuvoso;
				dadosPorTipo['muitoChuvoso'][d.data] = d.valores.muitoChuvoso;
				dadosPorTipo['observado'][
					d.data
				] = `flagObservado${d.valores.observado}`;
			}
		});

		Object.values(dadosPorTipo).forEach(dado => dados.push(dado));

		return dados;
	}

	private gerarColunas(isAnual: boolean): ADTColumns[] {
		const colunas: ADTColumns[] = [
			{
				data: 'tipo',
				title: 'Tipo',
				type: 'string',
				className: 'text-center',
				render: (condicao: string) => CondicaoMap.get(condicao),
			},
		];

		const datas = this.relatorios?.data.map(d => d.data) || [];

		const outrasColunas = datas.map(data => ({
			data: `${data}`,
			title: `${isAnual ? data : Meses[data! - 1]} (mm)`,
			type: 'number',
			className: 'text-center',
			orderable: false,
			render: (precipitacao: string | number | null) =>
				isNotNuloOuUndefined(precipitacao)
					? this.handleFormatNumber(precipitacao)
					: '-',
		}));

		return [...colunas, ...outrasColunas];
	}

	private handleFormatNumber(value: string | number | null): string {
		if (typeof value === 'string' && value.startsWith('flagObservado')) {
			const str = value.replace('flagObservado', '');
			if (str === 'null') {
				return '-';
			}
			return Number(str).toFixed(1).replace('.', ',');
		}
		const retorno = '≤ ' + Number(value).toFixed(1).replace('.', ',');
		return retorno === '≤ 0,0' ? '0,0' : retorno;
	}
}
