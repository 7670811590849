export enum PluviometriaObservadaCores {
	VALOR_MINIMO = '#F93537',
	VALOR_EXTREMAMENTE_BAIXO = '#FE5F35',
	VALOR_MUITO_BAIXO = '#FCA973',
	VALOR_BAIXO = '#FACC9B',
	VALOR_MEDIO_BAIXO = '#FDFD99',
	VALOR_MEDIO = '#9AFD02',
	VALOR_MEDIO_ALTO = '#73D973',
	VALOR_ALTO = '#32CD31',
	VALOR_MUITO_ALTO = '#009B36',
	VALOR_EXTREMAMENTE_ALTO = '#0097FE',
	VALOR_QUASE_MAXIMO = '#007FFF',
	VALOR_MAXIMO = '#0065FF',
}

export enum DesvioCores {
	VALOR_MINIMO = '#FD1A13',
	VALOR_EXTREMAMENTE_BAIXO = '#FE6442',
	VALOR_MUITO_BAIXO = '#FE7E10',
	VALOR_BAIXO = '#FFC72A',
	VALOR_MEDIO_BAIXO = '#FFFC9D',
	VALOR_MEDIO_ALTO = '#CBCDFD',
	VALOR_ALTO = '#959EFB',
	VALOR_MUITO_ALTO = '#6073FE',
	VALOR_EXTREMAMENTE_ALTO = '#003A96',
	VALOR_MAXIMO = '#310F65',
}

export enum NDCCores {
	VALOR_MINIMO = '#F93537',
	VALOR_QUASE_MINIMO = '#FE5F35',
	VALOR_EXTREMAMENTE_BAIXO = '#FCA973',
	VALOR_MUITO_BAIXO = '#FACC9B',
	VALOR_BAIXO = '#FDFD99',
	VALOR_MEDIO_BAIXO = '#9AFD02',
	VALOR_MEDIO = '#73D973',
	VALOR_MEDIO_ALTO = '#32CD31',
	VALOR_ALTO = '#1EAA33',
	VALOR_MUITO_ALTO = '#008A25',
	VALOR_EXTREMAMENTE_ALTO = '#0097FE',
	VALOR_QUASE_MAXIMO = '#007FFF',
	VALOR_MAXIMO = '#0065FF',
}
