import { AfterViewInit, Component, Input } from '@angular/core';
import {
	EstacaoMonitorada,
	ResponseHistoricoEstacao,
	variaveis,
	VariavelValue,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { numberToBrNumber } from '@utils';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as Highcharts from 'highcharts/highstock';
import { EstacoesService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/services/estacoes.service';
import { Router } from '@angular/router';
import { options } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-historico-estacao/chart-options';
import { getChartSeries } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-historico-estacao/chart-utils';
import { Select } from '@layout/interfaces/select';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

@Component({
	selector: 'seira-modal-historico-estacao',
	templateUrl: './modal-historico-estacao.component.html',
	styleUrls: ['./modal-historico-estacao.component.scss'],
})
export class ModalHistoricoEstacaoComponent implements AfterViewInit {
	@Input() close!: () => void;
	@Input() estacao!: EstacaoMonitorada;

	chartRef!: Highcharts.Chart;
	variaveis: Select[] = [];
	form!: FormGroup;
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options = options;
	loadingChart = true;

	constructor(
		private formBuilder: FormBuilder,
		private estacoesService: EstacoesService,
		private router: Router
	) {
		this.variaveis = variaveis.map(variavel => ({
			value: variavel.label,
			name: variavel.label,
		}));
		const hoje = new Date();
		this.form = this.formBuilder.group({
			dataInicio: new FormControl<Date>(hoje),
			dataFim: new FormControl<Date>(hoje),
			variavel: new FormControl(this.variaveis[0].value),
		});
	}

	ngAfterViewInit() {
		this.form.valueChanges.subscribe({
			next: value => {
				if (value.dataInicio && value.dataFim) {
					this.updateChartView();
				}
			},
		});
		if (this.estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL') {
			this.variaveis = this.variaveis.filter(
				variavel => variavel.name === 'Precipitação'
			);
			this.form.patchValue({ variavel: 'Precipitação' });
		}
	}

	chartInstance(chart: Highcharts.Chart) {
		this.chartRef = chart;
		this.updateChartView();
	}

	irParaRelatorios() {
		let urlEstacao =
			this.tipoEstacao == TipoEstacao.PCD
				? `/dados-meteorologicos/monitoramento/estacoes-automaticas`
				: `/dados-meteorologicos/monitoramento/pluviometria`;

		const url = this.router.serializeUrl(
			this.router.createUrlTree([urlEstacao])
		);

		window.open(url, '_blank');
	}

	get tipoEstacao() {
		return TipoEstacao[this.estacao.tipoEstacao];
	}

	get status() {
		const dataAtual = new Date();
		dataAtual.setHours(0, 0, 0, 0);
		const dataUltimaColeta = new Date(this.estacao.ultimaColeta);
		dataUltimaColeta.setHours(0, 0, 0, 0);

		const dadosEnviadosNoDia =
			dataAtual.getTime() === dataUltimaColeta.getTime();
		let color;
		let label;

		if (dadosEnviadosNoDia) {
			color = 'text-estacao-status-1';
			label = 'Dados enviados no dia';
		} else {
			color = 'text-estacao-status-4';
			label = 'Dados não recebidos';
		}
		return { color, label };
	}

	updateChartView() {
		this.loadingChart = true;
		const variavel = variaveis.find(
			variavel => variavel.label === this.form.get('variavel')?.value
		);

		if (variavel) {
			const { dataFim, dataInicio } = this.form.getRawValue();

			if (dataFim && dataInicio) {
				const dataInicioFormatada = new Date(dataInicio);
				dataInicioFormatada.setHours(0, 0, 0, 0);
				const dataFimFormatada = new Date(dataFim);
				dataFimFormatada.setHours(23, 59, 59, 999);
				this.estacoesService
					.historicoEstacao(
						this.estacao.id,
						dataInicioFormatada,
						dataFimFormatada,
						variavel.label
					)
					.subscribe({
						next: historicoEstacao => {
							this.setDadosGrafico(historicoEstacao, variavel);
						},
						error: () => {
							this.clearChart();
						},
					});
			}
		} else {
			this.loadingChart = false;
		}
	}

	tooltip(variavel: VariavelValue): Highcharts.Options['tooltip'] {
		const resultRegexTermination = /\(([^)]+)\)/.exec(variavel.value.textY);
		let termitation = '';
		if (resultRegexTermination && resultRegexTermination[1]) {
			termitation = resultRegexTermination[1];
		}
		const context = this;
		return {
			...this.chartRef.tooltip,
			formatter: function () {
				let coleta: Date | string = '';
				if (typeof this.point.x === 'number') {
					const date = new Date(this.point.x);
					coleta = date.toLocaleDateString('pt-BR', {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour:
							variavel.label !== 'Precipitação acumulada'
								? 'numeric'
								: undefined,
						minute:
							variavel.label !== 'Precipitação acumulada'
								? 'numeric'
								: undefined,
					});
				}
				const tooltips: string[] = [];
				const points: Record<
					string,
					Highcharts.TooltipFormatterContextObject[]
				> = {};

				this.points?.forEach(point => {
					if (['Direção', 'Velocidade'].includes(point.series.name)) {
						if (points['Vento']) {
							points['Vento'].push(point);
						} else {
							points['Vento'] = [point];
						}
					} else {
						points[point.series.name] = [point];
					}
				});

				Object.values(points).forEach(points => {
					tooltips.push(context.makeTooltip(points));
					if (points.length > 1) {
						for (let i = 0; i < points.length - 1; i++) {
							tooltips.push('');
						}
					}
				});

				return ['', ...tooltips];
			},
		};
	}

	makeTooltip(points: Highcharts.TooltipFormatterContextObject[]) {
		const classContext = this;
		const yAxisTitle = points[0].series.yAxis.options.title?.text ?? '';
		const unit = /\((.*?)\)/.exec(yAxisTitle)?.[1];
		const data = classContext.formatarHorario(
			Number(points[0].x),
			"EEEE, d 'de' MMMM, HH:mm"
		);
		return (
			`<br/>Coleta: ${data}` +
			points
				?.map(point => {
					const direction = (point.point as any).direction;

					return ` ${
						direction
							? `<br/>${
									point.series.name
							  }: ${classContext.getWindDirectionLabel(direction)}`
							: `<br/>${point.series.name}: ${
									point.y ? numberToBrNumber(Number(point.y)) : point.y
							  } `
					}`;
				})
				.join('') +
			(unit ? unit : '')
		);
	}

	vetorValores(vetor: any[]) {
		return !vetor?.every((e: any) => e === null);
	}

	setDadosGrafico(
		historicoEstacao: ResponseHistoricoEstacao,
		variavel: VariavelValue
	) {
		const { dataFim, dataInicio } = this.form.getRawValue();
		if (historicoEstacao.data.length > 0 && dataInicio && dataFim) {
			const dataInicioFormatada = new Date(dataInicio);
			dataInicioFormatada.setHours(0, 0, 0, 0);
			const dataFimFormatada = new Date(dataFim);
			dataFimFormatada.setHours(23, 59, 59, 999);

			while (this.chartRef?.series?.length) {
				this.chartRef?.series[0].remove(false, false);
			}
			this.chartRef.yAxis[0].update({
				title: { text: variavel.value.textY },
			});
			const tooltipOptions = this.tooltip(variavel);
			if (tooltipOptions) this.chartRef.tooltip.update(tooltipOptions);

			const existeValores = this.vetorValores(
				historicoEstacao[variavel.value.valueField]
			);

			// Adiciona a série de dados ao gráfico
			let series = getChartSeries(
				historicoEstacao,
				variavel.value.valueField,
				variavel.label
			);

			if (variavel.value.valueField === 'precipitacaoAcumulada') {
				series = this.ajustaPrecipitacaoAcumulada(series);
			}
			if (existeValores) {
				series.map(serie => {
					return this.chartRef.addSeries(serie);
				});
				this.chartRef.redraw(false);
				this.chartRef.xAxis[0].setExtremes(
					dataInicioFormatada.getTime(),
					dataFimFormatada.getTime()
				);
			}
			this.loadingChart = false;
		} else {
			this.clearChart();
		}
	}

	ajustaPrecipitacaoAcumulada(series: any) {
		const resultados: any = [];

		series.forEach((item: any) => {
			item.data.forEach((item: any) => {
				const timestamp = item[0] - 3 * 60 * 60 * 1000;
				const date = new Date(timestamp);
				const dia = date.toISOString().slice(0, 10);

				const precipitacao = parseFloat(item[1].toFixed(2));

				let encontrado = false;
				for (let i = 0; i < resultados.length; i++) {
					const timestampResultado = resultados[i][0] - 3 * 60 * 60 * 1000;
					const dateResultado = new Date(timestampResultado);
					const diaResultado = dateResultado.toISOString().slice(0, 10);
					if (diaResultado === dia) {
						resultados[i][0] = item[0];
						resultados[i][1] += precipitacao;
						encontrado = true;
						break;
					}
				}

				if (!encontrado) {
					resultados.push([item[0], precipitacao]);
				}
			});
		});

		const dataPrecipitacaoAcumulada = resultados.map((item: any) => {
			const dataFormatada = new Date(item[0] - 3 * 60 * 60 * 1000);
			dataFormatada.setHours(0, 0, 0, 0);

			if (resultados.length === 1) {
				const meioDoDia = 12 * 60 * 60 * 1000;
				dataFormatada.setTime(dataFormatada.getTime() + meioDoDia);
			}

			const dataUtf = dataFormatada.getTime();
			return [dataUtf, parseFloat(item[1].toFixed(2))];
		});

		return [{ ...series[0], data: dataPrecipitacaoAcumulada }];
	}

	clearChart() {
		if (this.chartRef) {
			while (this.chartRef.series.length > 0) {
				this.chartRef.series[0].remove(false);
			}

			const defaultYAxisOptions: Highcharts.AxisOptions = {
				title: { text: '' },
			};

			this.chartRef.update({
				yAxis: [defaultYAxisOptions],
			});

			const defaultTooltipOptions: Highcharts.TooltipOptions = {
				formatter: function () {
					return '';
				},
			};

			this.chartRef.update({
				tooltip: defaultTooltipOptions,
			});

			this.chartRef.redraw(false);
		}

		this.loadingChart = false;
	}
	formatarHorario(
		horario: number | string | Date,
		formato = "dd 'de' MMMM 'de' yyyy - HH:mm OOOO"
	) {
		return format(new Date(horario), formato, {
			locale: ptBrLocale,
		});
	}
	getWindDirectionLabel(direction: number) {
		if (direction < 0) {
			direction = 360 + (direction % 360);
		}

		const directions = [
			'Norte',
			'Norte-nordeste',
			'Nordeste',
			'Leste-nordeste',
			'Leste',
			'Leste-sudeste',
			'Sudeste',
			'Sul-sudeste',
			'Sul',
			'Sul-sudoeste',
			'Sudoeste',
			'Oeste-sudoeste',
			'Oeste',
			'Oeste-noroeste',
			'Noroeste',
			'Norte-noroeste',
		];

		const index = Math.round(direction / 22.5) % 16;
		return directions[index];
	}

	numberToBrNumber(value: number | string | null, decimalPlaces: number) {
		return numberToBrNumber(value, decimalPlaces);
	}
}
