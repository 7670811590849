<div class="d-flex flex-column">
	<label class="text-tertiary w-100 text-center fw-semibold mt-3">
		Índice de precipitação padronizada (SPI) - {{ periodoTitle }}
	</label>

	<div
		class="d-flex my-3 flex-row justify-content-between align-items-end gap-2">
		<seira-card-info [texto]="descricaoRelatorio" />
	</div>
</div>

<div
	*ngIf="carregando; else tabela"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<ng-template #tabela>
	<div
		style="max-width: 1300px"
		*ngIf="resultados.length > 0; else semDados"
		class="mt-3">
		<seira-public-table
			#tabelaSpi
			id="tabela-SPI"
			[loading]="carregando"
			[searching]="false"
			[info]="false"
			[hasHeader]="false"
			[order]="[1, 'asc']"
			[scrollX]="true"
			[data]="resultados"
			[columns]="colunasSPI">
		</seira-public-table>
    <p class="referencia-estudo">
      {{ referenciaEstudo }}
    </p>
	</div>
</ng-template>

<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
