<label class="text-center text-tertiary fw-semibold w-100">
	Desvio {{ isDesvioPorcentagem ? '(%)' : '(mm)' }} -
	{{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

<div style="text-align: right">
	<span
		triggers="mouseenter:mouseleave"
		style="cursor: pointer"
		[popover]="dica"
		placement="bottom right"
		containerClass="custom-popover"
		container="body"
		class="text-primary"
		customClass="fw-light mx-2">
		<i class="ph-lightbulb-fill ms-1"></i>
		Dica
	</span>
</div>

<div
	*ngIf="loading; else grafico"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-template #grafico>
	<seira-highchart
		classChart="highcharts-light h-auto"
		styleChart="width: 100%; height: 600px; display: block"
		(chartInstance)="setGrafico($event)"
		[options]="opcoesGrafico" />
</ng-template>

<ng-template #dica>
	<div class="row p-2">
		<span class="col fw-semibold texto-dica">
			Clique nas opções da legenda para obter dados mais específicos para os
			agrupamentos:
			<strong>Mesorregião, Bacia, Região pluviométrica e Sub-bacia.</strong>
		</span>
	</div>
</ng-template>
