<div
	class="form-check d-flex align-items-center p-0"
	[ngClass]="{ 'gap-1': qtd !== undefined, 'gap-2': qtd === undefined }">
	<input
		class="form-check-input p-0 m-0"
		type="checkbox"
		[formControl]="control"
		[formControlName]="controlName"
		[id]="id" />
	<label class="form-check-label" [for]="id">
		<ng-container *ngIf="qtd !== undefined">
			<span class="qtd-reservatorio-label">
				{{ qtd }}
			</span>
			<img
				class="icone-reservatorio"
				[src]="iconURLByLabel"
				[alt]="'Ícone ' + label"
				width="22.5"
				height="22.5" />
		</ng-container>
		<span>
			{{ label }}
		</span>
	</label>
</div>
