import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
	Agrupamento,
	DadosAgrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	RelatorioVECResponse,
} from '../../../interfaces';
import { RelatoriosService } from '../../../services';
import { DateTimeUtils } from '@utils/datetime-util';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { corrigeDuplicacaoNome } from '@home/submodulos/dados-meteorologicos/utils';
import { numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-vec',
	templateUrl: './tabela-vec.component.html',
	styleUrls: ['./tabela-vec.component.scss'],
})
export class TabelaVecComponent implements OnDestroy, InstanciaRelatorio {
	@ViewChild('tabelaVec', { static: false })
	tabela: PublicTableComponent;
	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloColunaAgrupamento = 'Agrupamento';
	descricaoRelatorio =
		'Os valores extremos de chuva se referem aos valores máximos de chuva registrados de um determinado período, local ou região.';

	carregando = true;
	disabledSelectList = false;

	colunasVEC: ADTColumns[] = [];
	resultados: any[] = [];
	colunasSelecionadas: string[] = [];

	tituloTabela = '';
	periodoAgrupamento = '';

	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: true },
		{ name: 'Longitude', isFixed: true },
		{ name: 'Altitude', isFixed: true },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.resultados,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		this.periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!this.periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.carregando = true;
		this.inputs.setLoading(true);

		this.colunasVEC = [];
		this.resultados = [];
		const periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			this.periodoAgrupamento
		);

		this.tituloTabela = `Valores extremos de chuva - ${periodoTitulo}`;

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: this.periodoAgrupamento.toUpperCase(),
		};

		this.relatoriosService.buscarRelatorioVECPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.relatoriosService.verificaExistenciaDados(ndcAgrupados);
				this.resultados = this.padronizarResultado(ndcAgrupados, agrupamento);
				this.atualizarTitulosTabela();
				this.altualizarColunasTabelaVEC();
			},
			error: () => {
				this.toastrService.error(
					'Erro ao gerar tabela de Volumes Extremos de Chuva, tente novamente'
				);
				this.carregando = false;
				this.inputs.setLoading(false);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.carregando = false;
			},
		});
	}

	padronizarResultado(resultados: RelatorioVECResponse[], agrupamento: string) {
		return resultados.map(item => {
			const base = {
				nome:
					agrupamento === 'MUNICIPIO_POSTO'
						? corrigeDuplicacaoNome(item.nome)
						: item.nome,
				municipio: item.municipio || '-',
				posto: item.posto || '-',
				dataColeta: format(new Date(item.dataColeta), 'dd/MM/yyyy'),
				precipitacaoMaxima: numberToBrNumber(item.precipitacaoMaxima, 1),
				latitude: numberToBrNumber(item.latitude, 4) || '-',
				longitude: numberToBrNumber(item.longitude, 4) || '-',
				altitude: numberToBrNumber(item.altitude, 1) || '-',
			};

			if (agrupamento === 'MUNICIPIO_POSTO') {
				return {
					...base,
					microrregiao: item.microrregiao || '-',
					mesorregiao: item.mesorregiao || '-',
					regiao: item.regiao || '-',
					bacia: item.bacia || '-',
					subbacia: item.subbacia || '-',
				};
			}
			return base;
		});
	}

	altualizarColunasTabelaVEC() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'nome',
					title: 'Município/Posto',
					type: 'string',
					className: 'text-left',
				},
				{
					data: 'id',
					title: 'Id',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunasVEC = [...colunasParaExibir];
		} else if (agrupamento === 'MUNICIPIO') {
			this.disabledSelectList = true;
			this.colunasVEC = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'posto',
					title: 'Posto',
					type: 'string',
					className: 'text-center',
				},
			];
		} else {
			this.disabledSelectList = true;
			this.colunasVEC = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'posto',
					title: 'Posto',
					type: 'string',
					className: 'text-center',
				},
			];
		}

		this.colunasVEC.push(
			...[
				{
					data: 'dataColeta',
					title: 'Data',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'precipitacaoMaxima',
					title: 'Precipitação Máxima (mm)',
					className: 'text-center',
				},
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
				},
			]
		);
	}

	atualizarTitulosTabela() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		this.tituloColunaAgrupamento =
			Agrupamento[agrupamento as keyof typeof Agrupamento];
	}
}
