import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-legenda-mapa',
	templateUrl: './legenda-mapa.component.html',
	styleUrls: ['./legenda-mapa.component.scss'],
})
export class LegendaMapaComponent {
	@Input() legendaInfo: any;
	@Input() titulo: string;

	getKeys(): number[] {
		if (this.legendaInfo) {
			return Object.keys(this.legendaInfo).map(Number);
		}
		return [];
	}

	getLegendas(): Array<{
		valor: string;
		cor: any;
	}> {
		const legendas = [];
		const sortedKeys = this.getKeys()
			.map(Number)
			.sort((a, b) => a - b);
		for (const key in sortedKeys) {
			legendas.push({
				valor: sortedKeys[key].toFixed(1),
				cor: this.legendaInfo[sortedKeys[key]],
			});
		}
		legendas[0] = {
			...legendas[0],
			valor: `≤ ${Number(legendas[1]?.valor) - 0.1}`,
		};
		legendas[legendas.length - 1].valor = `≥ ${legendas.at(-1)?.valor}`;
		return legendas.map(e => ({ ...e, valor: e.valor.replace('.', ',') }));
	}

	get width(): string {
		return `${100 / this.getLegendas().length}%`;
	}

	getBorderRadius(index: number): string {
		const isFirst = index === 0;
		const isLast = index === this.getLegendas().length - 1;

		const topLeft = isFirst ? '10px' : '0';
		const topRight = isFirst ? '10px' : '0';
		const bottomRight = isLast ? '10px' : '0';
		const bottomLeft = isLast ? '10px' : '0';

		return `border-radius: ${topRight} ${bottomRight} ${bottomLeft} ${topLeft} ;`;
	}
}
