<div
	class="col d-flex {{
		labelPlacement === 'start'
			? 'flex-row gap-2 align-items-center'
			: 'flex-column justify-content-start'
	}}">
	<label
		class="form-check-label fw-normal label-group-input text-tertiary-light mb-2"
		[for]="controlName"
		>{{ label }}</label
	>
	<div [id]="idRadio" [class]="classRadio">
		<div *ngIf="options.length > 3; else singleColumn" class="row">
			<div
				*ngFor="let option of options; let i = index"
				class="col-6 gap-2 d-flex align-items-center">
				<input
					[formControlName]="controlName"
					class="form-check-input p-0 m-0"
					type="radio"
					[id]="option.value"
					[value]="option.value"
          [attr.disabled]="isDesabilitado ? true : null"
        />
				<label class="form-check-label text-tertiary" [for]="option.value"
					>{{ option.label }}
				</label>
			</div>
		</div>

		<ng-template #singleColumn>
			<div
				*ngFor="let option of options"
				class="gap-2 d-flex align-items-center">
				<input
					[formControlName]="controlName"
					class="form-check-input p-0 m-0"
					type="radio"
					[id]="option.value"
					[value]="option.value"
          [attr.disabled]="isDesabilitado ? true : null"
        />
				<label class="form-check-label text-tertiary" [for]="option.value"
					>{{ option.label }}
				</label>
			</div>
		</ng-template>
	</div>
</div>
