import { Component, inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
	Agrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	RelatorioDesvio,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { Subject, takeUntil } from 'rxjs';
import { Validators } from '@angular/forms';
import { format } from 'date-fns';
import { numberToBrNumber } from '@utils';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DateTimeUtils } from '@utils/datetime-util';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services';
import { Select } from '@layout/interfaces/select';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';

@Component({
	selector: 'seira-tabela-desvio-climatologia-pluviometria-observada',
	templateUrl:
		'./tabela-desvio-climatologia-pluviometria-observada.component.html',
	styleUrls: [
		'./tabela-desvio-climatologia-pluviometria-observada.component.scss',
	],
})
export class TabelaDesvioClimatologiaPluviometriaObservadaComponent
	implements OnInit, InstanciaRelatorio, OnDestroy
{
	inputs = inject(INPUTS_RELATORIOS);
	resultado: any[] = [];
	colunas: ADTColumns[] = [];
	carregando = false;
	periodo = '';
	tituloTabela = '';
	periodoAgrupamento = '';
	texto =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período determinado. Os desvios referem-se à variação da precipitação em relação à sua climatologia, que indica se o volume de chuva foi superior ou inferior ao esperado. A climatologia é o estudo das condições médias do clima em uma região ao longo de um período de 30 anos, utilizada como referência para comparar variações climáticas, como a precipitação.';
	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaDesvioClimatologiaObservado', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	incluirDetalhes = false;

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.resultado,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];

	agrupamento: Select<string>[];

	_destroyed = new Subject();
	tituloColunaAgrupamento = '';
	disabledSelectList = false;
	tipoDesvio = '';

	constructor(private readonly relatorioService: RelatoriosService) {
		this.setValidators();
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);

		this.tipoDesvio =
			this.inputs.form.get(FormularioRelatorio.TIPO)?.value ===
			'PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA'
				? 'milimetro'
				: 'porcentagem';

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });
		this.clearValidators();
		this._destroyed.next(undefined);
	}

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	atualizarTituloColuna() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		this.tituloColunaAgrupamento =
			Agrupamento[agrupamento as keyof typeof Agrupamento];
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'nome',
					title: 'Município/Posto',
					type: 'string',
					className: 'text-left',
				},
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'string',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'data',
					title: 'Data',
					className: 'text-start',
					render: (data: any, type: string) => {
						const dataCorrigida = data.replace(/\.0/g, '');
						const periodoAnual = dataCorrigida.length === 4;
						if (periodoAnual) {
							return dataCorrigida;
						}
						return type === 'display'
							? DateTimeUtils.formatarData(dataCorrigida, 'MM/yyyy')
							: data;
					},
				},
				{
					data: 'precipitacaoAcumulada',
					title: 'Pluviometria observada (mm)',
					className: 'text-center',
				},
				{
					data: 'desvioMilimetro',
					title: 'Desvio (mm)',
					className: 'text-center',
				},
				{
					data: 'desvioPorcentagem',
					title: 'Desvio (%)',
					className: 'text-center',
				},
				{
					data: 'climatologia',
					title: 'Climatologia (mm)',
					className: 'text-center',
				},
			]
		);
	}

	clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	extrairDatasResultado(preciptacaoAgrupada: RelatorioDesvio) {
		return [
			...new Set(
				preciptacaoAgrupada.desvios
					.filter((el: any) => el.data)
					.map((el: any) => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a: any, b: any) => +a - +b)
					.map((data: any) => {
						let formato = 'MM/yyyy';
						if (this.periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato);
					})
			),
		];
	}

	gerarRelatorio(): void {
		this.carregando = true;
		this.periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		const diaInicio =
			this.periodoAgrupamento !== 'anual'
				? dataInicio
				: new Date(`${new Date(dataInicio).getFullYear()}-01-01T00:00:00`);
		const diaFim =
			this.periodoAgrupamento !== 'anual'
				? dataFim
				: new Date(`${new Date(dataFim).getFullYear()}-12-31T23:59:59`);

		this.periodo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			this.periodoAgrupamento
		);
		this.tituloTabela = `Pluviometria Observada/Climatologia/Desvios - ${this.periodo}`;
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		let id = null;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!this.periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.incluirDetalhes = false;
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				id = this.inputs.form.get('estacao')?.value;
				this.incluirDetalhes = true;
				break;
			case 'MICRORREGIAO':
				id = this.inputs.form.get('microrregiao')?.value;
				break;
			case 'MESORREGIAO':
				id = this.inputs.form.get('mesorregiao')?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.inputs.form.get('regiao')?.value;
				break;
			case 'BACIA':
				id = this.inputs.form.get('bacia')?.value;
				break;
			case 'MUNICIPIO':
				id = this.inputs.form.get('municipio')?.value;
				break;
		}

		this.inputs.setLoading(true);

		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento,
				idEntidade: id,
				dataFim: new Date(diaFim),
				dataInicio: new Date(diaInicio),
				periodo: this.periodoAgrupamento.toUpperCase(),
				incluirDetalhes: this.incluirDetalhes,
			})
			.subscribe({
				next: listaDesviosClimasObs => {
					this.relatorioService.verificaExistenciaDados(listaDesviosClimasObs);
					this.resultado = [];
					this.inputs.setLoading(false);
					this.carregando = false;

					const agrupamento = this.normalizarAgrupamento(
						this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO)
					);
					this.atualizarTituloColuna();
					this.atualizarColunasTabela();

					if (!listaDesviosClimasObs.length) return;

					const dataResultado = this.extrairDatasResultado(
						listaDesviosClimasObs[0]
					);

					listaDesviosClimasObs.forEach(p => {
						this.resultado.push(
							...(this.periodoAgrupamento === 'anual'
								? this.calcularDadosAnuais(p, dataResultado, agrupamento)
								: this.formatarResultados(p, agrupamento))
						);
					});
				},
				error: () => {
					this.inputs.setLoading(false);
					this.carregando = false;
				},
			});
	}

	private normalizarAgrupamento(agrupamento: string): string {
		agrupamento = agrupamento.toLowerCase();
		if (agrupamento === 'regiao_pluviometrica') {
			return 'regiaoPluviometrica';
		}
		if (agrupamento === 'subbacia') {
			return 'subBacia';
		}
		return agrupamento;
	}

	private calcularDadosAnuais(
		resultadoDesvios: RelatorioDesvio,
		dataResultado: string[],
		agrupamento: string
	) {
		return dataResultado.map(ano => {
			const dadosDoAno = resultadoDesvios.desvios.filter((item: any) =>
				item.data.startsWith(ano)
			);
			const calculos = this.calcularTotaisAnuais(dadosDoAno);
			return {
				nome: resultadoDesvios[agrupamento as keyof RelatorioDesvio],
				data: ano,
				...calculos,
				...(agrupamento === 'municipio_posto'
					? this.obterDetalhesPosto(resultadoDesvios)
					: {}),
			};
		});
	}

	private calcularTotaisAnuais(dados: any[]) {
		const chuvaAnual = dados.reduce((total, item) => total + item.chuva, 0);
		const climatologiaAnual = dados.reduce(
			(total, item) => total + item.climatologia,
			0
		);
		const desvioMilimetroAnual = dados.reduce(
			(total, item) => total + item.desvioMilimetro,
			0
		);
		const desvioPorcentagemAnual =
			climatologiaAnual !== 0
				? (desvioMilimetroAnual / climatologiaAnual) * 100
				: 0;

		return {
			precipitacaoAcumulada: numberToBrNumber(chuvaAnual, 1) || '-',
			climatologia: numberToBrNumber(climatologiaAnual, 1) || '-',
			desvioMilimetro: numberToBrNumber(desvioMilimetroAnual, 1) || '-',
			desvioPorcentagem: numberToBrNumber(desvioPorcentagemAnual, 1) || '-',
		};
	}

	private obterDetalhesPosto(dadosPosto: any) {
		return {
			nome:
				dadosPosto.municipio === dadosPosto.estacao
					? dadosPosto.municipio
					: `${dadosPosto.municipio}/${dadosPosto.estacao}`,
			municipio: dadosPosto.municipio || '-',
			latitude: numberToBrNumber(dadosPosto.latitude, 4),
			longitude: numberToBrNumber(dadosPosto.longitude, 4),
			altitude: numberToBrNumber(dadosPosto.altitude, 1),
			microrregiao: dadosPosto.microrregiao || '-',
			mesorregiao: dadosPosto.mesorregiao || '-',
			regiao: dadosPosto.regiaoPluviometrica || '-',
			bacia: dadosPosto.bacia || '-',
			subbacia: dadosPosto.subBacia || '-',
		};
	}

	private formatarResultados(resultados: RelatorioDesvio, agrupamento: string) {
		return resultados.desvios.map((resultado: any) => {
			return {
				...(agrupamento === 'municipio_posto'
					? this.obterDetalhesPosto(resultados)
					: { nome: resultados[agrupamento as keyof RelatorioDesvio] }),
				data: resultado.data,
				desvioMilimetro: numberToBrNumber(resultado.desvioMilimetro, 1),
				desvioPorcentagem: numberToBrNumber(resultado.desvioPorcentagem, 1),
				climatologia: numberToBrNumber(resultado.climatologia, 1),
				precipitacaoAcumulada: numberToBrNumber(resultado.chuva, 1),
			};
		});
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}
}
