import { microrregioesComRanges } from '@componentes/mapa-paraiba-svg/svg-paths';
import {
	DesvioCores,
	NDCCores,
	PluviometriaObservadaCores,
} from '@modulos/home/submodulos/dados-meteorologicos/enum/Variaveis-metereologica';

export enum LegendaBoletimCores {
	VERMELHO = '#FF5900',
	LARANJA = '#FF8800',
	LARANJA_CLARO = '#FFB900',
	AMARELO = '#FFFF00',
	VERDE_CLARO = '#9CFC00',
	VERDE = '#71DB72',
	VERDE_ESCURO = '#32CD31',
	AZUL_CLARO = '#74c8fa',
	AZUL = '#0296FF',
	AZUL_ESCURO = '#0065FD',
	AZUL_MUITO_ESCURO = '#0504f6',
}

export enum LegendaDadosPluviometricosCores {
	VERDE = '#83E954',
	AMARELO = '#FFF000',
	LARANJA = '#F8A135',
	VERMELHO = '#FF0000',
}

export const LegendaDadosPluviometricos: { [key: string]: string } = {
	'0 - 10': LegendaDadosPluviometricosCores.VERDE,
	'10 - 30': LegendaDadosPluviometricosCores.AMARELO,
	'30 - 70': LegendaDadosPluviometricosCores.LARANJA,
	'> 70': LegendaDadosPluviometricosCores.VERMELHO,
};

export const legendaInfoPluvObservadaPeriodo: { [key: number]: string } = {
	0: PluviometriaObservadaCores.VALOR_MINIMO,
	10: PluviometriaObservadaCores.VALOR_EXTREMAMENTE_BAIXO,
	20: PluviometriaObservadaCores.VALOR_MUITO_BAIXO,
	30: PluviometriaObservadaCores.VALOR_BAIXO,
	50: PluviometriaObservadaCores.VALOR_MEDIO_BAIXO,
	100: PluviometriaObservadaCores.VALOR_MEDIO,
	150: PluviometriaObservadaCores.VALOR_MEDIO_ALTO,
	200: PluviometriaObservadaCores.VALOR_ALTO,
	250: PluviometriaObservadaCores.VALOR_MUITO_ALTO,
	300: PluviometriaObservadaCores.VALOR_EXTREMAMENTE_ALTO,
	350: PluviometriaObservadaCores.VALOR_QUASE_MAXIMO,
	400: PluviometriaObservadaCores.VALOR_MAXIMO,
};

export const legendaInfoPluvObservada2MesesAAnual: { [key: number]: string } = {
	0: PluviometriaObservadaCores.VALOR_MINIMO,
	200: PluviometriaObservadaCores.VALOR_EXTREMAMENTE_BAIXO,
	300: PluviometriaObservadaCores.VALOR_MUITO_BAIXO,
	400: PluviometriaObservadaCores.VALOR_BAIXO,
	500: PluviometriaObservadaCores.VALOR_MEDIO_BAIXO,
	650: PluviometriaObservadaCores.VALOR_MEDIO,
	800: PluviometriaObservadaCores.VALOR_MEDIO_ALTO,
	950: PluviometriaObservadaCores.VALOR_ALTO,
	1100: PluviometriaObservadaCores.VALOR_MUITO_ALTO,
	1250: PluviometriaObservadaCores.VALOR_EXTREMAMENTE_ALTO,
	1400: PluviometriaObservadaCores.VALOR_QUASE_MAXIMO,
	1600: PluviometriaObservadaCores.VALOR_MAXIMO,
};

export const legendaInfoDesvioMilimetro: { [key: number]: string } = {
	[-150]: DesvioCores.VALOR_MINIMO,
	[-100]: DesvioCores.VALOR_EXTREMAMENTE_BAIXO,
	[-50]: DesvioCores.VALOR_MUITO_BAIXO,
	[-25]: DesvioCores.VALOR_BAIXO,
	0: DesvioCores.VALOR_MEDIO_BAIXO,
	50: DesvioCores.VALOR_MEDIO_ALTO,
	100: DesvioCores.VALOR_ALTO,
	150: DesvioCores.VALOR_MUITO_ALTO,
	200: DesvioCores.VALOR_EXTREMAMENTE_ALTO,
	250: DesvioCores.VALOR_MAXIMO,
};

export const legendaInfoDesvioPorcentagem: { [key: number]: string } = {
	[-100]: DesvioCores.VALOR_MINIMO,
	[-75]: DesvioCores.VALOR_EXTREMAMENTE_BAIXO,
	[-50]: DesvioCores.VALOR_MUITO_BAIXO,
	[-25]: DesvioCores.VALOR_BAIXO,
	0: DesvioCores.VALOR_MEDIO_BAIXO,
	25: DesvioCores.VALOR_MEDIO_ALTO,
	100: DesvioCores.VALOR_ALTO,
	300: DesvioCores.VALOR_MUITO_ALTO,
	350: DesvioCores.VALOR_EXTREMAMENTE_ALTO,
	400: DesvioCores.VALOR_MAXIMO,
};

export const legendaInfoBoletimDiario: { [key: number]: string } = {
	0: LegendaBoletimCores.VERMELHO,
	1: LegendaBoletimCores.LARANJA,
	5: LegendaBoletimCores.LARANJA_CLARO,
	10: LegendaBoletimCores.AMARELO,
	25: LegendaBoletimCores.VERDE_CLARO,
	50: LegendaBoletimCores.VERDE,
	75: LegendaBoletimCores.VERDE_ESCURO,
	100: LegendaBoletimCores.AZUL_CLARO,
	150: LegendaBoletimCores.AZUL,
	200: LegendaBoletimCores.AZUL_ESCURO,
	1000000: LegendaBoletimCores.AZUL_MUITO_ESCURO,
};

export const legendaNDCInfo: { [key: number]: string } = {
	0: NDCCores.VALOR_MINIMO,
	1: NDCCores.VALOR_QUASE_MINIMO,
	2: NDCCores.VALOR_EXTREMAMENTE_BAIXO,
	5: NDCCores.VALOR_MUITO_BAIXO,
	10: NDCCores.VALOR_BAIXO,
	20: NDCCores.VALOR_MEDIO_BAIXO,
	30: NDCCores.VALOR_MEDIO,
	40: NDCCores.VALOR_MEDIO_ALTO,
	50: NDCCores.VALOR_ALTO,
	100: NDCCores.VALOR_MUITO_ALTO,
	200: NDCCores.VALOR_EXTREMAMENTE_ALTO,
	300: NDCCores.VALOR_QUASE_MAXIMO,
	400: NDCCores.VALOR_MAXIMO,
};

export const CORES = {
	BRANCO_NEVE: '#f7f2e4',
	VERMELHO: '#ec0808',
	AMARELO: '#ffea00',
	VERDE: '#22ee03',
	AZUL: '#407dec',
	AZUL_ESCURO: '#035cf8',
	CINZA: '#D4D4D4',
	AZUL_CLARO: '#AFC9EF',
};

export const legendaRanges: { [key: number]: string } = {};

export const coresPadrao = [
	CORES.BRANCO_NEVE,
	CORES.VERMELHO,
	CORES.AMARELO,
	CORES.AZUL_CLARO,
	CORES.AZUL,
	CORES.AZUL_ESCURO,
	CORES.CINZA,
];
export const coresQuantis = [
	CORES.VERMELHO,
	CORES.AMARELO,
	CORES.VERDE,
	CORES.AZUL,
	CORES.AZUL_ESCURO,
	CORES.CINZA,
];

microrregioesComRanges.forEach(microrregiao => {
	microrregiao.ranges.forEach((range, index) => {
		legendaRanges[range] = coresPadrao[index];
	});
});
