import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	inject,
	Injector,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { Select } from '@layout/interfaces/select';
import {
	AgrupamentoQuantis,
	Formato,
	Meses,
	TipoMapaGraficoTabelaQuantis,
} from '@home/submodulos/dados-meteorologicos/interfaces/relatorioEnum';
import {
	compararStrings,
	enumAsSelectOptions,
	obter_erro_request,
	removerAcentos,
} from '@utils';
import {
	ComponenteRelatorio,
	ConjuntoLabelsDinamicasPeriodo,
	FormularioRelatorio,
	INPUTS_RELATORIOS_QUANTIS,
	InstanciaRelatorio,
	PeriodosInterface,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import moment, { Moment } from 'moment/moment';
import { ComponentesRelatoriosService } from '@home/submodulos/dados-meteorologicos/services/componentes-relatorios.service';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { isAfter, startOfMonth, subDays } from 'date-fns';
import { DateTimeUtils } from '@utils/datetime-util';
import { merge } from 'rxjs';
import { Mensagens } from '@core/enums/mensagens';
import { corrigeDuplicacaoNome } from '@modulos/home/submodulos/dados-meteorologicos/utils';
import { EPeriodoChuvoso } from '@modulos/home/submodulos/dados-meteorologicos/enum';

@Component({
	selector: 'seira-quantis',
	templateUrl: './quantis.component.html',
	styleUrls: ['./quantis.component.scss'],
})
export class QuantisComponent implements OnInit {
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;
	private listaRelatorios = inject(
		ComponentesRelatoriosService
	).getRelatorios();
	relatorioAtual: ComponenteRelatorio =
		this.listaRelatorios.QUANTIS.MAPA.QUANTIS;
	relatorioAnterior?: ComponenteRelatorio;
	instanceRelatorio?: InstanciaRelatorio;
	inputsRelatorioInjector!: Injector;
	form!: FormGroup;
	loadingInicialData = true;
	categorias: Select[] = [];
	formatos: Select[] = [];
	regioes: Select[] = [];
	microrregioes: Select[] = [];
	tiposPeriodosChuvosos: Select[] = [];
	estacoes: Estacao[] = [];
	municipioPostos: Select[] = [];
	tipos: Select[] = [];
	meses: Select[] = [];
	optionsAgrupamento: Select[] = [];
	mensagemDataInvalida = '';
	botoesDeExportacao: GroupButton[] = [];
	labelAdptativaPeriodo?: ConjuntoLabelsDinamicasPeriodo | null;

	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que você vai encontrar nesta página',
				text: 'Informações a respeito da classificação das chuvas registradas em um local ou região específica, verificando se o período foi muito chuvoso, chuvoso, muito seco, seco ou normal.',
			},
		],
	};
	opcoesTipoVisualizacao: OptionRadio<'MAPA' | 'GRAFICO' | 'TABELA'>[] = [
		{ label: 'Mapa', value: 'MAPA' },
		{ label: 'Gráfico', value: 'GRAFICO' },
		{ label: 'Tabela', value: 'TABELA' },
	];
	opcoesPeriodo: OptionRadio<PeriodosLabel | string>[] = [
		{ label: 'Diário', value: 'diario' },
		{ label: 'Mensal', value: 'mensal' },
		{ label: 'Anual', value: 'anual' },
	];

	constructor(
		private cdr: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		readonly relatoriosService: RelatoriosService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		this.optionsAgrupamento = enumAsSelectOptions(AgrupamentoQuantis);
		this.tipos = enumAsSelectOptions(TipoMapaGraficoTabelaQuantis);
		this.tiposPeriodosChuvosos = enumAsSelectOptions(EPeriodoChuvoso);
		this.setFormControls();
		this.buscarFiltrosEstacoes();
		this.observarFormulario();
		this.observarFormatos();
		this.observarMudancaTipo();
		this.observarMudancaAgrupamento();
	}

	get dataPeriodo() {
		const data = this.getFormItemValue(FormularioRelatorio.PERIODO);

		if (data && this.periodoInfo) {
			const dataObj = moment(data);

			if (this.periodoInfo.unit === 'day') {
				return dataObj.startOf('day').toDate();
			} else if (this.periodoInfo.unit === 'month') {
				return dataObj.startOf('month').toDate();
			} else if (this.periodoInfo.unit === 'year') {
				return dataObj.startOf('year').toDate();
			}
		}
		return null;
	}

	get dataInicio() {
		const data = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);

		if (data && this.periodoInfo) {
			const dataObj = moment(data);

			if (this.periodoInfo.unit === 'day') {
				return dataObj.startOf('day').toDate();
			} else if (this.periodoInfo.unit === 'month') {
				return dataObj.startOf('month').toDate();
			} else if (this.periodoInfo.unit === 'year') {
				return dataObj.startOf('year').toDate();
			}
		}
		return null;
	}

	get dataFim() {
		const data = this.getFormItemValue(FormularioRelatorio.DATA_FIM);

		if (data && this.periodoInfo) {
			const dataObj = moment(data);
			const hoje = moment();

			if (this.periodoInfo.unit === 'day') {
				if (
					dataObj.date() !== hoje.date() ||
					dataObj.month() !== hoje.month() ||
					dataObj.year() !== hoje.year()
				) {
					return dataObj.endOf('day').toDate();
				}
				return dataObj.toDate();
			} else if (this.periodoInfo.unit === 'month') {
				if (
					dataObj.month() !== hoje.month() ||
					dataObj.year() !== hoje.year()
				) {
					return dataObj.endOf('month').toDate();
				}
				return dataObj.toDate();
			} else if (this.periodoInfo.unit === 'year') {
				if (dataObj.year() !== hoje.year()) {
					return dataObj.endOf('year').toDate();
				}
				return dataObj.toDate();
			}
		}
		return null;
	}

	get limiteDataInicioInferior() {
		const dataInicioFromForm = this.getFormItemValue(
			FormularioRelatorio.DATA_INICIO
		);
		const periodoInfo = this.periodoInfo;
		if (!periodoInfo || !dataInicioFromForm) return null;
		return moment(new Date(dataInicioFromForm))
			.subtract(periodoInfo?.amount, periodoInfo?.unit)
			.toDate();
	}

	get limiteDataFimSuperior() {
		const dataInicioFromForm = this.getFormItemValue(
			FormularioRelatorio.DATA_INICIO
		);
		if (!this.periodoInfo || !dataInicioFromForm) return null;
		const amount = Number(this.periodoInfo?.amount);
		const limiteSuperior = moment(new Date(dataInicioFromForm))
			.add(amount, this.periodoInfo?.unit)
			.toDate();
		const hoje = new Date();
		if (limiteSuperior > hoje) return hoje;
		return limiteSuperior;
	}

	get periodoInfo() {
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;
		let tipoPeriodoAtual: PeriodosLabel = 'diario';

		if (
			this.relatorioAtual?.periodos?.anual &&
			(periodoBusca === 'anual' ||
				(!this.relatorioAtual?.periodos?.mensal &&
					!this.relatorioAtual?.periodos?.diario))
		) {
			tipoPeriodoAtual = 'anual';
		} else if (
			this.relatorioAtual?.periodos?.mensal &&
			(periodoBusca === 'mensal' ||
				(!this.relatorioAtual?.periodos?.anual &&
					!this.relatorioAtual?.periodos?.diario))
		) {
			tipoPeriodoAtual = 'mensal';
		} else if (this.relatorioAtual?.periodos?.mensal_unico) {
			tipoPeriodoAtual = 'mensal_unico';
		} else if (this.relatorioAtual?.periodos?.anual_unico) {
			tipoPeriodoAtual = 'anual_unico';
		} else {
			tipoPeriodoAtual = 'anual';
		}

		const periodoInfo = this.relatorioAtual?.periodos?.[tipoPeriodoAtual];
		return periodoInfo;
	}

	get mostrarPeriodoBusca() {
		return this.getMostrarEntidade(FormularioRelatorio.PERIODO_BUSCA);
	}

	get mostrarSelectAgrupamento() {
		return this.getMostrarEntidade(FormularioRelatorio.AGRUPAMENTO);
	}

	get mostrarSelectPostos() {
		return this.getMostrarEntidade(FormularioRelatorio.ESTACAO);
	}

	get mostrarSelectMicrorregioes() {
		return this.getMostrarEntidade(FormularioRelatorio.MICRORREGIAO);
	}

	get mostrarSelectRegioes() {
		return this.getMostrarEntidade(FormularioRelatorio.REGIAO_PLUVIOMETRICA);
	}

	get mostrarSelectPeriodoChuvoso() {
		return (
			this.form.get('periodoBusca')?.value === 'periodo_chuvoso' &&
			this.form.value.tipo
		);
	}

	get isMesesValidos(): boolean {
		const mesInicio = this.form.get(FormularioRelatorio.MES_INICIO)?.value;
		const mesFim = this.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return false;
		}

		const mesesEnum = Object.values(Meses);
		const indexMesInicio = mesesEnum.findIndex(mes =>
			compararStrings(mes, mesInicio)
		);
		const indexMesFim = mesesEnum.findIndex(mes =>
			compararStrings(mes, mesFim)
		);

		if (indexMesInicio > indexMesFim) {
			return false;
		}

		return true;
	}

	get isIntervalosDatasValido(): boolean {
		const mapeamento: Record<string, string> = {
			day: 'dias',
			month: 'meses',
			year: 'anos',
		};

		const hoje = new Date();

		if (
			(!this.dataInicio || !this.dataFim) &&
			(this.relatorioAtual?.periodos?.diario ||
				this.relatorioAtual?.periodos?.mensal ||
				this.relatorioAtual?.periodos?.anual)
		) {
			this.mensagemDataInvalida = `Selecione uma data de início e uma data de fim`;
			return false;
		}

		if (
			!this.dataPeriodo &&
			(this.relatorioAtual?.periodos?.mensal_unico ||
				this.relatorioAtual?.periodos?.anual_unico)
		) {
			this.mensagemDataInvalida = `Selecione uma data`;
			return false;
		}

		if (this.dataPeriodo && isAfter(this.dataPeriodo, hoje)) {
			this.mensagemDataInvalida = `Não é permitido selecionar datas futuras`;
			return false;
		}

		if (this.dataInicio && this.dataFim) {
			if (isAfter(this.dataInicio, hoje) || isAfter(this.dataFim, hoje)) {
				this.mensagemDataInvalida = `Não é permitido selecionar datas futuras`;
				return false;
			}

			if (isAfter(this.dataInicio, this.dataFim)) {
				this.mensagemDataInvalida = `A data de início deve ser anterior à data de fim`;
				return false;
			}

			const diferencaInicioFim = DateTimeUtils.subtrairEmMilisegundos(
				this.dataFim,
				this.dataInicio
			);
			const limite = this.limiteDataInicioInferior;

			if (!limite) {
				return true;
			}

			const dataInicio = moment(this.dataInicio);
			const dataFimMinEsperada = dataInicio
				.clone()
				.add(this.periodoInfo?.minAmount, this.periodoInfo?.unit);

			const dataFimMaxEsperada = dataInicio
				.clone()
				.add(this.periodoInfo?.amount, this.periodoInfo?.unit);

			const diferencaMinInicioFim = this.periodoInfo?.minAmount
				? dataFimMinEsperada.diff(dataInicio)
				: 0;
			const diferencaMaxInicioFim = this.periodoInfo?.amount
				? dataFimMaxEsperada.diff(dataInicio)
				: Infinity;

			const toleranciaMilissegundo = 1;
			if (diferencaInicioFim < diferencaMinInicioFim - toleranciaMilissegundo) {
				this.mensagemDataInvalida = `A diferença entre as datas deve ser de no mínimo ${this
					.periodoInfo?.minAmount} ${
					mapeamento[this.periodoInfo?.unit as string]
				}`;
				return false;
			}

			if (diferencaInicioFim > diferencaMaxInicioFim + toleranciaMilissegundo) {
				this.mensagemDataInvalida = `A diferença entre as datas deve ser de no máximo ${this
					.periodoInfo?.amount} ${
					mapeamento[this.periodoInfo?.unit as string]
				}`;
				return false;
			}

			if (
				this.periodoInfo?.apenasMesmoAno &&
				this.dataInicio.getFullYear() !== this.dataFim.getFullYear()
			) {
				this.mensagemDataInvalida = `O mês de início deve ser no mesmo ano do mês de fim`;
				return false;
			}
		}
		return true;
	}

	observarFormatos() {
		this.form
			.get(FormularioRelatorio.TIPO_VISUALIZACAO)
			?.valueChanges.subscribe(formato => {
				this.analiseFormato(formato);
			});
	}

	analiseFormato(formato: string) {
		switch (formato) {
			default:
				this.tipos = enumAsSelectOptions(TipoMapaGraficoTabelaQuantis);
		}
		this.form.patchValue({ tipo: this.tipos[0].value }, { emitEvent: false });
	}

	observarFormulario() {
		this.form.valueChanges.subscribe({
			next: ({
				tipoVisualizacao,
				tipo,
				periodoBusca,
			}: {
				tipoVisualizacao?: keyof typeof Formato;
				tipo?: keyof typeof TipoMapaGraficoTabelaQuantis;
				periodoBusca: PeriodosLabel;
			}) => {
				if ('QUANTIS' in this.listaRelatorios) {
					const categorias = this.listaRelatorios['QUANTIS'] as any;
					if (tipoVisualizacao && tipoVisualizacao in categorias) {
						const formatos = categorias[tipoVisualizacao];
						if (tipo && tipo in formatos) {
							this.relatorioAtual = formatos[tipo];
						} else {
							this.relatorioAtual = formatos;
						}
					} else {
						this.relatorioAtual = categorias;
					}
				}

				this.loadDynamicComponent(this.relatorioAtual?.componente);
				if (this.mostrarPeriodoBusca) {
					this.setLabelAdptativaPeriodoByPeriodoBusca(periodoBusca);
				} else {
					this.setLabelAdptativaPeriodoSemPeriodoBusca();
				}
				this.cdr.detectChanges();
			},
		});
	}

	observarMudancaTipo() {
		merge(
			this.form.get('tipo')!.valueChanges,
			this.form.get('tipoVisualizacao')!.valueChanges,
			this.form.get('periodoBusca')!.valueChanges
		).subscribe(() => {
			this.resetDatas();
			if (!this.form.get('tipo')?.value) {
				this.form
					.get(FormularioRelatorio.PERIODO_BUSCA)
					?.setValue(this.opcoesPeriodo[0].value, { emitEvent: false });
			}
			setTimeout(() => {
				if (!this.form.get(FormularioRelatorio.AGRUPAMENTO)?.value) {
					this.form
						.get(FormularioRelatorio.AGRUPAMENTO)
						?.setValue(this.optionsAgrupamento[0].value);
				}
				this.preencherValores();
				this.gerarRelatorio();
			}, 100);
		});
	}

	observarMudancaAgrupamento() {
		this.form.get('agrupamento')!.valueChanges.subscribe(() => {
			if (
				this.form.get(FormularioRelatorio.AGRUPAMENTO)?.value ===
				'REGIAO_PLUVIOMETRICA'
			) {
				this.opcoesPeriodo.push({
					label: 'Período chuvoso',
					value: 'periodo_chuvoso',
				});
			} else {
				this.opcoesPeriodo.pop();
			}
		});
	}

	gerarRelatorio() {
		if (
			!this.isMesesValidos &&
			this.relatorioAtual?.periodos?.mensal_por_nome
		) {
			this.toastr.info(
				'O mês de início deve ser anterior ao mês de fim.',
				'Não foi possível gerar resultados'
			);
			return;
		}
		if (!this.isIntervalosDatasValido) {
			this.periodoInfo?.amount;
			if (!this.periodoInfo) {
				this.toastr.info(
					'O intervalo de datas é inválido',
					'Não foi possível gerar resultados'
				);
			}

			this.toastr.info(
				this.mensagemDataInvalida,
				'Não foi possível gerar resultados'
			);
			return;
		}

		this.form.patchValue({
			dataInicio: this.dataInicio,
			dataFim: this.dataFim,
			periodo: this.dataPeriodo,
		});

		if (this.form.invalid) {
			return;
		}

		this.instanceRelatorio?.gerarRelatorio();
	}

	getFormItemValue(field: string) {
		return this.form.get(field)?.value;
	}

	get loadingForm() {
		return !!this.form.get(FormularioRelatorio.LOADING_GERAR)?.value;
	}

	setLoading(state: boolean): void {
		this.form.get(FormularioRelatorio.LOADING_GERAR)?.setValue(state);
	}

	private setFormControls(): void {
		const hoje = new Date();
		const inicioMesAtual = startOfMonth(hoje);
		const umMesAtras = subDays(hoje, 30);
		const mesesArray = Object.values(Meses);
		const mesAtual = removerAcentos(mesesArray[hoje.getMonth()]);
		const dataInicio =
			hoje.getFullYear() !== umMesAtras.getFullYear()
				? inicioMesAtual
				: umMesAtras;

		this.form = this.formBuilder.group({
			[FormularioRelatorio.TIPO]: new FormControl<
				keyof typeof TipoMapaGraficoTabelaQuantis | null
			>('QUANTIS'),
			[FormularioRelatorio.TIPO_VISUALIZACAO]: new FormControl('MAPA'),
			[FormularioRelatorio.AGRUPAMENTO]: new FormControl('MUNICIPIO_POSTO'),
			[FormularioRelatorio.ESTACAO]: new FormControl(null),
			[FormularioRelatorio.MICRORREGIAO]: new FormControl(null),
			[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: new FormControl(null),
			[FormularioRelatorio.PERIODO_CHUVOSO]: new FormControl<null | string>(
				'ANO_CIVIL'
			),
			[FormularioRelatorio.PERIODO_BUSCA]: new FormControl<PeriodosLabel>(
				'diario'
			),
			[FormularioRelatorio.DATA_INICIO]: new FormControl<Moment>(
				moment(dataInicio)
			),
			[FormularioRelatorio.DATA_FIM]: new FormControl<Moment>(moment(hoje)),
			[FormularioRelatorio.MES_INICIO]: new FormControl<string>(
				Meses.JANEIRO.toLocaleUpperCase()
			),
			[FormularioRelatorio.MES_FIM]: new FormControl<string>(
				mesAtual.toLocaleUpperCase()
			),
			[FormularioRelatorio.PERIODO]: new FormControl<moment.Moment | null>(
				moment(hoje)
			),
			[FormularioRelatorio.LOADING_GERAR]: new FormControl<boolean>(false),
		});
	}

	private setInjector() {
		this.inputsRelatorioInjector = Injector.create({
			providers: [
				{
					provide: INPUTS_RELATORIOS_QUANTIS,
					useValue: {
						form: this.form,
						agrupamentos: this.optionsAgrupamento,
						estacoes: this.estacoes,
						regioes: this.regioes,
						microrregioes: this.microrregioes,
						setLoading: this.setLoading,
					},
				},
			],
		});
	}

	private buscarFiltrosEstacoes() {
		this.relatoriosService.consultarOpcoesFiltros().subscribe({
			next: resp => {
				this.estacoes = resp.estacoes;
				this.microrregioes = resp.microrregioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.microrregioes.unshift({ value: '0', name: 'Estado completo' });

				this.regioes = resp.regioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));

				if (this.estacoes) {
					const estacoesFiltradas = this.estacoes.filter(
						e =>
							e.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL' &&
							e.statusEstacao === 'ATIVA'
					);

					estacoesFiltradas.map(e => {
						this.municipioPostos.push({
							name: corrigeDuplicacaoNome(`${e.nomeMunicipio}/${e.nomePosto}`),
							value: e.id.toString(),
						});
					});
				}

				this.setInjector();
				this.preencherValores();
			},
			error: err => {
				this.loadingInicialData = false;
				const msg_erro = obter_erro_request(err);
				this.toastr.error(
					msg_erro,
					'Erro ao buscar as informações das estações'
				);
			},
			complete: () => {
				this.loadingInicialData = false;
				this.loadDynamicComponent(this.relatorioAtual.componente);
				this.gerarRelatorio();
				this.setLabelAdptativaPeriodo();
			},
		});
	}

	private preencherValores() {
		if (!this.form.get(FormularioRelatorio.ESTACAO)?.value) {
			this.form
				.get(FormularioRelatorio.ESTACAO)
				?.setValue(this.municipioPostos[0].value);
		}
		if (!this.form.get(FormularioRelatorio.MICRORREGIAO)?.value) {
			this.form
				.get(FormularioRelatorio.MICRORREGIAO)
				?.setValue(this.microrregioes[0].value);
		}
		if (!this.form.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)?.value) {
			this.form
				.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
				?.setValue(this.regioes[0].value);
		}
	}

	private loadDynamicComponent(component: any): void {
		if (this.relatorioAtual !== this.relatorioAnterior) {
			if (this.dynamicComponentContainer.get(0)) {
				this.dynamicComponentContainer.remove(0);
			}
			if (component) {
				const componentFactory =
					this.componentFactoryResolver.resolveComponentFactory(component);
				const componentRef = this.dynamicComponentContainer.createComponent(
					componentFactory,
					undefined,
					this.inputsRelatorioInjector
				);
				this.instanceRelatorio = componentRef.instance as InstanciaRelatorio;
				this.botoesDeExportacao =
					this.instanceRelatorio.botoesDeExportacao || [];

				if (this.relatorioAtual?.periodos) {
					this.setOpcoesPeriodoByRelatorioInstance(
						this.relatorioAtual.periodos
					);
				}
				if (
					!this.instanceRelatorio.estacoes?.length ||
					!this.instanceRelatorio.regioes?.length ||
					!this.instanceRelatorio.microrregioes?.length
				) {
					this.instanceRelatorio.estacoes = this.estacoes;
					this.instanceRelatorio.regioes = this.regioes;
					this.instanceRelatorio.microrregioes = this.microrregioes;
				}
			} else {
				this.instanceRelatorio = undefined;
			}
		}
		this.relatorioAnterior = this.relatorioAtual;
	}

	private resetDatas() {
		const hoje = new Date();
		const inicioMesAtual = startOfMonth(hoje);
		const umMesAtras = subDays(hoje, 30);
		const mesesArray = Object.values(Meses);
		const mesAtual = removerAcentos(mesesArray[hoje.getMonth()]);
		const dataInicio =
			hoje.getFullYear() !== umMesAtras.getFullYear()
				? inicioMesAtual
				: umMesAtras;

		this.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValue(Meses.JANEIRO.toLocaleUpperCase());
		this.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValue(mesAtual.toLocaleUpperCase());
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValue(moment(dataInicio));
		this.form.get(FormularioRelatorio.DATA_FIM)?.setValue(moment(hoje));
		this.form.get(FormularioRelatorio.PERIODO)?.setValue(moment(hoje));
	}

	private setOpcoesPeriodoByRelatorioInstance(
		periodos: Partial<{
			[K in PeriodosLabel]: PeriodosInterface;
		}>
	) {
		this.opcoesPeriodo = [];

		if (this.mostrarPeriodoBusca) {
			if (periodos.diario) {
				this.opcoesPeriodo.push({ label: 'Diário', value: 'diario' });
			}
			if (periodos.mensal || periodos.mensal_unico) {
				this.opcoesPeriodo.push({ label: 'Mensal', value: 'mensal' });
			}
			if (periodos.anual || periodos.anual_unico) {
				this.opcoesPeriodo.push({ label: 'Anual', value: 'anual' });
			}
		}
	}

	private setLabelAdptativaPeriodo() {
		const periodoBusca = this.form.get('periodoBusca')!.value;

		if (this.mostrarPeriodoBusca) {
			this.setLabelAdptativaPeriodoByPeriodoBusca(periodoBusca);
		} else {
			this.setLabelAdptativaPeriodoSemPeriodoBusca();
		}
	}

	private setLabelAdptativaPeriodoByPeriodoBusca(periodoBusca: PeriodosLabel) {
		this.labelAdptativaPeriodo = {};
		const periodosRelatorio = this.relatorioAtual?.periodos;

		switch (periodoBusca) {
			case 'diario':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Período inicial',
						placeholder: 'Selecione o período inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Período final',
						placeholder: 'Selecione o período final',
						dataType: 'day',
					},
				};
				break;
			case 'mensal':
				if (periodosRelatorio?.mensal_unico) {
					this.labelAdptativaPeriodo = {
						unico: {
							label: 'Mês',
							placeholder: 'Selecione um mês',
							dataType: 'month',
						},
					};
				} else {
					this.labelAdptativaPeriodo = {
						inicio: {
							label: 'Mês inicial',
							placeholder: 'Selecione o mês inicial',
							dataType: 'month',
						},
						fim: {
							label: 'Mês final',
							placeholder: 'Selecione o mês final',
							dataType: 'month',
						},
					};
				}
				break;
			case 'anual':
				if (periodosRelatorio?.anual_unico) {
					this.labelAdptativaPeriodo = {
						unico: {
							label: 'Ano',
							placeholder: 'Selecione um ano',
							dataType: 'year',
						},
					};
				} else {
					this.labelAdptativaPeriodo = {
						inicio: {
							label: 'Ano inicial',
							placeholder: 'Selecione o ano inicial',
							dataType: 'year',
						},
						fim: {
							label: 'Ano final',
							placeholder: 'Selecione o ano final',
							dataType: 'year',
						},
					};
				}
				break;
			default:
				this.labelAdptativaPeriodo = null;
		}
	}

	private setLabelAdptativaPeriodoSemPeriodoBusca() {
		this.labelAdptativaPeriodo = {};
		const periodosRelatorio = this.relatorioAtual?.periodos;

		if (periodosRelatorio) {
			if (periodosRelatorio.mensal_unico) {
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Mês',
						placeholder: 'Selecione um mês',
						dataType: 'month',
					},
				};
			}

			if (periodosRelatorio.anual_unico) {
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Ano',
						placeholder: 'Selecione um ano',
						dataType: 'year',
					},
				};
			}

			if (periodosRelatorio.mensal_por_nome) {
				this.labelAdptativaPeriodo = {
					mensal_por_nome: true,
				};
			}

			if (periodosRelatorio.diario) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Período inicial',
						placeholder: 'Selecione o período inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Período final',
						placeholder: 'Selecione o período final',
						dataType: 'day',
					},
				};
			}

			if (periodosRelatorio.mensal) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Mês inicial',
						placeholder: 'Início',
						dataType: 'month',
					},
					fim: {
						label: 'Mês final',
						placeholder: 'Selecione uma data',
						dataType: 'month',
					},
				};
			}

			if (periodosRelatorio.anual) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Ano inicial',
						placeholder: 'Selecione o ano inicial',
						dataType: 'year',
					},
					fim: {
						label: 'Ano final',
						placeholder: 'Selecione o ano final',
						dataType: 'year',
					},
				};
			}
		} else {
			this.labelAdptativaPeriodo = {
				unico: {
					label: 'Dia',
					placeholder: 'Selecione uma data',
					dataType: 'day',
				},
			};
		}
	}

	private getMostrarEntidade(tipoFormulario: FormularioRelatorio) {
		const condicoesMostrarItens =
			this.relatorioAtual?.condicoesMostrarItensForm?.[tipoFormulario];

		if (condicoesMostrarItens) {
			const values = this.form.getRawValue();
			return condicoesMostrarItens(values);
		}
		return false;
	}

	protected readonly FormularioRelatorio = FormularioRelatorio;
}
