import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
	AgrupamentoEspecial,
	DadosAgrupamento,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	PeriodosLabel,
} from '../../../interfaces';
import { RelatorioNDCResponse } from '../../../interfaces/relatorio-ndc';
import { RelatoriosService } from '../../../services';
import { corrigeDuplicacaoNome } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { enumAsSelectOptions, numberToBrNumber, setAgrupamento } from '@utils';

import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';
@Component({
	selector: 'seira-tabela-ndc',
	templateUrl: './tabela-ndc.component.html',
	styleUrls: ['./tabela-ndc.component.scss'],
})
export class TabelaNdcComponent implements OnInit, OnDestroy {
	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloTabela = 'Número de dias com chuva (NDC)';
	tituloColunaAgrupamento = 'Agrupamento';
	descricaoRelatorio =
		'O NDC, Número de Dias com Chuva, representa o número de dias em que a pluviometria diária, acumulada em 24 horas, foi igual ou superior a 0,1 mm.';

	carregando = true;
	disabledSelectList = false;

	colunasNDC: ADTColumns[] = [];
	resultados: any[] = [];
	colunasSelecionadas: string[] = [];
	periodoAgrupamento = '';
	agrupamento: any[];

	@ViewChild('tabelaNDC', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.colunasNDC,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.agrupamento = enumAsSelectOptions(AgrupamentoEspecial);
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
	}

	ngOnInit() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	setColunasSelecionadas(colunas: any) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	gerarRelatorio() {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		this.periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!this.periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.tituloTabela = `Número de dias com chuva (NDC) - ${DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			this.periodoAgrupamento
		)}`;
		this.carregando = true;
		this.inputs.setLoading(true);

		this.colunasNDC = [];
		this.resultados = [];

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: this.periodoAgrupamento.toUpperCase(),
			isRetornoAgrupado: true,
		};

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = 0;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = 0;
				break;
			case 'MUNICIPIO':
				dados.municipio = 0;
				break;
		}

		this.relatoriosService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				const agrupamento = this.inputs.form.get('agrupamento')?.value;
				this.relatoriosService.verificaExistenciaDados(ndcAgrupados);
				this.resultados = this.padronizarResultado(ndcAgrupados, agrupamento);
				this.tituloColunaAgrupamento = setAgrupamento(agrupamento);
				this.atualizarColunasTabelaNDC();
			},
			error: () => {
				this.toastrService.error(
					'Erro ao gerar tabela de NDC, tente novamente'
				);
				this.carregando = false;
				this.inputs.setLoading(false);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.carregando = false;
			},
		});
	}

	padronizarResultado(
		ndcAgrupados: RelatorioNDCResponse[],
		agrupamento: string
	) {
		return ndcAgrupados.map(item => {
			const base = {
				nome:
					agrupamento === 'MUNICIPIO_POSTO'
						? corrigeDuplicacaoNome(item.nome)
						: item.nome,
				diasComChuva: item.diasComChuva || '-',
				somaPrecipitacao: numberToBrNumber(item.somaPrecipitacao, 1),
				mes: item.mes,
				ano: item.ano,
			};

			if (agrupamento === 'MUNICIPIO_POSTO') {
				return {
					...base,
					latitude: numberToBrNumber(item.latitude, 4) || '-',
					longitude: numberToBrNumber(item.longitude, 4) || '-',
					altitude: numberToBrNumber(item.altitude, 1) || '-',
					municipio: item.municipio || '-',
					microrregiao: item.microrregiao || '-',
					mesorregiao: item.mesorregiao || '-',
					regiao: item.regiao || '-',
					bacia: item.bacia || '-',
					subbacia: item.subbacia || '-',
				};
			}
			return base;
		});
	}

	atualizarColunasTabelaNDC() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		this.colunasNDC = [
			{
				data: 'nome',
				title: this.tituloColunaAgrupamento,
				type: 'string',
				className: 'text-left',
			},
		];

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'id',
					title: 'Id',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
				},

				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
				},
				{
					data: null,
					title: 'Data',
					type: 'string',
					className: 'text-center',
					render: (Agrupamento: RelatorioNDCResponse) => {
						if (Agrupamento.mes !== null && Agrupamento.mes !== undefined) {
							if (Agrupamento.mes < 10) {
								return `0${Agrupamento.mes}/${Agrupamento.ano}`;
							}
							return `${Agrupamento.mes}/${Agrupamento.ano}`;
						} else if (
							Agrupamento.ano !== null &&
							Agrupamento.ano !== undefined
						) {
							return `${Agrupamento.ano}`;
						}

						return '-';
					},
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunasNDC.push(...colunasParaExibir);
		} else {
			this.disabledSelectList = true;
		}

		this.colunasNDC.push(
			...[
				{
					data: 'diasComChuva',
					title: 'Dias com chuva',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'somaPrecipitacao',
					title: 'Precipitação total (mm)',
					type: 'number',
					className: 'text-center',
				},
			]
		);
		if (this.periodoAgrupamento !== 'periodo') {
			this.colunasNDC.push({
				data: null,
				title: 'Data',
				type: 'string',
				className: 'text-center',
				render: (Agrupamento: RelatorioNDCResponse) => {
					if (Agrupamento.mes !== null && Agrupamento.mes !== undefined) {
						if (Agrupamento.mes < 10) {
							return `0${Agrupamento.mes}/${Agrupamento.ano}`;
						}
						return `${Agrupamento.mes}/${Agrupamento.ano}`;
					} else if (
						Agrupamento.ano !== null &&
						Agrupamento.ano !== undefined
					) {
						return `${Agrupamento.ano}`;
					}

					return '-';
				},
			});
		}
	}
}
