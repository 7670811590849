import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import {
	capitalizeFirstLetter,
	criarImagemBase64FromChart,
	filterSelectOptions,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import { Chart } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Subject, takeUntil } from 'rxjs';
import {
	Agrupamento,
	FormularioRelatorio,
	IapmDTO,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '../../../interfaces';
import {
	GraficosPrecipitacaoService,
	RelatoriosService,
} from '../../../services';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import moment from 'moment';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';

export const PERIODO_MINIMO_IAPM_EM_ANOS = 2;
export const PERIODO_MAXIMO_IAPM_EM_ANOS = 10;

export const PERIODO_MINIMO_IAPM_EM_MESES = 2;
export const PERIODO_MAXIMO_IAPM_EM_MESES = 12;

export const PERIODO_MINIMO_IAPM_EM_DIAS = 182;
export const PERIODO_MAXIMO_IAPM_EM_DIAS = 366;
@Component({
	selector: 'seira-pluviometria-grafico-iapm',
	templateUrl: './pluviometria-grafico-iapm.component.html',
	styleUrls: ['./pluviometria-grafico-iapm.component.scss'],
})
export class PluviometriaGraficoIapmComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup<any>;
	isLoading = false;
	chart!: Chart;
	dados?: IapmDTO[];

	descricaoRelatorio =
		'Define-se como IAPM, Índice de Anomalia de Precipitação Modificado, a variação da pluviometria em comparação com valores médios históricos a fim de caracterizar as diferentes intensidades de eventos secos e chuvosos no tempo e no espaço.';

	referenciaEstudo =
		'Os métodos utilizados para a análise da precipitação e cálculo do Índice de Anomalia de Chuva (IAC) foram baseados no estudo intitulado: "Índice de Anomalia de Chuva (IAC) como Indicador para Análise da Variabilidade Climática na Bacia Hidrográfica do Rio Pajeú-PE", de autoria de J. M. O. Assis, W. M. Souza, M. C. M. Sobral, G. L. Melo e R. A. Irmão. Este trabalho foi apresentado no XX Simpósio Brasileiro de Recursos Hídricos em 2013 e fornece uma aplicação prática do IAC para a análise da variabilidade climática. O estudo está disponível no repositório da Associação Brasileira de Recursos Hídricos (ABRHidro).';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	fileName = '';
	chartTitle = '';
	periodoTitulo = '';
	agrupamento: Select<string>[];
	private _destroyed = new Subject();
	relatorioService = inject(RelatoriosService);
	tituloAgrupamento = '';

	constructor(
		private readonly service: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.BACIA,
			Agrupamento.MESORREGIAO,
			Agrupamento.MICRORREGIAO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setDatasByPeriodoBusca(
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	gerarRelatorio() {
		if (this.inputs.form?.invalid) {
			return;
		}

		const agrupamento = this.inputs.form?.get('agrupamento')?.value;
		const periodoBusca = this.inputs.form?.get('periodoBusca')?.value;
		const dataInicio = this.inputs.form?.get('dataInicio')?.value;
		const dataFim = this.inputs.form?.get('dataFim')?.value;

		let id = 0;

		switch (agrupamento) {
			case 'MICRORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MICRORREGIAO)?.value;
				break;
			case 'MESORREGIAO':
				id = this.getDataFromForm(FormularioRelatorio.MESORREGIAO)?.value;
				break;
			case 'REGIAO_PLUVIOMETRICA':
				id = this.getDataFromForm(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.value;
				break;
			case 'BACIA':
				id = this.getDataFromForm(FormularioRelatorio.BACIA)?.value;
				break;
		}

		if (id == 0 || !agrupamento || !periodoBusca || !dataInicio || !dataFim) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		);
		this.handleFetchIAPM(
			id,
			agrupamento,
			this.handleFormatPeriodoBusca(periodoBusca),
			formatISO(new Date(dataInicio)),
			formatISO(new Date(dataFim))
		);
	}

	getDadosTabelaParaExportacao(dados: IapmDTO[], isPdf: boolean) {
		const tableData: any[][] = [];
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoBusca = this.inputs.form?.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value;

		const colunas = [
			{
				text: Agrupamento[agrupamento as keyof typeof Agrupamento],
				fillColor: '#DCDCDC',
			},
			{ text: 'IAPM (mm)', fillColor: '#DCDCDC' },
			{ text: 'Classificação', fillColor: '#DCDCDC' },
			{
				text: periodoBusca === 'mensal' ? 'Mês/ano' : 'Ano',
				fillColor: '#DCDCDC',
			},
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: IapmDTO) => {
			const rowData = [
				item.nome,
				numberToBrNumber(item.iapm, 1),
				item.classificacao,
				periodoBusca === 'mensal'
					? item.periodo
					: DateTimeUtils.formatarData(item.periodo, 'yyyy'),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	retornarIAPMParaXLSX() {
		const iapms: any[] = [];

		this.dados?.forEach(iapm => {
			iapms.push({
				nome: iapm.nome,
				iapm: numberToBrNumber(iapm.iapm, 1),
				classificacao: iapm.classificacao,
				periodo: iapm.periodo,
			});
		});

		return iapms;
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (!this.chart || !this.dados) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 700,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: `Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);
		documentDefinition.content.push({
			table: {
				widths: ['30%', '20%', '30%', '20%'],
				body: this.getDadosTabelaParaExportacao(this.dados, true),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},

			marginLeft: 30,
			marginTop: 10,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.dados as IapmDTO[],
			false
		);

		DocumentExporter.gerarCSV(
			tableData,
			`Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.dados as IapmDTO[],
			false
		);
		let txtData = '';

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const periodoBusca = this.inputs.form?.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value;
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		const nomeArquivo = `Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'nome',
				text: Agrupamento[agrupamento as keyof typeof Agrupamento],
				size: 24,
			},
			{
				label: 'iapm',
				text: 'IAPM (mm)',
				size: 24,
			},
			{
				label: 'classificacao',
				text: 'Classificação',
				size: 24,
			},
			{
				label: 'periodo',
				text: periodoBusca === 'mensal' ? 'Mês/ano' : 'Ano',
				size: 24,
			},
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarIAPMParaXLSX(),
					columns: [
						{ key: 'iapm' },
						{ key: 'classificacao' },
						{ key: 'periodo' },
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	setDatasByPeriodoBusca(periodoBuscaValue: PeriodosLabel | null) {
		const dataInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			const hoje = new Date();

			switch (periodoBuscaValue) {
				case 'anual':
					dataFim.setValue(moment(hoje).subtract(1, 'year').endOf('year'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_ANOS, 'year')
							.startOf('year')
					);
					break;
				case 'mensal':
					dataFim.setValue(moment(hoje).subtract(1, 'month').startOf('month'));
					dataInicio.setValue(
						moment(hoje)
							.subtract(PERIODO_MAXIMO_IAPM_EM_MESES, 'month')
							.endOf('month')
					);
					break;
			}
		}
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.BACIA);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MESORREGIAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.REGIAO_PLUVIOMETRICA
		);

		switch (agrupamentoValue) {
			case 'BACIA':
				this.inputs.form
					?.get(FormularioRelatorio.BACIA)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MESORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MESORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.inputs.form
					?.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: (periodo: PeriodosLabel | null) => {
					this.setDatasByPeriodoBusca(periodo);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	private handleFetchIAPM(
		id: number,
		agrupamento: string,
		periodo: string,
		dataInicial: string,
		dataFinal: string
	) {
		this.inputs.setLoading(true);
		this.isLoading = true;
		this.service
			.gerarGraficoIAPM(id, agrupamento, periodo, dataInicial, dataFinal)
			.subscribe({
				next: values => {
					this.relatorioService.verificaExistenciaDados(values);
					this.dados =
						periodo === 'MENSAL'
							? values.map(value => ({
									...value,
									periodo: value.periodo.padStart(7, '0'),
							  }))
							: values;
					this.fileName = gerarFilenameGrafico(
						`grafico_iapm_${this.inputs.form
							.get(FormularioRelatorio.AGRUPAMENTO)
							?.value.toLocaleLowerCase()}_${periodo.toLocaleLowerCase()}`
					);
					this.chartTitle = `IAPM - ${capitalizeFirstLetter(
						values[0].nome
					)} - ${DateTimeUtils.formatarDataPeriodo(
						dataInicial,
						dataFinal,
						periodo
					)}`;
				},
				error: err => {
					this.toastr.error('Erro ao buscar as informações');
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.isLoading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	private getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form?.get(data);
	}

	private handleFormatPeriodoBusca(periodo: string) {
		if (periodo === 'anual') return 'ANUAL';
		if (periodo === 'mensal') return 'MENSAL';
		return 'PERIODO';
	}

	private handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}
}
