import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { TokenService } from '../token/token.service';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';

@Injectable({
	providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
	private readonly AUTH_HEADER = 'Authorization';

	constructor(
		private tokenService: TokenService,
		private router: Router,
		private userService: UserService
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (this.tokenService.hasToken()) {
			const token = this.tokenService.getToken();

			if (this.tokenService.isTokenExpired()) {
				this.redirectToHome();
			}
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}

		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					const { headers } = event;
					const authorizationHeader = headers?.get(this.AUTH_HEADER) ?? null;
					if (
						authorizationHeader &&
						this.tokenService.getToken() !== authorizationHeader
					) {
						this.tokenService.setToken(authorizationHeader);
					}
				}
			})
		);
	}

	redirectToHome() {
		this.userService.logout();
		this.router.navigate(['/']).then();
	}
}
