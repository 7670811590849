<label class="text-tertiary text-center fw-semibold mt-3 w-100">
	{{ quantisType }} - {{ municipioNome }} - {{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio" />

<div
	*ngIf="isLoading; else tabela"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-template #tabela>
	<div style="max-width: 1300px">
		<seira-tabela-relatorios-quantis
			[relatorios]="tableData"
			[isAnual]="isAnual" />
		<p
			style="
				color: #666666;
				text-align: justify;
				font-size: 13px;
				line-height: 1rem;
			">
			{{ legenda }}
		</p>
	</div>
</ng-template>
