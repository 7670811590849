import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { TiposDatePicker } from '@shared/interfaces/date-picker';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
	selector: 'seira-public-date-picker',
	templateUrl: './public-date-picker.component.html',
	styleUrls: ['./public-date-picker.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class PublicDatePickerComponent implements OnInit, OnChanges {
	@Input() id!: string;
	@Input() controlName!: string;
	@Input() required = false;
	@Input() placeholder = '';
	@Input() label?: string;
	@Input() datePickerType: TiposDatePicker = 'day';
	@Input() maxDate: Date | null = new Date();
	@Input() minDate: Date | null = null;
	@Input() tipoFormulario?: string;
	@Input() isDesabilitado = false;
	startView: 'month' | 'year' | 'multi-year' = 'month';
	parseDate = 'DD/MM/YYYY';
	control = new FormControl();

	filterDateByTipo: (d: any | null) => boolean;

	constructor(private controlContainer: ControlContainer) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['datePickerType']) {
			this.updateFilter(changes['datePickerType'].currentValue);
			switch (this.datePickerType) {
				case 'year':
					this.startView = 'multi-year';
					this.parseDate = 'YYYY';
					break;
				case 'month':
					this.startView = 'multi-year';
					this.parseDate = 'MM/YYYY';
					break;
				case 'partial_year':
					this.startView = 'multi-year';
					this.parseDate = 'DD/MM/YYYY';
					break;
				case 'partial_month':
					this.startView = 'year';
					this.parseDate = 'DD/MM/YYYY';
					break;
				default:
					this.startView = 'month';
					this.parseDate = 'DD/MM/YYYY';
					break;
			}
		}
	}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
		switch (this.datePickerType) {
			case 'year':
				this.startView = 'multi-year';
				break;
			case 'month':
				this.startView = 'multi-year';
				break;
		}
	}

	updateFilter(dateType: TiposDatePicker) {
		this.filterDateByTipo = (d: any | null): boolean => {
			if (this.tipoFormulario) {
				if (
					this.tipoFormulario === 'IAPM' ||
					this.tipoFormulario === 'VALORES_EXTREMOS'
				) {
					const date = d?.toDate() || new Date();
					if (dateType === 'month') {
						return !(
							date.getFullYear() === new Date().getFullYear() &&
							date.getMonth() >= new Date().getMonth()
						);
					}
					if (dateType === 'year') {
						return date.getFullYear() !== new Date().getFullYear();
					}
				}
			}

			return true;
		};
	}

	setYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		if (this.datePickerType === 'year') {
			const ctrlValue = this.control.value
				? moment(this.control.value)
				: moment();
			ctrlValue.year(normalizedMonthAndYear.year());
			this.control.setValue(ctrlValue);
			datepicker.close();
		}
	}

	setMonthAndYear(
		normalizedMonthAndYear: Moment,
		datepicker: MatDatepicker<Moment>
	) {
		if (this.datePickerType === 'month') {
			const ctrlValue = this.control.value
				? moment(this.control.value)
				: moment();
			ctrlValue.month(normalizedMonthAndYear.month());
			ctrlValue.year(normalizedMonthAndYear.year());
			const isFuture = ctrlValue.isAfter(moment());
			this.control.setValue(isFuture ? moment() : ctrlValue);
			datepicker.close();
		}
	}
}
