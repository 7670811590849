<div class="menu">
	<div class="accordion-item">
		<button
			class="accordion-button"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#camadas"
			aria-expanded="false"
			aria-controls="camadas">
			<div class="me-2">Camadas</div>
		</button>

		<div
			id="camadas"
			class="accordion-collapse collapse show"
			aria-labelledby="camadas-header">
			<div class="accordion-body" [formGroup]="formCamadas">
				<div class="d-flex flex-column gap-1">
					<seira-public-checkbox controlName="bacias" label="Bacias" />
					<seira-public-checkbox controlName="subBacias" label="Sub-bacias" />
					<seira-public-checkbox
						controlName="regioes"
						label="Regiões de curso" />
				</div>
			</div>
		</div>
	</div>
</div>
