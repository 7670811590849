import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as Highcharts from 'highcharts/highstock';
import { Chart } from 'highcharts';
import {
	optionsChartReservatorio,
	relatorioQuantis,
} from '@modulos/home/submodulos/dados-meteorologicos/componentes/grafico-quantis/chart-options';
import { DadosGraficoQuantisMensal } from '../../interfaces';

@Component({
	selector: 'seira-grafico-quantis',
	templateUrl: './grafico-quantis.component.html',
	styleUrls: ['./grafico-quantis.component.scss'],
})
export class GraficoQuantisComponent {
	@Input() dado!: DadosGraficoQuantisMensal;
	@Input() height = '190px';
	@Input() useMaximumLimit = 500;
	@Input() tituloXAxis = 'Meses';

	carregando = false;
	chart?: Chart;
	chartOptions = optionsChartReservatorio();
	series: Array<Partial<Highcharts.SeriesOptionsType> & Record<string, any>> =
		relatorioQuantis;

	@Output() chartInstance = new EventEmitter<Chart>();

	setChart(chart: Chart) {
		this.chart = chart;
		this.chartInstance.emit(chart);
		this.chart.setTitle({ text: this.dado.tituloGeral });
		this.chart.setCaption({ text: this.dado.legenda });

		this.chartOptions = {
			...this.chartOptions,
			yAxis: {
				max: this.useMaximumLimit,
			},
			exporting: {
				enabled: true,
				buttons: {
					contextButton: {
						menuItems: [
							'viewFullscreen',
							'separator',
							'downloadPNG',
							'downloadJPEG',
						],
					},
				},
				filename: this.dado.fileName ?? 'quantis-grafico',
			},
		};
		this.setSeries();
	}

	setSeries() {
		this.chart?.series.forEach(el => el.remove());
		this.series?.map(serie => {
			serie.data = [];
			return serie;
		});

		if (this.dado.dadosGraficosQuantis) {
			this.chartOptions = {
				...this.chartOptions,
				xAxis: {
					title: {
						text: this.tituloXAxis,
					},
				},
			};

			this.dado.dadosGraficosQuantis.forEach(el => {
				if (el.categorias?.length) {
					for (let i = 0; i < this.series.length; i++) {
						this.series[i].data.push({
							y: el.categorias[5 - i].to,
							name: el.titulo,
						});
					}
				}
			});
		}

		this.series.forEach(el => {
			this.chart?.addSeries(el as any);
		});
	}
}
