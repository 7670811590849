import { corrigeDuplicacaoNome } from '@modulos/home/submodulos/dados-meteorologicos/utils';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS_QUANTIS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { handleGenerateQuantisDataHighchart } from '@home/submodulos/dados-meteorologicos/utils/graficoUtils';
import { Select } from '@layout/interfaces/select';
import {
	criarImagemBase64FromChart,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import { Chart } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject, Subscription } from 'rxjs';
import { Meses, MesesNaoAbreviados, PeriodoChuvosoMap } from '../../../enum';
import {
	DadoGraficoQuantis,
	DadosGraficoQuantisMensal,
	QuantisPrecipitacao,
	QuantisResponse,
} from '../../../interfaces';
import {
	QuantisMicrorregiaoService,
	RelatoriosService,
} from '../../../services';
import { gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';

@Component({
	selector: 'seira-pluviometro-grafico-quantis',
	templateUrl: './pluviometro-grafico-quantis.component.html',
	styleUrls: ['./pluviometro-grafico-quantis.component.scss'],
})
export class PluviometroGraficoQuantisComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS_QUANTIS);
	microrregioes!: Select<string>[];
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	chart!: Chart;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	loading = true;
	gerou = false;
	useMaximumLimit = 500;
	resultado?: DadosGraficoQuantisMensal;
	private subscription = new Subscription();
	private _destroyed = new Subject();

	descricaoRelatorio = '';
	descricaoRelatorioMensal =
		'Define-se como quantis mensais, os valores que dividem uma amostra de dados mensais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	descricaoRelatorioAnual =
		'Define-se como quantis anuais, os valores que dividem uma amostra de dados anuais em percentis, permitindo a análise de diferentes categorias pluviométricas em determinado local ou região.';
	descricaoRelatorioPeriodoChuvoso =
		'Define-se como quantis por período chuvoso os valores que segmentam a precipitação acumulada ao longo de diferentes períodos, sejam eles anuais, quadrimestrais, quinquemestrais ou semestrais, dividindo-a em percentis para analisar a distribuição da chuva em uma microrregião.';

	periodoTitulo = '';
	quantisTitulo = '';
	tituloXAxis = '';

	dadosMensal: QuantisResponse<QuantisPrecipitacao[]>;
	dadosAnual: QuantisResponse<QuantisPrecipitacao[]>;
	dadosPeriodoChuvoso: QuantisResponse<any[]>;

	constructor(
		private toastr: ToastrService,
		private readonly quantisService: QuantisMicrorregiaoService,
		private relatorioService: RelatoriosService
	) {}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.inputs.agrupamentos[0].value, { emitEvent: false });
	}

	ngOnDestroy() {
		this.clearValidators();
		this.subscription.unsubscribe();
		this._destroyed.next(undefined);
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}
		this.loading = true;
		this.inputs.setLoading(true);
		const periodoBusca = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (periodoBusca === 'mensal') {
			this.tituloXAxis = 'Meses';
			this.quantisTitulo = 'mensal';
			this.descricaoRelatorio = this.descricaoRelatorioMensal;
		} else if (periodoBusca === 'anual') {
			this.tituloXAxis = 'Anos';
			this.quantisTitulo = 'anual';
			this.descricaoRelatorio = this.descricaoRelatorioAnual;
		} else {
			this.tituloXAxis = 'Anos';
			this.quantisTitulo = 'por região pluviométrica';
			this.descricaoRelatorio = this.descricaoRelatorioPeriodoChuvoso;
		}

		this.setQuantisByPeriodoBusca(periodoBusca);
	}

	setQuantisByPeriodoBusca(periodoBusca: string) {
		this.clearValidators();
		const agrupamento = this.inputs.form.get('agrupamento')?.value;

		if (agrupamento === 'REGIAO_PLUVIOMETRICA') {
			if (periodoBusca === 'periodo_chuvoso') {
				this.getQuantisByRegiaoAndPeriodoChuvoso();
				this.setValidatorsQuantisPluviometricoPeriodoChuvoso();
			} else if (periodoBusca === 'mensal') {
				this.getQuantisMensalByRegiao();
				this.setValidatorsQuantisPluviometricoMensalOrAnual();
			} else if (periodoBusca === 'anual') {
				this.getQuantisAnualByRegiao();
				this.setValidatorsQuantisPluviometricoMensalOrAnual();
			}
		} else {
			if (periodoBusca === 'mensal') {
				this.getQuantisMensal();
				this.setValidatorsQuantisMensalOrAnual();
			} else if (periodoBusca === 'anual') {
				this.getQuantisAnual();
				this.setValidatorsQuantisMensalOrAnual();
			}
		}
	}

	getQuantisByRegiaoAndPeriodoChuvoso() {
		const periodoChuvoso = this.inputs.form.get('periodoChuvoso')
			?.value as string;
		const regiao = this.inputs.form.get('regiao')?.value;
		const mapeamentoQtd: Record<string, number> = {
			QUADRA_CHUVOSA: 4,
			QUINTETO_CHUVOSO: 5,
			SEMESTRE_CHUVOSO: 6,
			ANO_CIVIL: 10,
		};
		const hoje = new Date();
		this.quantisService
			.getUltimosQuantisByRegiaoAndPeriodoChuvoso(
				regiao,
				periodoChuvoso,
				mapeamentoQtd[periodoChuvoso]
			)
			.subscribe({
				next: resp => {
					this.relatorioService.verificaExistenciaDados(resp);
					this.dadosPeriodoChuvoso = resp;
					const dadosGraficosQuantis: DadoGraficoQuantis[] =
						resp.quantis.map<DadoGraficoQuantis>(q => ({
							titulo: q.ano?.toString(),
							categorias: handleGenerateQuantisDataHighchart(q),
						}));
					this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
						new Date(
							hoje.getFullYear() - mapeamentoQtd[periodoChuvoso] + 1,
							0,
							1
						),
						new Date(hoje.getFullYear(), 0, 1),
						periodoChuvoso
					);
					const periodo = PeriodoChuvosoMap.get(
						this.inputs.form.get(FormularioRelatorio.PERIODO_CHUVOSO)?.value
					);
					const tituloGeral = `Região: ${resp.regiao} - Período: ${periodo} (${resp?.periodoChuvoso}) - ${this.periodoTitulo}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';
					const fileName = gerarFilenameGrafico(
						`quantis_regiao_periodo_chuvoso`
					);
					this.resultado = {
						tituloGeral,
						fileName,
						legenda,
						dadosGraficosQuantis,
					};
					this.setMaximumLimit(resp);
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getQuantisMensalByRegiao() {
		const mesInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const regiao = this.inputs.form.get('regiao')?.value;

		this.quantisService
			.getQuantisMensalGraficoByRegiaoId(regiao, mesInicio, mesFim)
			.subscribe({
				next: resp => {
					this.dadosMensal = resp;
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: `${Meses[q.data! - 1]}`,
								categorias: newCategorias,
							};
						}
					);
					this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
						mesInicio,
						mesFim,
						'mensal',
						true
					);
					const tituloGeral = `Região pluviométrica: ${resp.regiao}  - ${this.periodoTitulo}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';
					const fileName = gerarFilenameGrafico(`quantis_regiao_anual`);
					this.resultado = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
						fileName,
					};
					this.setMaximumLimit(resp);
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getQuantisAnualByRegiao() {
		const anoInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const anoFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const regiao = this.inputs.form.get('regiao')?.value;

		this.quantisService
			.getQuantisAnualGraficoByRegiaoId(regiao, anoInicio, anoFim)
			.subscribe({
				next: resp => {
					this.dadosAnual = resp;
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: q.data!.toString(),
								categorias: newCategorias,
							};
						}
					);
					this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
						anoInicio,
						anoFim,
						'anual',
						false
					);
					const tituloGeral = `Região pluviométrica: ${resp.regiao}  - ${this.periodoTitulo}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';
					const fileName = gerarFilenameGrafico(`quantis_regiao_anual`);
					this.resultado = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
						fileName,
					};
					this.setMaximumLimit(resp);
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getQuantisMensal() {
		const mesInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const estacao = Number.parseInt(this.inputs.form.get('estacao')?.value);

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			mesInicio,
			mesFim,
			'mensal',
			false
		);
		this.quantisService
			.getQuantisMensalGraficoByEstacaoId(estacao, mesInicio, mesFim)
			.subscribe({
				next: resp => {
					this.relatorioService.verificaExistenciaDados(resp);
					this.dadosMensal = resp;
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: `${Meses[q.data! - 1]}`,
								categorias: newCategorias,
							};
						}
					);

					this.setMaximumLimit(resp);

					const tituloGeral =
						resp.posto === resp.municipio
							? `Posto: ${resp.posto} - ${this.periodoTitulo}`
							: `Posto: ${resp.posto} (${resp.municipio}) - ${this.periodoTitulo}`;
					const fileName = gerarFilenameGrafico(
						`quantis_mensal_${resp.posto?.toLocaleLowerCase()}`
					);
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						fileName,
						legenda,
						dadosGraficosQuantis,
					};
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getQuantisAnual() {
		const anoInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const anoFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const estacao = Number.parseInt(this.inputs.form.get('estacao')?.value);

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			anoInicio,
			anoFim,
			'anual',
			false
		);
		this.quantisService
			.getQuantisAnualGraficoByEstacaoId(estacao, anoInicio, anoFim)
			.subscribe({
				next: resp => {
					this.relatorioService.verificaExistenciaDados(resp);
					this.dadosAnual = resp;
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: q.data!.toString(),
								categorias: newCategorias,
							};
						}
					);

					const tituloGeral =
						resp.posto === resp.municipio
							? `Posto: ${resp.posto} - ${this.periodoTitulo}`
							: `Posto: ${resp.posto} (${resp.municipio}) - ${this.periodoTitulo}`;
					const fileName = gerarFilenameGrafico(
						`quantis_anual_${resp.posto?.toLocaleLowerCase()}`
					);
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						fileName,
						legenda,
						dadosGraficosQuantis,
					};
					this.setMaximumLimit(resp);
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.gerou = true;
					this.loading = false;
					this.inputs.setLoading(false);
				},
			});
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	getTipo() {
		const periodoBusca = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		return periodoBusca === 'mensal'
			? 'QUANTIS_MENSAL'
			: periodoBusca === 'anual'
			? 'QUANTIS_ANUAL'
			: 'QUANTIS_PERIODO_CHUVOSO';
	}

	setMaximumLimit(resp: any) {
		let todosValores;
		const periodoBusca = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (periodoBusca === 'periodo_chuvoso') {
			todosValores = resp.quantis.flatMap(
				({ ...valores }) => Object.values(valores) as number[]
			);
			this.useMaximumLimit = Math.max(...todosValores) + 100;
		} else {
			todosValores = resp.quantis.flatMap(
				({ valores }: any) => Object.values(valores) as number[]
			);
		}

		const valorMaximo = Math.max(...todosValores);
		this.useMaximumLimit = valorMaximo > 10 ? valorMaximo + 10 : 10;
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas: any[] = [];
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (
			this.getTipo() === 'QUANTIS_MENSAL' ||
			this.getTipo() === 'QUANTIS_ANUAL'
		) {
			colunas.push(
				{
					text:
						agrupamento === 'MUNICIPIO_POSTO'
							? 'Município/Posto'
							: 'Região Pluviométrica',
					fillColor: '#DCDCDC',
				},
				{
					text: this.getTipo() === 'QUANTIS_MENSAL' ? 'Mês' : 'Ano',
					fillColor: '#DCDCDC',
				},
				{ text: 'Muito seco (mm)', fillColor: '#DCDCDC' },
				{ text: 'Seco (mm)', fillColor: '#DCDCDC' },
				{ text: 'Normal (mm)', fillColor: '#DCDCDC' },
				{ text: 'Chuvoso (mm)', fillColor: '#DCDCDC' },
				{ text: 'Muito chuvoso (mm)', fillColor: '#DCDCDC' },
				{ text: 'Observado (mm)', fillColor: '#DCDCDC' }
			);

			verificaSePDF(tableData, colunas, isPdf);

			if (this.getTipo() === 'QUANTIS_MENSAL') {
				this.dadosMensal.quantis.forEach((quantis: QuantisPrecipitacao) => {
					const rowData: any[] = [];

					rowData.push(
						agrupamento === 'MUNICIPIO_POSTO'
							? corrigeDuplicacaoNome(
									`${this.dadosMensal.municipio}/${this.dadosMensal.posto}`
							  )
							: this.dadosMensal.regiao,
						MesesNaoAbreviados[quantis.data as number],
						numberToBrNumber(quantis.valores.muitoSeco, 1),
						numberToBrNumber(quantis.valores.seco, 1),
						numberToBrNumber(quantis.valores.normal, 1),
						numberToBrNumber(quantis.valores.chuvoso, 1),
						numberToBrNumber(quantis.valores.muitoChuvoso, 1),
						numberToBrNumber(quantis.valores.observado, 1) ?? '-'
					);

					tableData.push(rowData);
				});
			} else {
				this.dadosAnual.quantis.forEach((quantis: QuantisPrecipitacao) => {
					const rowData: any[] = [];

					rowData.push(
						agrupamento === 'MUNICIPIO_POSTO'
							? corrigeDuplicacaoNome(
									`${this.dadosAnual.municipio}/${this.dadosAnual.posto}`
							  )
							: this.dadosAnual.regiao,
						quantis.data,
						numberToBrNumber(quantis.valores.muitoSeco, 1),
						numberToBrNumber(quantis.valores.seco, 1),
						numberToBrNumber(quantis.valores.normal, 1),
						numberToBrNumber(quantis.valores.chuvoso, 1),
						numberToBrNumber(quantis.valores.muitoChuvoso, 1),
						numberToBrNumber(quantis.valores.observado, 1) ?? '-'
					);

					tableData.push(rowData);
				});
			}
		} else {
			colunas.push(
				{ text: 'Região pluviométrica', fillColor: '#DCDCDC' },
				{ text: 'Ano', fillColor: '#DCDCDC' },
				{ text: 'Muito seco (mm)', fillColor: '#DCDCDC' },
				{ text: 'Seco (mm)', fillColor: '#DCDCDC' },
				{ text: 'Normal (mm)', fillColor: '#DCDCDC' },
				{ text: 'Chuvoso (mm)', fillColor: '#DCDCDC' },
				{ text: 'Muito chuvoso (mm)', fillColor: '#DCDCDC' },
				{ text: 'Observado (mm)', fillColor: '#DCDCDC' }
			);

			verificaSePDF(tableData, colunas, isPdf);

			this.dadosPeriodoChuvoso.quantis.forEach(quantis => {
				const rowData: any[] = [];

				rowData.push(
					this.dadosPeriodoChuvoso.regiao,
					quantis.ano,
					numberToBrNumber(quantis.muitoSeco, 1),
					numberToBrNumber(quantis.seco, 1),
					numberToBrNumber(quantis.normal, 1),
					numberToBrNumber(quantis.chuvoso, 1),
					numberToBrNumber(quantis.muitoChuvoso, 1),
					numberToBrNumber(quantis.observado, 1) ?? '-'
				);

				tableData.push(rowData);
			});
		}
		return tableData;
	}

	getDadosTabelaParaExportacaoXLSX() {
		const tableData: any[] = [];
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		if (this.getTipo() === 'QUANTIS_MENSAL') {
			if (agrupamento === 'MUNICIPIO_POSTO') {
				this.dadosMensal.quantis.map(quantisMensal => {
					tableData.push({
						municipio: this.dadosMensal.municipio,
						mes: MesesNaoAbreviados[quantisMensal.data as number],
						muitoSeco: quantisMensal.valores.muitoSeco,
						seco: quantisMensal.valores.seco,
						normal: quantisMensal.valores.normal,
						chuvoso: quantisMensal.valores.chuvoso,
						muitoChuvoso: quantisMensal.valores.muitoChuvoso,
						observado: numberToBrNumber(quantisMensal.valores.observado, 1),
					});
				});
			} else {
				this.dadosMensal.quantis.map(quantisMensal => {
					tableData.push({
						regiao: this.dadosMensal.regiao,
						mes: MesesNaoAbreviados[quantisMensal.data as number],
						muitoSeco: quantisMensal.valores.muitoSeco,
						seco: quantisMensal.valores.seco,
						normal: quantisMensal.valores.normal,
						chuvoso: quantisMensal.valores.chuvoso,
						muitoChuvoso: quantisMensal.valores.muitoChuvoso,
						observado: numberToBrNumber(quantisMensal.valores.observado, 1),
					});
				});
			}
		} else if (this.getTipo() === 'QUANTIS_ANUAL') {
			if (agrupamento === 'MUNICIPIO_POSTO') {
				this.dadosAnual.quantis.map(quantisAnual => {
					tableData.push({
						municipio: this.dadosAnual.municipio,
						ano: quantisAnual.data?.toString(),
						muitoSeco: quantisAnual.valores.muitoSeco,
						seco: quantisAnual.valores.seco,
						normal: quantisAnual.valores.normal,
						chuvoso: quantisAnual.valores.chuvoso,
						muitoChuvoso: quantisAnual.valores.muitoChuvoso,
						observado: numberToBrNumber(quantisAnual.valores.observado, 1),
					});
				});
			} else {
				this.dadosAnual.quantis.map(quantisAnual => {
					tableData.push({
						regiao: this.dadosAnual.regiao,
						ano: quantisAnual.data?.toString(),
						muitoSeco: quantisAnual.valores.muitoSeco,
						seco: quantisAnual.valores.seco,
						normal: quantisAnual.valores.normal,
						chuvoso: quantisAnual.valores.chuvoso,
						muitoChuvoso: quantisAnual.valores.muitoChuvoso,
						observado: numberToBrNumber(quantisAnual.valores.observado, 1),
					});
				});
			}
		} else {
			this.dadosPeriodoChuvoso.quantis.forEach(quantis => {
				tableData.push({
					regiao: this.dadosPeriodoChuvoso.regiao,
					ano: quantis.ano,
					muitoSeco: quantis.muitoSeco,
					seco: quantis.seco,
					normal: quantis.normal,
					chuvoso: quantis.chuvoso,
					muitoChuvoso: quantis.muitoChuvoso,
					observado: numberToBrNumber(quantis.observado, 1) ?? '-',
				});
			});
		}

		return tableData;
	}

	async exportarPDF() {
		if (
			!this.resultado ||
			!this.chart ||
			!this.resultado.dadosGraficosQuantis.length
		) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}

		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push([
			{
				text: `Quantis ${this.quantisTitulo} - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: await criarImagemBase64FromChart(this.chart),
				width: 500,
				height: 300,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		documentDefinition.content.push({
			table: {
				widths: [
					'15%',
					'12.5%',
					'12.5%',
					'10%',
					'12.5%',
					'12.5%',
					'12.5%',
					'12.5%',
				],
				body: this.getDadosTabelaParaExportacao(true),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
			fontSize: 10,
			marginLeft: 5,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(false);
		DocumentExporter.gerarCSV(
			tableData,
			`Quantis ${this.quantisTitulo} - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(false);
		let txtData = '';

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Quantis ${this.quantisTitulo} - ${this.periodoTitulo}`
		);
	}

	async exportarXLSX() {
		const nomeArquivo = `Quantis ${this.quantisTitulo} - ${this.periodoTitulo}`;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers: { label: string; text: string; size: number }[] = [];

		if (
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value ===
			'REGIAO_PLUVIOMETRICA'
		) {
			headers.push({
				label: 'regiao',
				text: 'Região pluviométrica',
				size: 24,
			});
		} else {
			headers.push({
				label: 'municipio',
				text: 'Município/Posto',
				size: 24,
			});
		}

		if (this.getTipo() === 'QUANTIS_MENSAL') {
			headers.push({
				label: 'mes',
				text: 'Mês',
				size: 24,
			});
		} else {
			headers.push({
				label: 'ano',
				text: 'Ano',
				size: 24,
			});
		}

		headers.push(
			{
				label: 'muitoSeco',
				text: 'Muito seco (mm)',
				size: 24,
			},
			{
				label: 'seco',
				text: 'Seco (mm)',
				size: 24,
			},
			{
				label: 'normal',
				text: 'Normal (mm)',
				size: 24,
			},
			{
				label: 'chuvoso',
				text: 'Muito chuvoso (mm)',
				size: 24,
			},
			{
				label: 'observado',
				text: 'Observado (mm)',
				size: 24,
			}
		);

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.getDadosTabelaParaExportacaoXLSX(),
					title: {
						consommeRow: 3,
						consommeCol: 7,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		await ExcelTable.generateExcel(dataExcel);
	}

	setValidatorsQuantisMensalOrAnual() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	setValidatorsQuantisPluviometricoMensalOrAnual() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.setValidators(Validators.required);
	}

	setValidatorsQuantisPluviometricoPeriodoChuvoso() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_CHUVOSO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.setValidators(Validators.required);
	}

	clearValidators() {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_CHUVOSO)
			?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.clearValidators();
	}
}
