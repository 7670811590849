<div class="tooltip-borders mb-0 ms-0 me-0 border shadow-lg">
	<div
		class="tooltip-header bg-primary text-white fw-semibold font-size-16 px-3 py-2">
		<i class="ph-map-pin me-2 mb-1"></i>
		{{ markerData.nomePosto ? markerData.nomePosto : markerData.municipio }}
	</div>
	<div
		class="tooltip-footer px-3 py-1 text-tertiary font-size-15 bg-white"
		style="white-space: nowrap">
		<div class="d-flex col align-items-center">
			<i class="ph ph-map-pin fs-5"></i>
			<div
				class="d-flex col py-2"
				style="margin-left: 5px"
				*ngIf="markerData.municipio">
				<span class="fw-semibold" style="margin-right: 5px">Município:</span>
				{{ markerData.municipio }}
			</div>
		</div>
		<div class="d-flex col align-items-center" *ngIf="markerData.tipoPCD">
			<i class="ph ph-tag fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Tipo de PCD: </span>
				{{ markerData.tipoPCD }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Latitude:</span>
				{{ markerData.lat | formatDecimal: 4 }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Longitude:</span>
				{{ markerData.lng | formatDecimal: 4 }}
			</div>
		</div>
		<div class="d-flex">
			<i class="ph ph-push-pin fs-5 mt-1"></i>
			<div
				class="d-inline-flex align-items-center justify-content-between"
				style="margin-left: 5px; white-space: nowrap">
				<div>
					<span class="fw-semibold py-2 pe-2">Min:</span>
					<div class="py-2 pe-2 align-items-center">
						{{ formatarNumero(markerData.valorMin) }}
					</div>
				</div>
				<div *ngIf="unidade !== Unidade.PRECIPITACAO">
					<span class="fw-semibold py-2 pe-2">Média:</span>
					<div class="py-2 pe-2 align-items-center">
						{{ formatarNumero(markerData.value) }}
					</div>
				</div>
				<div>
					<span class="fw-semibold py-2 pe-2">Max:</span>
					<div class="py-2 pe-2 align-items-center">
						{{ formatarNumero(markerData.valorMax) }}
					</div>
				</div>
				<div *ngIf="unidade === Unidade.PRECIPITACAO">
					<span class="fw-semibold py-2 pe-2">Total observado:</span>
					<div class="py-2 pe-2 align-items-center">
						{{ formatarNumero(markerData.value) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
