<label class="text-center text-tertiary fw-semibold w-100">
	{{ tituloGrafico }}
</label>
<seira-card-info aditionalClasses="my-3" [texto]="descricaoRelatorio" />

<div
	*ngIf="loading; else grafico"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<ng-template #grafico>
	<div id="grafico"></div>
</ng-template>
